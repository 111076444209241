import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { CopyAll } from "@mui/icons-material";
import cardlogo from "../../Assets/Image/logo_solar.png";
import future_margin from "../../Assets/Image/future_margin.svg";
import trending from "../../Assets/Image/trending.svg";
import cooperation from "../../Assets/Image/cooperation.svg";
import multiple_gift from "../../Assets/Image/multiple_gift.svg";
import passive_income from "../../Assets/Image/passive_income.svg";
import investment_access from "../../Assets/Image/investment_access.svg";
import { api_url, site_url } from "../../config/config";
import { Link } from "react-router-dom";
import _fetch from "../../config/api";
import { Col, Row } from "react-bootstrap";
import Bitcoinamic from "../../Assets/Img__v2/Bitcoin-amico.png";

const Token = () => {
  const [amount, setamount] = useState(0);
  const [month, setmonth] = useState([{ mon: 12 }, { mon: 18 }, { mon: 24 }, { mon: 36 }]);
  const [percentage, setpercentage] = useState([{ pers: 3 }, { pers: 4 }, { pers: 5 }, { pers: 6 }])
  const [monthKey, setmonthKey] = useState(0);


  const title = "kissan";
  const shortTitle = "kSN";




  const checkAuth = localStorage.getItem('auth');
  const Authbutton = () => {
    if (checkAuth !== "true") {
      return (
        <>
          <Link to={site_url + '/login'} className='main-button'>Log In</Link>

        </>
      )
    }
    else {
      return (
        <>
          <Link to={site_url + '/dashboard'} className='main-button'> Dashboard</Link>

        </>
      )
    }
  }



  useEffect(() => {
    Authbutton()
  }, [Authbutton]);

  const text = "Empower Your Crypto Portfolio";

  const [content, setContent] = useState('');
  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentLength < text.length) {
        const newText = text.slice(0, currentLength + 1);
        setContent(newText);
        setCurrentLength(currentLength + 1);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval time for the typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentLength]);

  return (
    <div className="statoken_page only-margin">
      <div className="coustom_container for__bg">
        <Row className="banner_row-token">
          <Col>
            <div className="top-sec">
              <h4>{title} MOBILE</h4>
              <h1 className="sentence">{content}</h1>
              <p>Dive into the future of decentralized finance with {shortTitle} Token. Seamlessly integrate stability and growth in your crypto portfolio. Experience low volatility and impressive returns. Join {title} for financial empowerment today.
              </p>
            </div>
          </Col>
          <Col>
            <div className="token_img">
              <img src={Bitcoinamic} alt="token" />
            </div>
          </Col>
        </Row>

      </div>

      <div className="coustom_container token_profit">
        <div className="row">
          <div className="col-4_left">
            <div className="card-to">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <div className="card-header">
                <h4>Est. APY</h4>
                <h1>{Number(percentage?.[monthKey]?.pers) * 12}%</h1>
              </div>
              <div className="new__add">

                <h3>My {shortTitle} Center</h3>
                <div className="logo_img">
                  <img src={cardlogo} alt="logo" />
                </div>
                <div className="data_token">
                  <h4>{amount} / {Number(((amount * (percentage?.[monthKey]?.pers / 100)) * month?.[monthKey]?.mon)?.toFixed(3)) + Number(amount)}</h4>
                  <h5>
                    Total {shortTitle} Subscribed / Total {shortTitle}
                  </h5>
                </div>
                <div className="card-footer">
                  <div className="footer_card_1">
                    <Button variant="success">subscribe</Button>
                    <Button variant="success">redeem</Button>
                  </div>
                  <Link to={site_url + '/login'}>
                    <div className="login_btn">
                      Login
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8_right">
            <div className="card_to_right">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <div className="card-header">
                <h3>Current Profits</h3>
              </div>
              <div className="card-body">
                <div className="card-sec-body-1">
                  <div className="card-sec-body-1_data">
                    <h3>{shortTitle} Earn Rewards</h3>
                    <span>
                      <Link to="#">
                        <CopyAll className="copy_icon" />
                      </Link>
                    </span>
                  </div>
                  <p>
                    {shortTitle} Earn is an asset management platform on{" "}
                    {shortTitle} for investing and growing your {shortTitle}{" "}
                    holdings. You can earn {shortTitle} every day by subscribing
                    to a variety of products that support early redemption.
                  </p>
                </div>
                <div className="logo_name">
                  <img src={cardlogo} alt="logo" />
                  <h4> {shortTitle}</h4>
                </div>
                <div className="token___card_sec__ad12">
                  <div className="ad__12_data">
                    <div className="ad__12_data__New">
                      <label htmlFor="New-__SD">Amount</label>
                      <input type="number" className="input__Token" id="New-__SD" onChange={(e: any) => setamount(e.target.value)} value={amount} />
                    </div>
                    <h5>Total {shortTitle} Subscribed</h5>
                  </div>
                  <div className="ad__12_data  ad__12_data__selet">
                    <div className="ad__12_data__New form-group">
                      <label htmlFor="sel1">Years</label>
                      <select className="form-control" id="sel1" onChange={(e: any) => { setmonthKey(e.target.value) }}>
                        <option value={0}>1</option>
                        <option value={1}>1.5</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </select>
                    </div>
                    <h5>Cumulative Profit</h5>
                  </div>
                </div>
                <h4 className="token_card_sec_title"> Other Events</h4>
                <div className="token_card_sec">
                  <div className="ad_12_data">
                    <p> {((amount * (percentage?.[monthKey]?.pers / 100)) * month?.[monthKey]?.mon)?.toFixed(3)} {shortTitle}</p>
                    <h5>Total Profits</h5>
                  </div>
                  <div className="ad_12_data">
                    <p>{Number(((amount * (percentage?.[monthKey]?.pers / 100)) * month?.[monthKey]?.mon)?.toFixed(3)) + Number(amount)} {shortTitle}</p>
                    <h5>Total Returns</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="coustom_container sta_power">
        <h6>Feel the power of {shortTitle}</h6>

        <Link to="#">
          <p>Cutting-edge, cross-chain trading, made simple</p>
        </Link>

        <Row>
          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={future_margin} alt="" />
              <h6>Futures Margin</h6>
              <p>
                Use {shortTitle} as a margin to unlock a 15% discount on trading
                fees.
              </p>

              <Link to="#">Stay Tuned</Link>
            </div>
          </Col>

          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={trending} alt="" />
              <h6>Trading Fees</h6>
              <p>
                Pay with {shortTitle} to get a 20% discount on all trading fees.
              </p>

              <Link to="#">check details</Link>
            </div>
          </Col>

          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={cooperation} alt="" />
              <h6>KOL Cooperation</h6>
              <p>
                Use {shortTitle} as collateral for more collaboration
                opportunities.
              </p>

              <Link to="#">Stay Tuned</Link>
            </div>
          </Col>

          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={multiple_gift} alt="" />
              <h6>Impressive Rewards </h6>
              <p>
                Holding {shortTitle} gives you access to {shortTitle} rewards,
                private circle tickets, and more.
              </p>

              <Link to="#">Stay Tuned</Link>
            </div>
          </Col>

          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={passive_income} alt="" />
              <h6>Earn Passive Income</h6>
              <p>Stake {shortTitle} to earn and enjoy value-added income.</p>

              <Link to="#">Stay Tuned</Link>
            </div>
          </Col>

          <Col md={6}>
            <div className="card">
              <span className='left__top__border'></span>
              <span className='right__top__border'></span>
              <span className='left__bottom__border'></span>
              <span className='right__bottom__border'></span>
              <img src={investment_access} alt="" />
              <h6>Early Investment Access</h6>
              <p>
                Use {shortTitle} as a margin to unlock a 15% discount on trading
                fees.
              </p>

              <Link to="#">Stay Tuned</Link>
            </div>
          </Col>
        </Row>
      </div>


    </div>
  );
};

export default Token;
