import "./Assets/scss/style.scss";
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Index from "./Public/Index";
import Header from "./Public/Header";
import Footer from "./Public/Footer";
import Fees from "./Public/FooterPages/Fees";
import Referral from "./Public/Section/Referral";
import ComplianceLicense from "./Public/FooterPages/ComplianceLicense";
// import Referrel from "./Public/Templates/Referrel";
import About from "./Public/FooterPages/About";
import Feedback from "./Public/FooterPages/Feedback";
import ProtectionFund from "./Public/FooterPages/ProtectionFund";
import ProofReserves from "./Public/FooterPages/ProofReserves";
import BuyCrypto from "./Public/FooterPages/BuyCrypto";
import Careers from "./Public/FooterPages/Careers";
import IvServices from "./Public/FooterPages/IvServices";
import Earn from "./Public/FooterPages/Earn";
import Markets from "./Public/FooterPages/Markets";
import FeeSchedule from "./Public/FooterPages/FeeSchedule";
import Verification from "./Public/FooterPages/Verification";
import BuyBitcoin from "./Public/FooterPages/BuyBitcoin";
import CopyTrading from "./Public/FooterPages/CopyTrading";
import Policies from "./Public/FooterPages/Policies";
import Launchpad from "./Public/FooterPages/Launchpad";
import AffiliateProgram from "./Public/FooterPages/AffiliateProgram";
import VipIntroduce from "./Public/FooterPages/VipIntroduce";
import GettingApiKey from "./Public/ApiPages/GettingApiKey";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MorningCup from "./Public/FooterPages/MorningCup";
import Bonus from "./Public/Templates/Bonus";
import PublicMainTrading from "./Public/TradingComp/MainTrading";
import PublicManualTrading from "./Public/TradingCompManual/ManualTrading";
import LaunchBase from "./Public/Templates/LaunchBase";
import CommingSoon from "./Public/CommingSoon/CommingSoon";
import Rewards from "./Public/FooterPages/Rewards";
import Webform from "./Public/FooterPages/Webform";
import LegalTerms from "./Public/FooterPages/LegalTerms";
import CoinListing from "./Public/CoinListing";
import Security from "./Public/FooterPages/Security";
import Help from "./Public/Help";
import Test from "./Assets/test";
import Safty2 from "./Public/FooterPages/Safty2";
import Tradingview from "./Public/FooterPages/Tradingview";
import UserAgreement from "./Public/FooterPages/UserAgreement";
import Freetrial from "./Public/Freetrial";
import BlogNew from "./Public/FooterPages/Blog";
import Token from "./Public/FooterPages/Token";
import Mobile_view from "./Public/FooterPages/Mobile_view";

function App() {


  function PublicLayout() {
    return (
      <>
        <div className="login__container">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </>
    );
  }

  function PublicMarketLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  function ApiLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }


  function CommingSoonlayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }
  function PolicyLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Router>
        <Routes>

          <Route element={<PublicLayout />}>

            {/* --  ONLY TEST FILE PLEASE NOT REMOVE.. -- */}

            <Route path="/test" element={<Test />} /> 
            <Route path="/" element={<Index />} />
            <Route path="*" element={<Index />} />
            <Route path="/fees" element={<Fees />} />
            <Route path="/about" element={<About />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/blog" element={<BlogNew />} />
            <Route path="/inner-blog" element={<MorningCup />} />
            <Route path="/protection-fund" element={<ProtectionFund />} />
            <Route path="/proof-reserves" element={<ProofReserves />} />
            <Route path="/fee-schedule" element={<FeeSchedule />} />
            <Route path="/verification-channel" element={<Verification />} />
            <Route path="/buy-bitcoin" element={<BuyBitcoin />} />
            <Route path="/token" element={<Token />} />
            <Route path="/buy-crypto" element={<BuyCrypto />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/promotions" element={<Rewards />} />
            <Route path="/institutional-services" element={<IvServices />} />
            <Route path="/regulatory-license" element={<ComplianceLicense />} />
            <Route path="/referral-program" element={<Referral />} />
            <Route path="/copy-trading" element={<CopyTrading />} />
            <Route path="/vip-services" element={<VipIntroduce />} />
            <Route path="/launchpad" element={<Launchpad />} />
            <Route path="/affiliate-program" element={<AffiliateProgram />} />
            <Route path="/earn" element={<Earn />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/legal-terms" element={<LegalTerms />} />
            <Route path="/coin-listing" element={<CoinListing />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/security" element={<Security />} />
            <Route path="/launchbase" element={<LaunchBase />} />
            {/* <Route path="/help-data" element={<HelpData />} /> */}
            <Route path="/Webform" element={<Webform />} />
            <Route path="/help" element={<Help />} />
            <Route path="/trust-and-safety" element={<Safty2 />} />
            <Route path="/tradingview" element={<Tradingview />} />
            <Route path="/mobile" element={<Mobile_view />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/Freetrial" element={<Freetrial />} />
          </Route>


          {/* Public Market ( Trading page......) */}
          <Route element={<PublicMarketLayout />}>
            <Route path="/trade/:id" element={<PublicMainTrading />} />
            <Route path="/trades/:id" element={<PublicManualTrading />} />
          </Route>

          <Route element={<ApiLayout />}>
            <Route path="/getting-api-key" element={<GettingApiKey />} />
          </Route>

          <Route element={<CommingSoonlayout />}>
            <Route path="/coming-soon" element={<CommingSoon />} />
          </Route>

          {/* Policy Pages.... layout  */}

          <Route element={<PolicyLayout />}>
            <Route path="/privacy-policy" element={<Policies />} />
          </Route>

        </Routes>


      </Router>
      <ToastContainer />
    </>
  );
}

export default App;