import React from 'react'
import { Link } from 'react-router-dom';
import { title } from '../config/config';
import reset_pwd from '../Assets/Img__v2/reset-password (2).png';
import fund_pwd from '../Assets/Img__v2/reset-password (1).png';
import email from '../Assets/Img__v2/email.png';
import edit_phone from '../Assets/Img__v2/smartphone.png';
import recharge from '../Assets/Img__v2/mobile (1).png';
import edit_google from '../Assets/Image/edit_google.png';
import { SearchRounded } from '@mui/icons-material';


const Help = () => {
    return (
        <>
            <div className="help_page only-margin">
                <div className="help_page__banner ">
                    <div className="coustom_container">
                        <div className="new_container">
                            <div className="top_sec">
                                <div className="info">
                                    <h1>How may we assist you?</h1>
                                    <div className='searchbar'>
                                        <div className='search_ic'><SearchRounded className='searchicon' /></div>
                                        <input type="search" placeholder="Please enter your question here" className='search-input-sec' />
                                    </div>
                                </div>

                            </div>

                            <div className="service-sec">
                                <div className='spacing'>
                                    <h2>Self Service</h2>
                                    <div className='row self-service-row'>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={reset_pwd} alt="reset pwd" />
                                                        <h1>Reset Login Password</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Graphic Design</h4>
                                                        <p>Forgot your login password? No worries! Reset your password easily by following the simple steps on our Help page..</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={fund_pwd} alt="reset pwd" />
                                                        <h1>Change Funds Password</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Change Funds Password</h4>
                                                        <p>Secure your funds! Easily change your funds password on {title} to ensure enhanced protection for your assets. Follow the steps to update your password securely..</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={edit_phone} alt="reset pwd" />
                                                        <h1>Edit Phone Number</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Edit Phone Number</h4>
                                                        <p>Update your phone number password effortlessly on {title}. Keep your account secure and manage your contact details with ease. Follow the steps to make the changes.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={email} alt="reset pwd" />
                                                        <h1>Email Setup</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Email Setup </h4>
                                                        <p>Set up your email password for added security on {title}. Safeguard your communication and account access. Follow the steps to configure your email password.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={edit_google} alt="reset pwd" />
                                                        <h1>Edit Google Authentication</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Edit Google Authentication</h4>
                                                        <p>Modify your Google Authentication easily on {title}. Enhance your account's security with two-factor authentication. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="service service1">
                                                    <div className="front">

                                                        <img src={recharge} alt="reset pwd" />
                                                        <h1>Recharge Self-Service Acceleration</h1>
                                                    </div>
                                                    <div className="back">
                                                        <h4>Recharge Self-Service Acceleration</h4>
                                                        <p>Boost your recharge process with self-service acceleration on {title}. Get faster and smoother deposits for an improved trading experience. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="latest">
                                <div className='spacing'>
                                    <h2>Latest Articles</h2>
                                    <div className='latest-articles'>
                                        <ul>
                                            <li>How to Manage Assets in the Gate Web3 Wallet App?</li>
                                            <li>How to Manage Assets in the Gate Web3 Wallet on the Web?</li>
                                            <li>How to Utilise Gate Web3 Wallet on Chrome?</li>
                                            <li>Web3 Startup FAQs: Gate</li>
                                            <li>How can one take part in the Gate Web3 Startup Subscription?</li>
                                            <li>Instructions for Backtesting a Strategy.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="acvity-sec">
                                <div className='inner'>
                                    <h2>Latest Updates</h2>
                                    <div className='latest-articles'>
                                        <ul>
                                            <li>{title}.Io introduces a new Structured Product: Earn up to 15% APR during weekends.
                                                <br /> <span>Updated 5 minutes ago.</span>
                                            </li>
                                            <li>Trading of MuratiAI (MURATIAI) has been postponed.<br /><span>Updated 19 minutes ago.</span></li>
                                            <li>{title}.Io will list Aura Network (AURA).<br /><span>Updated 36 minutes ago.</span></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="acvity-sec">
                                <div className='inner'>
                                    <h2>Recent Activities</h2>
                                    <div className='latest-articles'>
                                        <ul>
                                            <li>Live Trading in Crypto Market
                                                <br /> <span>Last Updated: 3 days and 12 hours ago</span>
                                            </li>
                                            <li>Long/Short Market Analysis<br /><span>Last Updated: 2 days and 20 hours ago</span></li>
                                            <li>Weekend Playground 😻 (💜Random Rain of Red Envelopes 🧧)<br /><span>Last Updated: 2 days and 20 hours ago</span></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="create-future-sec">

                                <div className='spacing'>
                                    <div className='row why-choose-row'>
                                        <div className='col-lg-6 col-md-6'>
                                            <h2>Latest Announcements</h2>
                                            <div className="card">

                                                <div className="card-body">
                                                    <h5>{title}.io Structured Product Launched: Enjoy weekend & Earn up to 15% APR</h5>
                                                    <p>Updated at:5 min ago</p>
                                                    <h5><>The Postponement of MuratiAI (MURATIAI) Trading </></h5>
                                                    <p>Updated at:19 min ago</p>
                                                    <h5>{title}.io will list  Aura Network (AURA)</h5>
                                                    <p>Updated at:36 min ago</p>
                                                    <div className='view-more'><Link to="#">View more</Link></div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6'>
                                            <h2>Recent Activities</h2>
                                            <div className="card">

                                                <div className="card-body">
                                                    <h5>CRYPTO MARKET LIVE TRADING</h5>
                                                    <p>Updated at:3 days 12 hours ago</p>
                                                    <h5>LONG/SHORT MARKET ANALYSIS </h5>
                                                    <p>Updated at:2 days 20 hours ago</p>
                                                    <h5>Weekend Playground😻 (💜Random rain of red envelopes🧧)</h5>
                                                    <p>Updated at:2 days 20 hours ago</p>
                                                    <div className='view-more'><Link to="#">View more</Link></div>
                                                </div>

                                            </div>
                                        </div>  </div>

                                </div>

                                <div>
                                </div>
                            </div>

                            <div className="win-rewards">
                                <div className='info'>
                                    <h1>Win up to $100 in Welcome Rewards</h1>
                                    <p>Complete Beginner Tasks & Win Awesome Prize</p>
                                    <Link to="/login" className='btnhero-sec text-decoration-none'>Create Account</Link>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Help