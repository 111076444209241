import React from 'react'
import Banner from './Section/Banner'
import Communityicons from './Section/Communityicons'
import Download from './Section/Download'
import Section2 from './Section/Section2'
import { Link } from 'react-router-dom'
import CryptoMIn from './Section/CryptoMIn'
import { ShortName, shortTitle, title } from '../config/config'
import crypto_journey from '../Assets/Image/crypto_journey.png';

const Index = () => {

  return (
    <div className='index_main-page'>
      <Banner />
      <Section2 />
      <Communityicons />
      <CryptoMIn />

      <div className='Need__help'>
        <div className='coustom_container'>
          <h2>Need Help?</h2>
          <div className='card___need__help'>
            <span className='left__top__border'></span>
            <span className='right__top__border'></span>
            <span className='left__bottom__border'></span>
            <span className='right__bottom__border'></span>

            <div className='row'>
              <div className='col-md-4 columns__need'>
                <div className='card__need__sec'>
                  <h4>24/7 Customer Support -</h4>
                  <p>Get 24/7 support from our friendly Customer Support agents.</p>

                  <Link to="/Webform" className='need__liks__a'> Chat Now</Link>
                </div>
              </div>

              <div className='col-md-4 columns__need'>
                <div className='card__need__sec'>
                  <h4>Learn -</h4>
                  <p>Discover a range of trading guides tailored for beginners and advanced traders alike.</p>
                  <Link to="/privacy-policy" className='need__liks__a'> Learn More</Link>
                </div>

              </div>

              <div className='col-md-4 columns__need'>
                <div className='card__need__sec'>
                  <h4>FAQs -</h4>
                  <p>Explore FAQs for detailed instructions on specific features..</p>
                  <Link to="/privacy-policy" className='need__liks__a' > Learn More</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      {/* FAQ SEC___ CSS Banner.scss */}
      <div className='FAQ__A'>
        <div className='coustom_container'>
          <h1>Frequently Ask Questions</h1>
          <div className='row'>
            <div className='col-md-6'>
              <div className='FQA__INNWE'>
                <h3>What Is {title}?</h3>
                <p>Bitcoin Is A Decentralized Digital Currency, Meaning That It Lacks A Central Bank Or Single Administrator. Bitcoin Can Be Sent From User To User Through The Peer-To-Peer Network Without The Need For Intermediaries.</p>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='FQA__INNWE'>
                <h3>Is {title} A Safe Cryptocurrency Exchange?</h3>
                <p>{title} Boasts One Of The World's Most Sophisticated Security Technology And Maintenance Team, And Is Constantly Upgrading Its Security Systems To Ensure The Safety Of User Assets And Accounts.</p>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='FQA__INNWE'>
                <h3>Can I Start Trading With Just $1??</h3>
                <p>{title} Allows Users To Improve Their Practical Experience Through A Variety Of Trading And Financial Products For As Low As $1.</p>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='FQA__INNWE'>
                <h3>Is There An Exchange Limit Between Fiat And Crypto?
                </h3>
                <p>{title} Has No Restrictions On The Exchange Between Fiat And Crypto, And Supports Over 50 Fiat Currencies Through Our P2P Market And Credit/Debit Card Channels.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Download />


      <div className="c___adventues d-none">
        <div className='coustom_container'>
          <div className='row crypto__sec'>
            <div className='col-md-5 journey'>
              <h1>
                Begin Your Cryptocurrency Adventure
              </h1>
              <h3> {ShortName} Simplifies the Onset. Join Now for Trading Over 200 Cryptocurrencies.</h3>
              <Link to="/sign-up" className="btnhero-sec text-decoration-none">
                Get started with {shortTitle}</Link>
            </div>
            <div className='col-md-7  right_img_sec'>
              <img src={crypto_journey} alt="crypto_journey__img" />
            </div>
          </div>
          <div className='rewards__sec'>
            <p> Geographic restrictions and eligibility requirements apply to Margin, Futures, and Opt-In Rewards.</p>
            <p> Please note that Reward Rates can change and depend on complying with KISSANX's Terms. Information provided is for general knowledge only and does not constitute investment advice, recommendations, or solicitation for crypto activities. Some crypto products and markets lack regulation, potentially without government compensation or protection. Cryptoasset market volatility poses risks, leading to potential loss. Tax implications may arise from returns or asset value growth, requiring independent tax advice. Consult KISSANX's detailed Terms of Service for more information.</p>
            <p>Engaging in leveraged trading of futures, derivatives, and other instruments carries a level of risk and may not be appropriate for all individuals. Refer to KISSANX's Risk Disclosure for further insights.</p>
          </div>

        </div>

      </div>


    </div >
  )
}

export default Index