import React from 'react'
import { title, website } from '../../config/config'
import { Link } from 'react-router-dom'
import { Android, Apple, Shop } from '@mui/icons-material'


const Download = () => {
    return (
        <div>
            <div className="download_sec">
                <div className="coustom_container">
                    <div className="row new__add">
                        <div className="col-12 text-center">
                            <div className=' mb__LEptop'>
                                <p> {title.toUpperCase()} PRO</p>
                                <h1> Trade Anytime, Anywhere.</h1>
                                <h3>Open new positions instantly, whether it's on {title} App or Web.</h3>

                                <div className='QR__area text-center'>
                                    <div className='qr__img__Sicd'>
                                        <img src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=` + website}  alt="qr__img"/>
                                    </div>
                                    <div className='mt-2'>
                                        <span>Scan Now to Download</span>
                                        <p>iOS & Android</p>
                                    </div>
                                </div>

                                <div className='Btn__group'>
                                    <Link className='button btn__more' to="/">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <Apple />
                                        <span>App Store</span>
                                    </Link>

                                    <Link className='button btn__more' to="/">
                                    <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <Android />
                                        <span>Android APK</span>
                                    </Link>

                                    <Link className='button btn__more' to="/">
                                          <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <Shop />
                                        <span>Google Play</span>
                                    </Link>



                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-none">
                            <div className='laptop__screen'>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default Download
