import React, { useEffect, useState } from 'react'
import taskgift from '../../Assets/Image/taskgift.png';
import Card from 'react-bootstrap/Card';
import { shortTitle, title } from '../../config/config';
import { Link } from 'react-router-dom';
import promotions_ from '../../Assets/Image/promotions_.png';
import lucky__draw from '../../Assets/Img__v2/coin_group.png';
import { PaidTwoTone, CardGiftcardTwoTone, CandlestickChartTwoTone } from '@mui/icons-material';




const Rewards = () => {

    const checkAuth = localStorage.getItem('auth');

    const Authbutton = () => {
        if (checkAuth !== "true") {
            return (
                <>
                    <Link to="/login" className='main-button'>Log In</Link>

                </>
            )
        }
        else {
            return (
                <>
                    <Link to="/dashboard" className='main-button'> Dashboard</Link>

                </>
            )
        }
    }



    useEffect(() => {
        Authbutton()
    }, [Authbutton]);


    const text = "Task Earnings: Up to 5,005 USDT.";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);


    return (

        <div className="main_rewardspage only-margin">

            <div className="coustom_container for__bg">
                <div className="top-sec">
                    <h4>PROMOTIONS</h4>
                    <h1 className="sentence">{content}</h1>
                    <p>Experience an unparalleled connection to your crypto, DeFi, and NFTs. Seamlessly access Web3 now using the {title} Token by {title}.
                    </p>
                </div>
            </div>

            <div className="last-sec">
                <div className="coustom_container">
                    <div className="inner">
                        <h2>Tasks for new users.</h2>
                        {/* <p>The Trust and Safety Team on the {title} platform has implemented cutting-edge industry practices to ensure the protection of our users from fraud and abuse.</p> */}
                    </div>
                    <div className="card_sec">

                        <div className="inner_sec">
                            <div className="info">
                                <CardGiftcardTwoTone />
                                <h1>Sign Up</h1>
                                <p>Sign up now at {title} for exclusive promotions, revolutionary crypto experiences, and a glimpse into the future of finance.</p>
                            </div>
                        </div>

                        <div className="inner_sec">
                            <div className="info">
                                <PaidTwoTone />
                                <h1>Deposit</h1>
                                <p>Boost your crypto journey! Deposit now at {title} and unlock exciting rewards, empowering your financial exploration with {title}. Don't miss out!</p>
                            </div>
                        </div>

                        <div className="inner_sec For_basis">
                            <div className="info">
                                <CandlestickChartTwoTone />
                                <h1>Trade</h1>
                                <p>Seamlessly exchange assets, seize opportunities, and shape your financial destiny with {title}'s innovative platform. Trade now!</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="coustom_container rewards_dailydraw pt-2">
                <div className="row__coustom">
                    <span className='left__top__border'></span>
                    <span className='right__top__border'></span>
                    <span className='left__bottom__border'></span>
                    <span className='right__bottom__border'></span>
                    <div className="info">

                        <h5>Daily Lucky Draw of -- USDT</h5>
                        <h6>Complete new user tasks to unlock advanced tasks</h6>
                    </div>
                  
{/*                     
                            <img src={lucky__draw} alt='' />
                     */}

                </div>

            </div>


            {/* <div className="coustom_container user_guide pt-5">
                        <h5>User Guide</h5>

                        <div className="row">
                            <div className="col-4 ">
                                
                                <Card>
                                                                
                                    <Card.Img variant="top" src={userguide_card}/>
                                                                                                                                        
                                </Card>  

                                <div className='cardfooter_text'>
                                    <h6>  How to trade in the Futures Market?</h6>
                                </div>
                                
                            </div>

                            <div className="col-4">
                                
                                <Card>
                                                                
                                    <Card.Img variant="top" src={userguide_card}/>
                                                                                                                                        
                                </Card>  

                                <div className='cardfooter_text'>
                                    <h6>  How to trade in the Futures Market？</h6>
                                </div>
                                
                            </div>

                            <div className="col-4 ">
                                
                                <Card>
                                                                
                                    <Card.Img variant="top" src={userguide_card}/>
                                                                                                                                        
                                </Card>  

                                <div className='cardfooter_text'>
                                    <h6>  How to trade in the Futures Market？</h6>
                                </div>
                                
                            </div>
                        </div>

                    </div> */}




        </div>
    )
}

export default Rewards