import React, { useState } from "react";
import { useEffect } from "react";
import license__sponsar_1 from "../../Assets/Image/Footer_IMG/footer.card.msb.png";
import license__sponsar_2 from "../../Assets/Image/Footer_IMG/footer.card.msb.png";

import { Link } from "react-router-dom";



const ComplianceLicense = () => {


  const text = "Compliance And Accountability";

  const [content, setContent] = useState('');
  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentLength < text.length) {
        const newText = text.slice(0, currentLength + 1);
        setContent(newText);
        setCurrentLength(currentLength + 1);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval time for the typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentLength]);


  return (
    <>
      <div className="License___sec only-margin">
        <div className="coustom_container for__bg">
          <div className="top-sec">
            <h4>FEE SCHEDULE</h4>
            <h1 className="sentence">{content}</h1>
            <p>We Are Committed To Protecting Your Assets, And We Take This Responsibility Seriously. Our Values Guide Us To Make Decisions That Meet Our Community's Needs And Have A Lasting Impact On Society.Our Operations Are Conducted Competently And Transparently, And Are Ever-Evolving To Meet Global Compliance Standards In Cryptocurrency. We Work With Affiliates To Ensure Our Information Is Accurate, And Our Platform Is Secure. We Offer Services And Products To Our Users That Reflect Our Industry-Leading Security And Credibility.
            </p>
          </div>
        </div>
        <div className="max__contain_license">
          <div className="license__sponsar coustom_container">
            <div className="IIner____container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card_img_le">
                      <img src={license__sponsar_1} alt="sponsar_img" />
                    </div>
                    <div className="card-itmes">
                      <h2> U.S. MSB License</h2>
                      <p>
                        Regulatory Authority: The Financial Crimes Enforcement
                        Network (FinCEN), U.S. Department of the Treasury
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="card">
                    <div className="card_img_le">
                      <img src={license__sponsar_2} alt="sponsar_img" />
                    </div>
                    <div className="card-itmes">
                      <h2> Canada MSB Licence</h2>
                      <p>
                        Regulatory Authority: Financial Transactions and Reports
                        Analysis Centre of Canada
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-main coustom_container">
          <div className="faq-inner">

            <div className="faq-sec">
              <h3>FAQ</h3>
              <div className="inner-sec">
                <div className="question">
                  <h4> Compliance Management</h4>
                  <p>Our dedicated team of experts ensures that we follow strict compliance standards and protocols. Our team follows a comprehensive compliance management framework, which is overseen by senior management, to identify, assess, execute and evaluate effectively.</p>
                </div>
                <div className="question">
                  <h4> eKYC</h4>
                  <p>We use extensive eKYC procedures to safeguard our platform and mitigate any potential risk from fraud and illicit behavior. We have established proper sanctions and anti-money laundering policies to ensure we comply with all relevant jurisdictions and prevent counterfeiting or other criminal activities on the platform.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ComplianceLicense;
