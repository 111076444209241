import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor
import { Link } from 'react-router-dom';
const CoinListing = () => {

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote", "bold", "italic", "underline", "strike"],
            ["link", "image", 'video', 'code-block'],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: ["right", "center", "justify"] }],
            [{ indent: '-1' }, { indent: '+1' }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        'indent',
        'code-block',
        'video',
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",

    ];

    const [code, setCode] = useState("Introduction");
    const handleProcedureContentChange = (content: React.SetStateAction<string>, delta: any, source: any, editor: any) => {
        setCode(content);

    };

    const text = "Explore Featured Crypto Listings";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);



    return (
        <div className='coin_listing_form only-margin'>

            <div className="coustom_container for__bg">
                <div className="top-sec">
                    <h4>COIN LISTING</h4>
                    <h1 className="sentence">{content}</h1>
                    
                </div>
            </div>

       
                <div className='coin_sec'>

                    <div className='webform_sec_body'>
                        <Form>
                           

                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Coin/Token Name</Form.Label>
                                        <Form.Control type="text" placeholder="Coin/Token Name" />
                                    </Form.Group>
                                </div>

                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Symbol</Form.Label>
                                        <Form.Control type="text" placeholder="Symbol" />
                                    </Form.Group>
                                </div>
                                <div className='choose-file'>
                                    <div className='file-sec'>
                                        <div className="mb-3">
                                            <label className="form-label">Logo Url</label>
                                            <label htmlFor="formFile" className="form-control lable">Choose file</label>
                                            <input className="d-none" type="file" id="formFile" />
                                        </div>
                                    </div>
                                    <div className='reset-btn'>
                                        <Button className='button' >Reset file</Button>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Issue Price
                                        </Form.Label>
                                        <Form.Control type="number" placeholder="Issue Price" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Issue Date   </Form.Label>
                                        <Form.Control type="date" placeholder="" />
                                    </Form.Group>
                                </div>

                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Max Supply</Form.Label>
                                        <Form.Control type="text" placeholder="Max Supply" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Circulating Supply</Form.Label>
                                        <Form.Control type="text" placeholder="Circulating Supply" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Explorer Link</Form.Label>
                                        <Form.Control type="text" placeholder="Explorer Link" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>White PP Link</Form.Label>
                                        <Form.Control type="text" placeholder="White PP Link" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <Form.Group className="mb-3  input-field">
                                        <Form.Label>Website Link</Form.Label>
                                        <Form.Control type="text" placeholder="Website Link" />
                                    </Form.Group>
                                </div>

                            </div>
                            <div className="col-md-12 col-lg-12">
                                <Form.Group className=" input-field">
                                    <Form.Label>Introduction</Form.Label>
                                </Form.Group></div>
                                
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={code}
                                onChange={handleProcedureContentChange}
                            />
                            <div className="submit_btn">
                                <div><Button className='button' >Save</Button></div>
                                <div><Button className='button' >Cancel</Button></div>
                            </div>
                        </Form>
                    </div>


                </div>
            </div>
    )
}

export default CoinListing