import React, { useEffect, useState } from 'react'
import qr from "../../Assets/Image/qrcode.png"
import mobile4 from "../../Assets/Image/Footer_IMG/mobile4.png"
import { title } from '../../config/config'

const Mobile_view = () => {


    const text = "Seamless Communication";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);



    return (
        <div>
            <div className="StaMobile-sec only-margin">
                <div className="coustom_container for__bg">
                    <div className="top-sec">
                        <h4>{title} MOBILE</h4>
                        <h1 className="sentence">{content}</h1>
                        <p>Discover seamless crypto trading with {title}'s mobile app. Effortlessly monitor market trends, manage your portfolio, and execute trades anytime, anywhere. Take control of your investments with user-friendly tools and top-notch security. Join now for a streamlined trading experience on the move.
                        </p>
                    </div>
                </div>
                <div className="sec-sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <h1>User-friendly tools</h1>
                            <p>Stay informed about market trends, build your cryptocurrency portfolio, and seamlessly execute your trading strategy with the help of intuitive tools.</p>
                            <div className="main-inner">
                                <div className="row">

                                    <div className="col-md-6 mb-5 d-flex align-items-center justify-content-center">
                                        <div className="card__inner">
                                            <img src={mobile4} alt="" />
                                            <div className="">
                                                <h1>Buy, Sell, Store</h1>
                                                <p>Start your trading journey with cutting-edge cybersecurity and dependable custody solutions. Keep track of asset prices and stay updated on real-time market trends wherever and whenever you need.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5 d-flex align-items-center justify-content-center">
                                        <div className="card__inner card_2">
                                            <img src={mobile4} alt="" />
                                            <div className="">
                                                <h1>Homepage</h1>
                                                <p>The mobile homepage of {title} provides a clear summary of your account, including watchlists, a segment highlighting top-performing assets, and educational material.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5 d-flex align-items-center justify-content-center">
                                        <div className="card__inner">
                                            <img src={mobile4} alt="" />
                                            <div className="">
                                                <h1>Stay updated and never overlook a market shift.</h1>
                                                <p>Establish price alerts to stay ahead in the market and get notified when the ideal moment arises. Never pass up on a chance to make a purchase!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5 d-flex align-items-center justify-content-center">
                                        <div className="card__inner card_2">
                                            <img src={mobile4} alt="" />
                                            <div className="">
                                                <h1>Swift and secure process for registration.</h1>
                                                <p>Easily adopt the future of money with the {title} mobile app. Begin by signing up for a free account and complete your first purchase within a mere 3 minutes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobile_view
