import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Banner_tem from "../../Assets/Img__v2/card_silder_a.png";
import Banner_tema from "../../Assets/Img__v2/card_silder_b.jpg";
import Banner_temb from "../../Assets/Img__v2/card_silder_c.jpg";
import { Autoplay, Pagination, HashNavigation, Navigation } from "swiper";
import { api_url, markit_url, title } from '../../config/config';
import { Link } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import LoaderCom from '../../config/LoaderCom';
import _fetch from '../../config/api';




const Section2 = () => {
  const [emptyHotData, setemptyHotData] = useState([]);
  const [allHotMarket, setHotMarket] = useState([]);

  const [emptyNewData, setemptyNewData] = useState([]);
  const [allNewMarket, setNewMarket] = useState([]);

  const [emptyGainersData, setemptyGainersData] = useState([]);
  const [allGainersMarket, setGainersMarket] = useState([]);


  useEffect(() => {
    (async () => {
      setHotMarket([])
      setemptyHotData([])
      const data1 = await _fetch(`${api_url}public/hostList`, 'GET', {}, {});
      setemptyHotData(data1.data.data)
      setHotMarket(data1?.data?.data);
      //////////////NEW//////////
      setemptyNewData([])
      setNewMarket([])
      const newMarket = await _fetch(`${api_url}public/newCoin`, 'GET', {}, {});
      setNewMarket(newMarket.data.data)
      setemptyNewData(newMarket?.data?.data);

      //////////// gainer ////////////
      setGainersMarket([])
      setemptyGainersData([])
      const gainerMarket = await _fetch(`${api_url}public/topGainer`, 'GET', {}, {});
      setemptyGainersData(gainerMarket.data.data)
      setGainersMarket(gainerMarket?.data?.data);
    })()


  }, []);

  return (
    <>
      <div className='Slider__bg'>
        <div className='coustom_container'>
          <Swiper
            loop={true}
            slidesPerView={4}
            spaceBetween={20}
            // centeredSlides={true}
            autoplay={{
              delay: 2500,
              // disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}

            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            // navigation={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='card'>
                <div className='card-header'>
                  <img src={Banner_tem} alt='ad__img' width="100%" />
                </div>
                <div className='card-body'>
                  <p className='Brade__card_name'> {title?.toLocaleLowerCase()} Converter</p>
                </div>

              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='card'>
                <div className='card-header'>
                  <img src={Banner_tema} alt='ad__img' width="100%" />
                </div>
                <div className='card-body'>
                  <p className='Brade__card_name'> {title?.toLocaleLowerCase()} Converter</p>
                </div>

              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='card'>
                <div className='card-header'>
                  <img src={Banner_temb} alt='ad__img' width="100%" />
                </div>
                <div className='card-body'>
                  <p className='Brade__card_name'> {title?.toLocaleLowerCase()} Converter</p>
                </div>

              </div>
            </SwiperSlide>


            <SwiperSlide>
              <div className='card'>
                <div className='card-header'>
                  <img src={Banner_tem} alt='ad__img' width="100%" />
                </div>
                <div className='card-body'>
                  <p className='Brade__card_name'> {title?.toLocaleLowerCase()} Converter</p>
                </div>

              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='card'>
                <div className='card-header'>
                  <img src={Banner_tem} alt='ad__img' width="100%" />
                </div>
                <div className='card-body'>
                  <p className='Brade__card_name'> {title?.toLocaleLowerCase()} Converter</p>
                </div>

              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>



      <div className='home__market'>
        <div className='coustom_container'>
          <div className='home_market_header'>
            <h3>Trending Assets</h3>
            <Link to="/market"> View all 100+ coins <KeyboardArrowRight /></Link>
          </div>
          <div className='home_market_body'>
            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}

              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation, HashNavigation]}

              className="mySwiper"
            >
              <SwiperSlide>
                <div className='card'>
                  <h4 className='heading___card'>  Hot List </h4>

                  <div className='coin_list'>
                    <div className="d-flex align-items-center justify-content-center h-100 for_position">

                    <LoaderCom condition={emptyHotData} text={'Getting latest values...'} />
                    </div>
                    {allHotMarket?.map((val: any) => {
                      return (
                        <div className=''>
                          <Link to="#" className='stretched-link coin_list_name'>
                            <div className='coin_list_left'>
                              <img src={val?.image} alt="coin_img" className='coin__img' />
                              <div className='coin__list__name'>
                                <p className='name__coin'>{val?.symbol?.toUpperCase()}</p>
                                <h6 className='full__name_coin'> {val?.name}</h6>
                              </div>

                            </div>

                            <div className='coin_list_right'>
                              <div className='coin__list__name coin_list_right_name'>
                                <p className='coin__price'>${val?.current_price}</p>
                                <h6 className={val?.price_change_percentage_24h > 0 ? 'coin__price__profit' : 'coin__price_loss'}> -1.30%</h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })}


                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='card'>
                  <h4 className='heading___card'>  New Coins</h4>

                  <div className='coin_list'>
                  <div className="d-flex align-items-center justify-content-center h-100 for_position">

                    <LoaderCom condition={emptyNewData} text={'Getting latest values...'} />
                  </div>
                    {allNewMarket?.map((val: any, key: any) => {
                      return (
                        <div className=''>
                          <Link to="#" className='stretched-link coin_list_name'>
                            <div className='coin_list_left'>
                              <img src={val?.image} alt="coin_img" className='coin__img' />
                              <div className='coin__list__name'>
                                <p className='name__coin'>{val?.symbol?.toUpperCase()}</p>
                                <h6 className='full__name_coin'> {val?.name}</h6>
                              </div>

                            </div>

                            <div className='coin_list_right'>
                              <div className='coin__list__name coin_list_right_name'>
                                <p className='coin__price'>${val?.current_price}</p>
                                <h6 className={val?.price_change_percentage_24h > 0 ? 'coin__price__profit' : 'coin__price_loss'}> -1.30%</h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })}


                   
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='card'>
                  <h4 className='heading___card'>  Top Gainers </h4>

                  <div className='coin_list'>
                  <div className="d-flex align-items-center justify-content-center h-100 for_position">

                    <LoaderCom condition={emptyGainersData} text={'Getting latest values...'} />
                  </div>
                    {allGainersMarket?.map((val:any,key:any) => {
                      return (
                        <div className='' key={key}>
                          <Link to="#" className='stretched-link coin_list_name'>
                            <div className='coin_list_left'>
                              <img src={val?.image} alt="coin_img" className='coin__img' />
                              <div className='coin__list__name'>
                                <p className='name__coin'>{val?.symbol?.toUpperCase()}</p>
                                <h6 className='full__name_coin'> {val?.name}</h6>
                              </div>

                            </div>

                            <div className='coin_list_right'>
                              <div className='coin__list__name coin_list_right_name'>
                                <p className='coin__price'>${val?.current_price}</p>
                                <h6 className={val?.price_change_percentage_24h > 0 ? 'coin__price__profit' : 'coin__price_loss'}> -1.30%</h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })}


                    
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            <Link to="/market" className='only__sm d-none'> View all 100+ coins <KeyboardArrowRight /></Link>


          </div>
        </div>

      </div>
    </>
  )
}

export default Section2

