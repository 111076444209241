import React, { useEffect } from "react";
import { shortTitle, title, titleDescription } from "../../config/config";
import img__affilates from "../../Assets/Image/Footer_IMG/afficar__A.svg";
import Features from "../../Assets/Image/Footer_IMG/advantage.svg";

const AffiliateProgram = () => {


  return (
    
    <>
      <div className="affiliates_a only-margin">
        <div className="affiliates_a__banner ">
        <div className="main coustom_container">

            <div className="row">
              <div className="col-12">
                <h1>Become {title}'s <br/> Business Affiliate</h1>
                <p>
                  Affluence For Influence, Count Your Daily Commission. Get
                  Monthly Bonus & Exclusive {shortTitle} Bonus!
                </p>
              
              </div>
            
            </div>
        </div>
          
    
        </div>

        <div className="affiliates__cards">
          <div className="container">
            <div className="row ">
              <div className="col-4">
                <div className="card">
                  <img src={img__affilates} alt="icon" />
                  <h4>50%</h4>
                  <p>Up to 50% daily commission</p>
                </div>
              </div>

              <div className="col-4">
                <div className="card">
                  <img src={img__affilates} alt="icon" />
                  <h4>5,000 {shortTitle}</h4>
                  <p>Up to 5,000 {shortTitle} bonus for 3 months</p>
                </div>
              </div>

              <div className="col-4">
                <div className="card">
                  <img src={img__affilates} alt="icon" />
                  <h4>$3,000</h4>
                  <p>Up to $3,000 monthly bonus from referrals' sign up</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="affiliates__Advantage">
          <div className="coustom_container">
            <div className="aff__heading_ad">
              <h1>The {title} Advantage</h1>
              <h4>
                {" "}
                We help you monetize your influence and develop a sustainable
                passive income
              </h4>
            </div>

            <div className="aff__cards">
              <div className="row card-1">
                <div className="col-12 col-md-8 col-lg-8 img___txt__add">
                  <h2>Diverse Features</h2>
                  <p>
                    {" "}
                    With our industry-leading one-click copy trading, grid
                    trading, mixed contracts, and much more, you can start
                    trading now and acquire monetized users.
                  </p>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <div className="add__end_img">
                    <img src={Features} alt="img" />
                  </div>
                </div>
              </div>

              <div className="row card-2">
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="add__left_img">
                    <img src={Features} alt="img" />
                  </div>
                </div>

                <div className="col-12 col-md-8 col-lg-8 img___txt__add">
                  <h2>Manage Transparently</h2>
                  <p>
                    {" "}
                    With our industry-leading one-click copy trading, grid
                    trading, mixed contracts, and much more, you can start
                    trading now and acquire monetized users.
                  </p>
                </div>
              </div>

              <div className="row card-3">
                <div className="col-12 col-md-8 col-lg-8img___txt__add">
                  <h2>Professional Support</h2>
                  <p>
                    {" "}
                    By leveraging our extensive resources, we promote content
                    and activities to convert your audiences. Eligible affiliate
                    partners will receive exposure and opportunities to grow
                    your audience and maximize your affiliate earnings with
                    {shortTitle}.
                  </p>
                </div>

                <div className="col-12 col-md-4 col-lg-4">
                  <div className="add__end_img">
                    <img src={Features} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="affi_"></section>
      </div>
    </>
  );
};

export default AffiliateProgram;
