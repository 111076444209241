import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import top_img from '../../Assets/Image/top_img.svg';
import ivcard from '../../Assets/Image/ivcard.svg';
import services_advan from '../../Assets/Image/services_advan.svg';
import Card from 'react-bootstrap/Card';
import { shortTitle, title } from '../../config/config';
import { Link } from 'react-router-dom';
const IvServices = () => {

    const text = "Institutional Services";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);
    return (
        <div className="iv_services_page only-margin">
            <div className="coustom_container for__bg">
                <div className="row">
                    <div className="col-md-7">

                <div className="top-sec">
                    <h4>INSTITUTIONAL</h4>
                    <h1 className="sentence">{content}</h1>
                    <p>Unlock prime blockchain solutions with {title}'s institutional services. Seamlessly integrate digital assets, access decentralized finance, and get personalized consultations. Elevate your institution's efficiency and security through cutting-edge technology. Join {title}'s  ecosystem today for a future empowered by blockchain.
                    </p>
                </div>
                    </div>
                </div>
            </div>

            <div className="coustom_container services_scope pt-5">
                <h5>Scope of Services</h5>

                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button variant="success ">Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button variant="success ">Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button variant="success ">Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button>Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button variant="success ">Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>



                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>
                                <div> <Button variant="success ">Apply</Button></div>
                            </Card.Body>

                        </Card>
                    </div>

                   
                </div>

            </div>

            <div className="coustom_container services_advantages pt-5">

                <h5>Advantages and Privileges</h5>

                <div className="row">
                    <div className="col-12 col-sm-12 col-lg-6">

                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>Discounted Fees for VIP</h6>
                                <p> Discounted fees in the first two months</p>

                            </div>
                        </div>

                    </div>

                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>Exclusive Withdrawal Limit </h6>
                                <p>Customized daily withdrawal limit</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>Tailored Sub-Account Service</h6>
                                <p> Add additional sub-accounts and create demo sub-accounts</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>Professional Trading Experience</h6>
                                <p>Increased order frequency and technical support through API rate limits</p>
                                <p>Reduced transaction latency and improved transaction efficiency</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>Professional Customer Support</h6>
                                <p>One-on-one account manager</p>
                                <p> Global customer support team providing services 24/7</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex_area">
                            <div>
                                <img src={services_advan} alt='' />
                            </div>

                            <div className='card-body-text'>

                                <h6>More to come</h6>
                                <p>  Stay tuned......</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="coustom_container sta_client">
                <div className="text_btn">
                    <h5>Become an institutional client of {shortTitle}</h5>
                    <div> <Button>Contact Us</Button></div>
                </div>
            </div>

        </div>
    )
}

export default IvServices