import { Link } from "react-router-dom";
import { ShortName, logo, shortTitle, title } from "../config/config";
import { Accordion } from "react-bootstrap";




const Footer = () => {

  const Token = `KSN`;

  return (
    <>
      <footer className="Footer__public___12 s-primary-bg">
        <div className="coustom_container footer-section-container">
          {/* footer navigation */}
          <nav className="footer_nav pb-5">
            <li className=" footer_navlist_A">
              <div className="web__f__deatial-12">
                <div className="f-web-logo-12">
                  <Link to="/" className="footer_logo_A line-height-0">
                    <img src={logo} alt="" width="100%" />
                  </Link>
                </div>
                <div className="inner">
                  {/* <h1>{title.toUpperCase()}</h1> */}
                  <p>Empower financial freedom with {title} Exchange </p>
                </div>
              </div>
            </li>
            <ul className="footer_navlist">
              {/* Footer Nav Item */}

              {/* Footer Nav Item */}
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Company
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/about"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/security"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Security
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/trust-and-safety"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Trust and safety
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/tradingview"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      TradingView
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/buy-crypto"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Buy Crypto
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/careers"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Careers
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  our products
                </h3>
                <ul className="footer_sub_navlist">

                  <li className="footer_sub_navitem">
                    <Link

                      to="/token"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      {Token} Token
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link

                      to="/protection-fund"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Protection Fund
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link

                      to="/proof-reserves"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Proof Reserves
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link

                      to="/fee-schedule"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Fee Schedule
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link

                      to="/verification-channel"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Verification Channel
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link

                      to="/buy-bitcoin"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Buy Bitcoin
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Service
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/bonus"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Bonus
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/launchpad"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      LeaderBoard
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/promotions"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Promotions
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/referral-program"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Referral Program
                    </Link>
                  </li>
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/affiliate-program"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Affiliate program
                    </Link>
                  </li> */}
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/vip-services"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      VIP Services
                    </Link>
                  </li> */}
                  <li className="footer_sub_navitem">
                    <Link
                      to="/mobile"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      {title} Mobile
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/getting-api-key"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      API Trading
                    </Link>
                  </li>


                  <li className="footer_sub_navitem">
                    <Link
                      to="/coin-listing"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Apply for Listing
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="footer_navitem For_basis">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Legal & Disclosures
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/help"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      {ShortName} Help
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/legal-terms"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Risk Disclosure
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/user-agreement"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      User Agreement
                    </Link>
                  </li>
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/terms-of-use"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Terms of Use
                    </Link>
                  </li> */}
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Legal Statement
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="footer_navitem For_basis1">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Support
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/Webform"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Submit Case
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/feedback"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Feedback
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/fees"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Fees
                    </Link>
                  </li>

                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/fee-schedule"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Fee Schedule
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/verification-channel"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Official Verification Channel{" "}
                    </Link>
                  </li> */}
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/buy-bitcoin"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Buy Bitcoin
                    </Link>
                  </li> */}
                </ul>
              </li>


              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Contact Us
                </h3>
                <ul className="footer_sub_navlist">
                  {/* <li className="footer_sub_navitem">
                    <a className="footer_nav_link text-lowercase" target="_blank" href="">listings@kissanx.com</a>
                  </li>

                  <li className="footer_sub_navitem">
                    <a className="footer_nav_link text-lowercase" target="_blank" href="">support@kissanx.com</a>
                  </li>
                  <li className="footer_sub_navitem">
                    <a className="footer_nav_link text-lowercase" target="_blank" href="">contact@kissanx.com</a>
                  </li>
                  <li className="footer_sub_navitem">
                    <a className="footer_nav_link text-lowercase" target="_blank" href="">partnership@kissanx.com</a>
                  </li> */}
                  <li className="footer_sub_navitem">
                    <Link
                      to="/institutional-services"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Institutional Services
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/regulatory-license"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Regulatory License
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/copy-trading"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Copy Trading
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/vip-services"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Vip Services
                    </Link>
                  </li>

                </ul>
              </li>
              {/* <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Copy Trading
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/copy-trading"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Copy Trading
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/startagy-trading"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Strategy Trading
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* <li className="footer_navitem add__class">
                <div className="more-footer-links">
                  <div className="two-two">
                    <Link to="/" className="media-link-footer">
                      {" "}
                      <FacebookIcon />{" "}
                    </Link>
                    <Link to="/" className="media-link-footer">
                      <InstagramIcon />{" "}
                    </Link>
                  </div>
                  <div className="two-two">
                    <Link to="/" className="media-link-footer">
                      {" "}
                      <TwitterIcon />{" "}
                    </Link>
                    <Link to="/" className="media-link-footer">
                      {" "}
                      <GoogleIcon />{" "}
                    </Link>
                  </div>
                </div>
              </li> */}
            </ul>
          </nav>

          {/* mobile footer */}

          <div className="mobile__footer">
            <div className="web__f__deatial-15">
              <div className="f-web-logo-12">
                <Link to="/" className="footer_logo_A line-height-0">
                  <img src={logo} alt="" width="100%" />
                </Link>
                <div className="inner">
                  <p>Empower financial freedom with {title} Exchange </p>
                </div>

              </div>
            </div>
            <div className="inner">
              <div className="right_header-sidebar">
                {/* <ol className="drop-nav">
                  <li className="drop-item drop-itemOnclick" id="sddsf">
                    <a
                      className="drop-link"
                      onClick={(e) => {
                        showContent(e);
                      }}
                    >
                      <span>Company</span>

                      <KeyboardArrowDownIcon />
                    </a>

                    <div className="header_mobail">
                      <ul className="nav__dropa">
                        <Link to="/about" className="nav-link">
                          About Us
                        </Link>
                        <Link to="/protection-fund" className="nav-link">
                          Protection Fund
                        </Link>
                        <Link to="/proof-reserves" className="nav-link">
                          Proof of Reserves
                        </Link>

                        <Link to="/token" className="nav-link">
                          STA Token
                        </Link>
                        <Link to="/buy-crypto" className="nav-link">
                          Buy Crypto
                        </Link>
                        <Link to="/careers" className="nav-link">
                          Careers
                        </Link>
                      </ul>
                    </div>
                  </li>

                  <li className="drop-item drop-itemOnclick" id="sddsf">
                    <a
                      className="drop-link"
                      onClick={(e) => {
                        showContent(e);
                      }}
                    >
                      <span> Service</span>

                      <KeyboardArrowDownIcon />
                    </a>

                    <div className="header_mobail">
                      <ul className="nav__dropa">
                        <Link to="/launchpad" className="nav-link">
                          STA Launchpad
                        </Link>
                        <Link to="/promotions" className="nav-link">
                          Promotions
                        </Link>
                        <Link to="/referral-program" className="nav-link">
                          Referral Program
                        </Link>
                        <Link to="/affiliate-program" className="nav-link">
                          Affiliate program
                        </Link>
                        <Link to="/vip-services" className="nav-link">
                          VIP Services
                        </Link>
                        <Link to="/institutional-services" className="nav-link">
                          Institutional Services
                        </Link>
                        <Link to="/api-trading" className="nav-link">
                          API Trading
                        </Link>
                      </ul>
                    </div>
                  </li>

                  <li className="drop-item drop-itemOnclick" id="sddsf">
                    <a
                      className="drop-link"
                      onClick={(e) => {
                        showContent(e);
                      }}
                    >
                      <span> Legal & Disclosures</span>

                      <KeyboardArrowDownIcon />
                    </a>
                    <div className="header_mobail">
                      <ul className="nav__dropa">
                        <Link to="/regulatory-license" className="nav-link">
                          Regulatory License
                        </Link>
                        <Link to="/privacy-policy" className="nav-link">
                          AML/KYC Policies
                        </Link>
                        <Link to="/privacy-policy" className="nav-link">
                          Privacy Policy{" "}
                        </Link>
                        <Link to="/privacy-policy" className="nav-link">
                          Terms of Use
                        </Link>
                        <Link to="/privacy-policy" className="nav-link">
                          Legal Statement
                        </Link>
                        <Link
                          to="/privacy-policy"
                          className="nav-link"
                        >
                          Risk Disclosure
                        </Link>
                      </ul>
                    </div>
                  </li>

                  <li className="drop-item drop-itemOnclick" id="sddsf">
                    <a
                      className="drop-link"
                      onClick={(e) => {
                        showContent(e);
                      }}
                    >
                      <span> Support </span>

                      <KeyboardArrowDownIcon />
                    </a>

                    <div className="header_mobail">
                      <ul className="nav__dropa">
                        <Link to="/feedback" className="nav-link">
                          Feedback
                        </Link>
                        <Link to="/fee-schedule" className="nav-link">
                          Fee Schedule{" "}
                        </Link>
                        <Link to="/verification-channel" className="nav-link">
                          Official Verification Channel
                        </Link>
                        <Link to="/buy-bitcoin" className="nav-link">
                          Buy Bitcoin
                        </Link>
                      </ul>
                    </div>
                  </li>

                  <li className="drop-item drop-itemOnclick" id="sddsf">
                    <a
                      className="drop-link"
                      onClick={(e) => {
                        showContent(e);
                      }}
                    >
                      <span> Copy Trading </span>

                      <KeyboardArrowDownIcon />
                    </a>

                    <div className="header_mobail">
                      <ul className="nav__dropa">
                        <Link to="/copy-trading" className="nav-link">Copy Trading</Link>
                        <Link to="/startagy-trading" className="nav-link">Strategy Trading   </Link>
                      </ul>
                    </div>
                  </li>
                </ol> */}

                <Accordion /* defaultActiveKey="" */>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="" id="sddsf">Company</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">
                          <Link to="/about" className="nav-link">
                            About Us
                          </Link>

                          <Link to="/security" className="nav-link">
                            Security
                          </Link>
                          <Link to="/trust-and-safety" className="nav-link">
                            Trust and safety
                          </Link>
                          <Link to="/tradingview" className="nav-link">
                            TradingView
                          </Link>

                          {/* <Link to="/token" className="nav-link">
                            STA Token
                          </Link> */}
                          <Link to="/buy-crypto" className="nav-link">
                            Buy Crypto
                          </Link>
                          <Link to="/careers" className="nav-link">
                            Careers
                          </Link>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header id="sddsf">Our products</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">

                          <Link
                            to="/token" className="nav-link">
                            {Token} Token
                          </Link>
                          <Link
                            to="/protection-fund" className="nav-link">
                            Protection Fund
                          </Link>
                          <Link
                            to="/proof-reserves" className="nav-link">
                            Proof Reserves
                          </Link>
                          <Link
                            to="/fee-schedule" className="nav-link">
                            Fee Schedule
                          </Link>
                          <Link
                            to="/verification-channel" className="nav-link">
                            Verification Channel
                          </Link>
                          <Link
                            to="/buy-bitcoin" className="nav-link">
                            Buy Bitcoin
                          </Link>


                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header id="sddsf">Service</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">
                          <Link to="/bonus" className="nav-link">
                            Bonus
                          </Link>
                          <Link to="/launchpad" className="nav-link">
                            LeaderBoard
                          </Link>
                          <Link to="/promotions" className="nav-link">
                            Promotions
                          </Link>
                          <Link to="/referral-program" className="nav-link">
                            Referral Program
                          </Link>

                          <Link to="/mobile" className="nav-link">
                            {title} Mobile
                          </Link>

                          <Link to="/getting-api-key" className="nav-link">
                            API Trading
                          </Link>

                          <Link to="/coin-listing" className="nav-link">
                            Apply for Listing
                          </Link>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header id="sddsf">Legal & Disclosures</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">
                          <Link to="/help" className="nav-link">
                            {ShortName} help
                          </Link>
                          <Link to="/privacy-policy" className="nav-link">
                            Privacy Policy
                          </Link>
                          <Link to="/legal-terms" className="nav-link">
                            Risk Disclosure
                          </Link>

                          <Link to="/user-agreement" className="nav-link">
                            User Agreement
                          </Link>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header id="sddsf">Support</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">
                          <Link to="/feedback" className="nav-link">
                            Feedback
                          </Link>
                          <Link to="/Webform" className="nav-link">
                            Submit Case
                          </Link>
                          <Link to="/fees" className="nav-link">
                            Fees
                          </Link>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header id="sddsf">Contact Us</Accordion.Header>
                    <Accordion.Body>
                      <div className="header_mobail">
                        <ul className="nav__dropa">
                          {/* <a className="nav-link text-lowercase" target="_blank" href="">listings@kissanx.com</a>
                          <a className="nav-link text-lowercase" target="_blank" href="">support@kissanx.com</a>
                          <a className="nav-link text-lowercase" target="_blank" href="">contact@kissanx.com</a>
                          <a className="nav-link text-lowercase" target="_blank" href="">partnership@kissanx.com</a> */}
                          <Link to="/institutional-services" className="nav-link">
                          Institutional Services
                          </Link>
                          <Link to="/regulatory-license" className="nav-link">
                          Regulatory License
                          </Link>
                          <Link to="/copy-trading" className="nav-link">
                          Copy Trading
                          </Link>
                          <Link to="/vip-services" className="nav-link">
                          Vip Services
                          </Link>
                         

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          {/* footer bottom */}
          <div className="footer_bottom">
            <div className="footer_bottom_left">
              <p className="sec_info s-normal-color opacity-0-7">
                CopyRight © {new Date().getFullYear()}
                <Link className="sec_info s-normal-color ms-1 me-1" target="_black" to="/">
                  {title}
                </Link>
                All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
