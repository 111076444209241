import React from 'react'
import { shortTitle } from '../../config/config';
import protection_bg from '../../Assets/Image/protection_bg.svg';
import Card from 'react-bootstrap/Card';
import { ArrowRightAlt } from '@mui/icons-material';
import btc_img from '../../Assets/Image/btc_img.png';
import pro_card1 from '../../Assets/Img__v2/Mobile login-pana.png';
import pro_card2 from '../../Assets/Img__v2/Copyright-pana.png';
import pro_card3 from '../../Assets/Img__v2/GDPR-cuate.png';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';


const ProtectionFund = () => {
    return (
        <div className='protection_page'>

            <div className='coustom_container probg_banner'>
                <div className="pro_banner">
                    <div className="row row_gap">
                        <div className="col align_left">
                            <h5>Your security, our priority</h5>
                            <h6>{shortTitle} Protection Fund</h6>
                            <p>We regularly monitor the {shortTitle} Protection Fund, as well as crypto's cybersecurity landscape, to make sure that our fund remains adequate for our customers.</p>
                        </div>

                        <div className="col">
                            <img src={protection_bg} alt='' />
                        </div>
                    </div>

                </div>

            </div>


            <div className='coustom_container bg_color'>
                <div className="top_cards pt-5 ">
                    <h5>The fund is currently valued at US $300 million.<span>{shortTitle}</span></h5>

                    <div className='row row_gap pt-4'>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <span className='left__top__border'></span>
                                <span className='right__top__border'></span>
                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>



                                <div className='card_topflex'>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>BTC</h6>
                                        <Card.Title>{shortTitle} 6500 BTC</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Button className='main-button' variant="success">Wallet <ArrowRightAlt /></Button>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <span className='left__top__border'></span>
                                <span className='right__top__border'></span>
                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>


                                <div className='card_topflex'>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>USDT</h6>
                                        <Card.Title>{shortTitle}160 million USDT</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Button className='main-button' variant="success">Wallet <ArrowRightAlt /></Button>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <span className='left__top__border'></span>
                                <span className='right__top__border'></span>
                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>


                                <div className='card_topflex'>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>USDC</h6>
                                        <Card.Title>{shortTitle} 40 million USDC</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Button className='main-button' variant="success">Wallet <ArrowRightAlt /></Button>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>


                    </div>
                </div>

                <div className="pro_text pt-5">
                    <span className='we_need_protection'></span>
                    <h5>Why do we need a protection fund?</h5>

                    <h6>The {shortTitle} Protection Fund gives our platform an extra layer of resilience against cybersecurity threats.</h6>

                    <h6>In addition to our Proof of Reserves, the fund shows that we’re committed to protecting your assets, and that we operate with transparency and integrity.</h6>

                    <h6>If our customers have their accounts compromised or assets stolen or lost - not due to their own actions or trading behavior - then they may apply for a claim through the {shortTitle} Protection Fund.</h6>

                </div>

                <div className="assets_cards pt-5">
                    <h5>Learn how to protect your assets.</h5>
                    <h6>Read our Academy articles on how to protect your account on Web3.</h6>

                    <div className='row row_gap pt-4 pb-5'>
                        <div className='col-12 col-md-4 col-lg-4 asset-col-sec'>
                            <Card>

                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>


                                <Card.Img variant="top" src={pro_card1} />
                                <Card.Body>
                                    <div><Button variant="success">Cryptocurrency</Button></div>

                                    <Card.Text>$300M {shortTitle} Protection Fund to Protect User's Assets</Card.Text>
                                    <Card.Title>December 22, 2022 ｜10 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-md-4 col-lg-4 asset-col-sec'>
                            <Card>
                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>
                                <Card.Img variant="top" src={pro_card2} />
                                <Card.Body>
                                    <div><Button variant="success">{shortTitle}</Button></div>

                                    <Card.Text>Solana's $6M Hack: How to Protect Your Money</Card.Text>
                                    <Card.Title>December 12, 2022 ｜ 5 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-md-4 col-lg-4 trending-card'>
                            <Card>
                                <span className='left__bottom__border'></span>
                                <span className='right__bottom__border'></span>
                                <Card.Img variant="top" src={pro_card3} />
                                <Card.Body>
                                        <div><Button variant="success">Cryptocrruncy</Button></div>
                                 

                                    <Card.Text>Rookie Mistakes When Starting Out in Crypto</Card.Text>
                                    <Card.Title>November 18, 2022 ｜ 10 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </div>


               
            </div>

        </div>
    )
}

export default ProtectionFund