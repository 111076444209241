import React, { useEffect, useState } from "react";
import {
  Search,
  UnfoldMore,
} from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import LoaderCom from "../../config/LoaderCom";
import LiveBalance from "./LiveBalance";

const Market = (props: any) => {

  const { id } = useParams();

  const [emptyData, setemptyData] = useState([]);

  const [elpMarket, setElpMarket] = useState([]);
  const [searchedVal, setSearchedVal] = useState('');

  const [tradeData, setTradeData] = useState([{}]);
  async function MARKET() {
    // setemptyData([]);
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=trade_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      setElpMarket(d?.market);
      setemptyData(d)
    }
  }
  useEffect(() => {
    MARKET()
    if (props.symbol.toLowerCase() == 'usdt' && props.symbol != '') {
      var nowStream = `busdusdt@trade`;
    } else {
      var nowStream = `${props.symbol.toLowerCase()}usdt@trade`;
    }
    const streams = ['@ticker', '@depth20', '@trade'];
    const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      // console.log(json.stream, nowStream)
      try {
        if ((json)) {

          if (json.stream === 'bnbusdt@ticker') {
          } else if (json.stream === 'bnbusdt@depth20') {
          } else if (json.stream === nowStream) {
            if (json) {
              var d = json.data;
              if (d.E > 0) {
                setTimeout(() => {
                  tradeData.slice(0, 21);
                  setTradeData(tradeData => [...tradeData.reverse(), d]);
                }, 5000);
              }
            }
          }
        }
      } catch (err) {
        // console.log(err);
      }
    };



  }, [])

  const tradeValue = "100%"

  const socket = {

    Profit: {
      width: "100%",
      backgroundImage: `linear-gradient( to right, var(--buy) ${tradeValue}, var(--buy) ${tradeValue} ,  Transparent 0%, Transparent 0% )`,
    },


    Loss: {
      width: "100%",
      backgroundImage: `linear-gradient( to right, var(--sell) ${tradeValue}, var(--sell) ${tradeValue} ,  Transparent 0%, Transparent 0% )`,
    },

  };

  return (
    <>
      <div className="main-market">

        <div className="data-sec">

          <div className="data-sec_new" style={{ display: props.CointTab }}>

            <div className="main-search">
              <div className="search-bar">
                <div className="icon">
                  <Search />
                </div>
                <div className="input1">
                  <input type="search" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="main-table">
              <table>
                <thead>
                  <tr>
                    <th className="pr">
                      pair <UnfoldMore />
                    </th>
                    <th className="pc">
                      Price <UnfoldMore />
                    </th>
                    <th className="ch">
                      Balance
                      {/* <UnfoldMore /> <SyncAlt /> */}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <div className="table-body">
                    <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                    {elpMarket?.filter((value: any, index: any) =>
                      !searchedVal.length || value?.symbol
                        .toString()
                        .toLowerCase()
                        .includes(searchedVal?.toString().toLowerCase())
                    ).map((value: any, index: any) =>
                      <tr>
                        <Link to="/"><td>
                          {/* <Star /> ZRX */}
                          <img src={value?.image} alt="coin" className="coins" />

                          <span style={{ color: "rgb(132, 142, 156)" }}>{value?.symbol?.toUpperCase()}/USDT</span>
                          {value?.price_change_percentage_24h >= 0 && <span className="clr clr_profilt">{value?.price_change_percentage_24h?.toFixed(2)}%</span>}
                          {value?.price_change_percentage_24h < 0 && <span className="clr ">{value?.price_change_percentage_24h?.toFixed(2)}%</span>}
                        </td>
                          <td className="pc">{value.current_price ? value.current_price?.toFixed(5) : null} USDT</td>
                          <td className="ch">
                            <h6 style={{ fontSize: '10px' }}>${value.usdt_income?.toFixed(2)} </h6>
                            <h6 style={{ fontSize: '10px' }}>{`${value?.symbol.toUpperCase()} ${value.total_coin ? value.total_coin?.toFixed(2) : '0.00'}`}</h6></td>
                        </Link>
                      </tr>

                    )}


                  </div>

                </tbody>
              </table>
            </div>
          </div>

          <div className="d-mobail-none main-table2" style={{ display: props.myorderTab }}>
            <div className="inner">
              <Tabs>
                <TabList>
                  <Tab>Market Trades</Tab>
                  <Tab className="react-tabs__tab this___None__a "> Order Book</Tab>
                </TabList>

                <TabPanel>
                  <div className="tab1">
                    <table className=" w-100">
                      <thead>
                        <tr>
                          <th className="pr">Price(USDT)</th>
                          <th className="pc">Amount </th>
                          <th className="ch">Time </th>
                        </tr>
                      </thead>

                      <tbody>


                        {tradeData?.reverse().slice(0, 21).map((item: any, index: any, tradeData: any) => {
                          if (item.p > 0) {
                            return (
                              <tr data-index={index} key={index} >
                                <td style={{ color: (index > 0 && item.p < tradeData[index - 1].p) ? '#e84650' : '#10a748' }}>{parseFloat(item.p)?.toFixed(3)}</td>
                                <td className='pc'>{parseFloat(item.q)?.toFixed(3)}</td>
                                <td className='ch'>{new Date(item.E).toLocaleTimeString("en-US")}</td>
                              </tr>
                            )
                          }
                        }
                        )}

                      </tbody>
                    </table>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="LiveBalance__inner-phone-view">
                    <LiveBalance symbol={props.id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} LoadApi={props.LoadApi} />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Market;
