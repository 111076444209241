import React, { useEffect, useState } from 'react';
import {
  TipsAndUpdates,
  RateReview,
  CardGiftcard,
  WatchLater,
} from "@mui/icons-material";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { shortTitle, title } from '../../config/config';

const Feedback = () => {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const text = "Feedback Much Valued!";

  const [content, setContent] = useState('');
  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentLength < text.length) {
        const newText = text.slice(0, currentLength + 1);
        setContent(newText);
        setCurrentLength(currentLength + 1);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval time for the typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentLength]);


  return (
    <>
      <div className="feedback-main">
        <div className="coustom_container for__bg">
          <div className="row">
            <div className="col-md-8">

          <div className="top-sec">
            <h4>FEEDBACK</h4>
            <h1 className="sentence">{content}</h1>
            <p>Share your thoughts with us at {title}! Your feedback fuels our growth. Let us know how we can enhance your experience. Together, we shape a better platform.
            </p>
          </div>
            </div>
          </div>
        </div>


        <div className="background">

        <div className="submit-sec">
          <div className="coustom_container">
            <h2>Submit Feedback</h2>
            <div className="cards-main">
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <h3>Ensuring Safety & Security</h3>
                <p>
                  If you discover any security vulnerabilities or bugs, please promptly share your feedback with us. Your input is invaluable in maintaining a secure environment.
                </p>
              </div>
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <h3>Product Recommendations</h3>
                <p>
                  If you come across any product-related glitches or possess ideas for enhancing our products, we value and encourage your input.
                </p>
              </div>
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <h3>Design and Formatting</h3>
                <p>
                  You are encouraged to provide feedback or suggestions related to visual elements or user-friendliness. Your input is greatly appreciated.
                </p>
              </div>
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <h3>Feedback on Operations</h3>
                <p>
                  We value your operations regarding any content misunderstandings related to events or announcements.
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className="work-sec">
          <div className="coustom_container">
            <h2>How Does It Work?</h2>
            <div className="work-cards">
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <TipsAndUpdates className="icon-mui" />
                <h3>Submit Feedback</h3>
              </div>
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <RateReview className="icon-mui" />
                <h3>Team Review</h3>
              </div>
              <div className="items">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>
                <CardGiftcard className="icon-mui" />
                <h3>Redeem Your Gift</h3>
              </div>
            </div>
            <div className="btm-txt">
              <p>
                {shortTitle} serves Users Globally, And We Regard The Safe And Stable
                Development Of The Platform As Our Top Priority. Your Valuable
                Feedback Helps Us Improve Our Product And Create A Better Future
                Together.
              </p>
              <button type="button" className="" onClick={onOpenModal}>
                <span>Let's Get Started! Click Here To Submit Feedback.</span>
              </button>
            </div>
          </div>
        </div>

        </div>

        <div className="serve-sec">
          <h2>We Take Great Pride in Providing Service to You.</h2>
          <div className="coustom_container">
            <div className="feedback-items">
              <div className="items">
                <span>
                  User Feedback <RateReview className="icon-mui" />
                </span>
                <h3>
                  Impressed with {title}.com! Intuitive UI, low fees, and quick deposits/withdrawals. The educational resources provided helped me navigate the crypto world confidently. A trustworthy platform for beginners and experts.
                </h3>
                <div className="user-sec">
                  <span>
                    <WatchLater className="icon-mui" />
                    {new Date().getFullYear()}
                  </span>
                  <span>User: **********</span>
                </div>
              </div>
              <div className="items">
                <span>
                  User Feedback <RateReview className="icon-mui" />
                </span>
                <h3>
                  {title} is a game-changer! Its user-friendly interface and efficient tools simplify cryptocurrency trading. I'm impressed by its security features and real-time updates. Highly recommended for traders!"
                </h3>
                <div className="user-sec">
                  <span>
                    <WatchLater className="icon-mui" />
                    {new Date().getFullYear()}
                  </span>
                  <span>User: **********</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={onCloseModal} center
        classNames={{
          modal: "feedback__sidebar",
        }}>
        <div className="inner">
          <h1>How likely are you to recommend our service to a friend or colleague?</h1>
          <div className="members">
            <div className="add-new d-flex justify-content-center align-items-center w-100">
              <span className="numbring">1</span>
              <span className="numbring">2</span>
              <span className="numbring">3</span>
              <span className="numbring">4</span>
              <span className="numbring">5</span>
            </div>
            <div className="add-new d-flex justify-content-center align-items-center w-100">
              <span className="numbring">6</span>
              <span className="numbring">7</span>
              <span className="numbring">8</span>
              <span className="numbring">9</span>
              <span className="numbring">10</span>
            </div>
          </div>
          <div className="info">
            <p>0 - Extremly Unlikely</p>
            <p>10 - Extremly likely</p>
          </div>

          <div className="bottom__sec">

            <div className="feilds">
              <p>What feature can we add to improve?</p>
              <textarea className='new_textarea' name="" id="" rows={2} cols={50} placeholder='We do love to hear your suggestion' ></textarea>
            </div>
            <div className="feilds">
              <p>Email(optional)</p>
              <textarea className='new_textarea' name="" id="" rows={1} cols={50} placeholder='Someone@something@gmail.com'></textarea>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button type='button' className='btn-success'>SEND FEEDBACK</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Feedback;
