import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import earn_banner from '../../Assets/Image/earn_banner.png';
import igu_img from '../../Assets/Image/igu_img.png';
import earn_gift from '../../Assets/Image/earn_gift.png';
import earn_table from '../../Assets/Image/earn_table.png';
import fire from '../../Assets/Image/fire.png';
import Card from 'react-bootstrap/Card';
import {SearchRounded} from '@mui/icons-material';
import {ArrowLeft} from '@mui/icons-material';
import {ArrowRight} from '@mui/icons-material';
import { Link } from "react-router-dom";
import { shortTitle ,title} from '../../config/config';
// import { title } from 'process';


const Earn = () => {
  return (

    <div className='earn_page'>
            <div className='coustom_container earn_banner pt-5 mt-5'>
            <div className="row">
                    <div className="col">
                        <h5>{shortTitle} Earn</h5>
                        <p>Grow Your Crypto with Passive Returns</p>
                        <div className='btn_padding'><Button className='' variant="success">Login</Button></div>
                    </div>

                    <div className="col">
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={earn_banner} alt=''/>
                        </div>

                    </div>
            </div>

            </div>

        {/* Next part bg white start */}
            <div className='coustom_container earn_cards pt-5'>
                
                <div className="white_card">
                    <div className="row">
                    <div className='col-12 col-sm-6 col-lg-6'>
                        <Card>
                            <div className="card_header">
                                <img src={igu_img} alt='' className='logo_icon'/>
                                <h6>IGU</h6>
                                <p>IGU</p>
                                <img src={fire} alt='' className='fire_icon'/>
                            </div>
                            <Card.Body>
                                <div className="card_footer">
                                  
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text>Flexible</Card.Text>
                                
                                </div>

                                <div className="card_footer">
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text><span>100.00%</span></Card.Text>
                                
                                </div>
                                
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-6'>
                        <Card>
                            <div className="card_header">
                                <img src={igu_img} alt='' className='logo_icon'/>
                                <h6>IGU</h6>
                                <p>IGU</p>
                                <img src={fire} alt='' className='fire_icon'/>
                            </div>
                            <Card.Body>
                                <div className="card_footer">
                                  
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text>Flexible</Card.Text>
                                
                                </div>

                                <div className="card_footer">
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text><span>100.00%</span></Card.Text>
                                
                                </div>
                                
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-6'>
                        <Card>
                            <div className="card_header">
                                <img src={igu_img} alt='' className='logo_icon'/>
                                <h6>IGU</h6>
                                <p>IGU</p>
                                <img src={fire} alt='' className='fire_icon'/>
                            </div>
                            <Card.Body>
                                <div className="card_footer">
                                  
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text>Flexible</Card.Text>
                                
                                </div>

                                <div className="card_footer">
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text><span>100.00%</span></Card.Text>
                                
                                </div>
                                
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-6'>
                        <Card>
                            <div className="card_header">
                                <img src={igu_img} alt='' className='logo_icon'/>
                                <h6>IGU</h6>
                                <p>IGU</p>
                                <img src={fire} alt='' className='fire_icon'/>
                            </div>
                            <Card.Body>
                                <div className="card_footer">
                                  
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text>Flexible</Card.Text>
                                
                                </div>

                                <div className="card_footer">
                                    <Card.Title>Duration</Card.Title>
                                    <Card.Text><span>100.00%</span></Card.Text>
                                
                                </div>
                                
                            </Card.Body>
                        </Card>
                    </div>

                    </div>
                       
                </div>


                <div className="earn_table pt-5">
                    <h5>{shortTitle} Earn</h5>

                    <div className="checkbox_header">
                        <div className="checkbox_left">

                        <label className="checkbox_container">Match My Assets
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>

                        <label className="checkbox_container">Available Only
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>

                            {/* <div>  
                                 <input type="checkbox" id="assets" name="assets" value="Bike" />
                                <label htmlFor="assets">Match My Assets</label>
                            </div>

                            <div>  
                                 <input type="checkbox" id="assets2" name="assets2" value="Bike" />
                                <label htmlFor="assets2">Available Only</label>
                            </div> */}

                            <div className='img_flex coupons'>
                                <Link to="#">
                                    <img src={earn_gift} alt=''/>

                                   My Coupons (0)
                                </Link>

                            </div>

                            
                        </div>

                        <div className="searchbar">
                        
                            <div><SearchRounded className='searchicon'/></div>

                            <div><input type="search" placeholder="Search coin" /></div>
                                    
                        </div>
                    </div>

                    <div className="table_part">
                    <div className="table_overflow">
                        <table>
                          <tr>
                            <th>
                            Coin 
                            {/* <span>
                                <Link to="#"><ArrowLeft /></Link>
                                <Link to="#"><ArrowRight /></Link>
                            </span> */}
                            </th>

                            <th>Duration</th>
                            
                            <th>
                            Est.APY  
                            {/* <span>
                                <Link to="#"><ArrowLeft /></Link>
                                <Link to="#"><ArrowRight /></Link>
                            </span> */}
                            </th>

                            <th><Link to="#">Auto-Subscribe </Link></th>
                            <th></th>
                          </tr>

                          <tr>
                            <td>
                                <div className="flex_text">
                                    <img src={earn_table} alt=''/>
                                    <b>VOLT</b>
                                    <p>VOLT</p>
                                </div>
                            </td>

                            <td>14 days</td>

                            <td><h6> 128.00% </h6></td>

                            
                            <td>
                            <label className='switch'>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                            </td>
                            
                            <td className='align_end'><Button className='table_btn '  variant="success">Ended</Button></td>
                          </tr>


                          <tr>
                            <td>
                                <div className="flex_text">
                                    <img src={earn_table} alt=''/>
                                    <b>VOLT</b>
                                    <p>VOLT</p>
                                </div>
                            </td>

                            <td>14 days</td>

                            <td><h6> 128.00% </h6></td>

                            <td>
                            <label className='switch'>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                            </td>

                            <td className='align_end'><Button className='table_btn' variant="success">Subscribe</Button></td>
                          </tr>


                          <tr>
                            <td>
                                <div className="flex_text">
                                    <img src={earn_table} alt=''/>
                                    <b>VOLT</b>
                                    <p>VOLT</p>
                                </div>
                            </td>

                            <td>14 days</td>

                            <td><h6> 128.00% </h6></td>

                          <td>
                            <label className='switch'>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                            </td>

                            <td className='align_end'><Button className='table_btn' variant="success">Subscribe</Button></td>
                          </tr>

                          <tr>
                            <td>
                                <div className="flex_text">
                                    <img src={earn_table} alt=''/>
                                    <b>VOLT</b>
                                    <p>VOLT</p>
                                </div>
                            </td>

                            <td>14 days</td>

                            <td><h6> 128.00% </h6></td>

                           <td>
                            <label className='switch'>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                            </td>

                            <td className='align_end'><Button className='table_btn' variant="success">Subscribe</Button></td>
                          </tr>

                          <tr>
                            <td>
                                <div className="flex_text">
                                    <img src={earn_table} alt=''/>
                                    <b>VOLT</b>
                                    <p>VOLT</p>
                                </div>
                            </td>

                            <td>14 days</td>

                            <td><h6> 128.00% </h6></td>

                           <td>
                            <label className='switch'>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                            </td>

                            <td className='align_end'><Button className='table_btn' variant="success">Ended</Button></td>
                          </tr>

                         
                        </table>
                      </div>
                    </div>
                </div>

                <div className='faqs pt-5'>
                    <h5>FAQs</h5>
                    
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is {shortTitle} Earn?</Accordion.Header>
                            <Accordion.Body>
                            {shortTitle} Earn, launched by {title}, provides users with high-yield investment opportunities for their cryptocurrency and helps them increase their crypto holdings.   
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How often is interest paid out?</Accordion.Header>
                            <Accordion.Body>
                            {shortTitle} Earn distributes interest to your spot account every day at 9:00  a.m. (UTC).  
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Do fixed-term investment products offer daily interest payments like flexible investment products?</Accordion.Header>
                            <Accordion.Body>
                            Yes, they do! Both of them offer daily interest payouts. 
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Why is my interest not reinvested into Earn products when Auto-Subscribe is on? </Accordion.Header>
                            <Accordion.Body>
                            Your interest will not be reinvested if your chosen Earn product is sold out or if you have reached the individual investment cap for it. Please note that the total quota and individual investment cap vary from one product to another. Please refer to the description of each investment product for the exact numbers.Additionally, if your interest is less than the minimum amount required for subscription, it will not be reinvested.  
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Will I receive my funds immediately after I redeem them?</Accordion.Header>
                            <Accordion.Body>
                            While you will receive your funds in the spot account immediately after choosing Fast Redemption for a flexible-term investment product, your funds invested in fixed-term products cannot be redeemed before the pre-determined redemption date, and will be reinvested into the flexible-term product of the same coin upon redemption.  
                            </Accordion.Body>
                        </Accordion.Item>

                        

                    </Accordion>

                   
                

                </div>


            




            </div>

   </div>
  )
}

export default Earn