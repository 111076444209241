import React, { useEffect, useState } from 'react'
import { title, website } from '../../config/config'
import { Link } from 'react-router-dom'

const UserAgreement = () => {
    const text = "User Agreement Overview";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (currentLength < text.length) {
          const newText = text.slice(0, currentLength + 1);
          setContent(newText);
          setCurrentLength(currentLength + 1);
        } else {
          clearInterval(interval);
        }
      }, 50); // Adjust the interval time for the typing speed
  
      return () => {
        clearInterval(interval);
      };
    }, [currentLength]);
    return (
        <div>
            <div className="UserAgreement-sec only-margin">
            <div className="coustom_container for__bg">
                    <div className="top-sec">
                        <h4>USER AGREEMENT</h4>
                        <h1 className="sentence">{content}</h1>
                        <p>The {title} document serves as a thorough outline detailing the terms and conditions that regulate user access and the services offered by the {website} platform. It encompasses essential elements including user obligations, privacy protocols, risk acknowledgment, and procedures for settling disputes. This guarantees a trading experience characterized by transparency and security for all platform users.
                        </p>
                    </div>
                </div>
                <div className="sec-sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <h1>1. GENERAL</h1>
                            <h5>1.1 General</h5>
                            <p>Before using or accessing {title}.io's websites, including www.{title}.io, the API located on <Link to="/getting-api-key">{title}</Link>, and any services offered through these websites, associated mobile applications, and APIs such as https://{title}.io/zendesk (collectively referred to as the "Services"), carefully review this User Agreement ("Agreement"). Pay special attention to content highlighted with capitalised terms, italics, bold, underline, or colour. This Agreement comprises the main body, the privacy policy ("Privacy Policy"), and any published rules, statements, or instructions. {title}.io, its affiliates, and related body corporates are collectively referred to as "we," "our," "us," or "Site" in this Agreement.</p>
                            <p>By accessing, downloading, using, or clicking "I agree" to accept any {title}.io Services, you confirm that you have read, understood, and agreed to all the terms and conditions stated in this Agreement, including the Privacy Policy, and any other agreements you may need to accept. Additional specific terms and conditions may apply to certain features of the Services.</p>
                            <p>Please read these terms carefully, as they govern your use of the Services and access to the Sites. The terms contain crucial provisions, including an arbitration and mediation clause that mandates resolving all claims through mediation and legally binding arbitration. By accessing the Sites and using the Services, you acknowledge and agree to various matters, including the significant risks associated with trading digital assets, their derivatives, and the assumption of all related risks. {title}.io's Sites provide online transaction and platform services for digital asset trading activities but do not participate as buyers or sellers in the transactions or offer services related to fiat currency replenishment and withdrawal.</p>
                            <h5>1.2 Risk Reminder</h5>
                            <ol>
                                <li><p>The digital asset market is novel and uncertain, and its expansion is not guaranteed.</p></li>
                                <li><p>Financial institutions, corporations, or the Sites do not offer digital assets themselves.</p></li>
                                <li><p>Digital assets are primarily used by speculators and have limited usage in retail and commercial markets.</p></li>
                                <li><p>Trading digital assets is highly risky due to their continuous trading without price limits, susceptible to major fluctuations caused by market makers and global government policies.</p></li>
                                <li><p>Cryptocurrencies like Bitcoin and Ether differ from traditional fiat currencies, goods, or commodities, subjecting them to exceptional risks. They are decentralised and not backed by central banks or governments, remaining largely unregulated, leading to fluctuating values influenced by various factors.</p></li>
                                <li><p>We cannot guarantee the availability of specific cryptocurrencies on our Sites and Services due to significant risks, technology impact, and international market conditions. We may discontinue support for certain cryptocurrencies and provide notice accordingly. You may transfer the cryptocurrency to another user or platform at your own cost.</p></li>
                                <li><p>Once transferred from our Sites, we are not responsible for any cryptocurrency sent to other sites, devices, platforms, wallets, or users, including those not traded on our Sites or sent to incorrect or incompatible wallet addresses.</p></li>
                                <li><p>As we don't identify transaction counterparties, it's recommended not to send cryptocurrency to unknown or untrusted individuals. Transfers are irreversible, so take precautions to secure your cryptocurrencies and digital assets using personal wallets or other secure devices.</p></li>
                                <li><p>Digital assets trading is highly risky and not suitable for most people. Investing may result in partial or total loss of your investment. Seek advice from a professional advisor, carefully assess financial position, and consider potential risks before transacting digital assets. Any losses incurred are borne solely by you, and we disclaim liability.</p></li>
                                <h5>Please be aware of the following:</h5>
                                <li><p>Our Sites are solely meant as a platform for obtaining digital asset information, finding trading partners, and conducting digital asset transactions. We do not participate in your transactions, so it is your responsibility to assess the authenticity, legality, and validity of digital assets and information, bearing any resulting responsibilities and losses at your own discretion.</p></li>
                                <li><p>Opinions, information, discussions, analyses, prices, advice, and other content on our Sites are general market reviews and not professional advice regarding investment, finance, taxation, or any other matter. We are not liable for any losses arising directly or indirectly from such information, including profit loss.</p></li>
                                <li><p>We reserve the right to determine, amend, or modify the contents of the Sites at our discretion. While we make reasonable efforts to ensure accuracy, we cannot guarantee the degree of accuracy of the information and disclaim liability for losses arising from the information or any delay or failure caused by internet connectivity issues.</p></li>
                                <li><p>Using internet-based trading systems entails risks such as software, hardware, or internet link failures. As we cannot control internet reliability and availability, we are not responsible for distortions, delays, or link failures.</p></li>
                                <li><p>Prohibited activities on the Sites and Services include engaging in illegal transactions, money laundering, terrorism funding, smuggling, or commercial bribery. If any illegal activities are suspected or noticed, we may take appropriate measures, including freezing relevant accounts and notifying authorities, without assuming any responsibility and reserving the right to hold relevant individuals accountable.</p></li>
                                <li><p>You must refrain from using our Sites or Services in any manner that engages in or facilitates activities detrimental to market integrity, our reputation, or the legitimate rights of other users. These activities, collectively referred to as "Market Manipulation Activities," include, but are not limited to, market manipulation practices like spoofing, layering, wash trading, or any illicit, improper, fake, unfair, or unethical transactions. Specifically, you and/or your affiliated individuals, subsidiaries, or entities must not open multiple accounts on our Sites to partake in Market Manipulation Activities. You understand that these actions can artificially influence the market, leading to inflated or deflated prices of digital assets. We are committed to promoting fairness and integrity and will take necessary measures to protect our Sites and Services from such abusive behaviour.</p></li>
                                <li><p>In the event we detect, notice, or reasonably suspect Market Manipulation Activities, we reserve the right, at our sole discretion, to take appropriate actions, such as issuing warnings, restricting trading, cancelling or reversing transactions, freezing assets associated with the activities, suspending or closing accounts related to you and your affiliates, and pursuing legal remedies against those involved. We do not accept liability for these activities and will continue to address them with necessary actions, protecting the integrity of our platform and users' interests.</p></li>

                            </ol>
                            <h5>1.3</h5>
                            <p>{title}.io offers an online service enabling users to exchange various blockchain assets, which are data representing transaction, access, or other rights on corresponding blockchain networks (referred to as "Assets"). Additionally, {title}.io may provide ancillary services to facilitate the exchange of these blockchain assets. However, certain functions on the site may not be available depending on your country of residence. It is your responsibility to comply with the rules and laws applicable in your country and the country from which you access the Sites and Services. By clicking the "Create Account" button or using the Services, you are agreeing to be legally bound by this Agreement and its terms and conditions. If you do not agree to these terms, please refrain from accessing the Sites or using any part of the Service.</p>
                            <h5>1.4</h5>
                            <p>When you create an account with {title}.io ("Account"), you agree to maintain the security and confidentiality of your login credentials and restrict access to your Account and devices. You are fully responsible for all activities occurring under your Account and assume all risks associated with unauthorised access.</p>
                            <h5>1.5</h5>
                            <p>We reserve the right to modify this Agreement, affiliated service terms, activity terms, or announcements from time to time, at our sole discretion and without prior notice. Any amended terms will be effective immediately upon posting. Your continued use of the site and services indicates acceptance of these changes. If you disagree with any amended terms, you must stop using or accessing our Service. Your use and access to our service signify that you have agreed to and fully understood this Agreement, as well as any contents in other affiliated service terms, including amended terms introduced by {title}.io from time to time.</p>

                            <h1>2. ELIGIBILITY</h1>
                            <h5>2.1</h5>
                            <p>By accessing the Sites or using our Services, you warrant that you are at least 18 years old and legally capable of entering into contracts, including this Agreement, and that you have not been previously suspended or removed from the Site or Services.</p>
                            <h5>If you are accessing our Sites and/or Services on behalf of an Entity, you represent to us that you have been duly authorised by the Entity to use our Site and Services, perform transactions, and bind the Entity to this Agreement. In this Agreement, "you" refers not only to you as an individual user but also to the Entity you represent. Both you and the Entity are jointly and severally bound by this Agreement, and you confirm that the Entity is capable of and agrees to be bound by these terms.</h5>
                            <h5>2.3 You represent and warrant that:</h5>
                            <ol>
                                <li><p>You are not listed on any trade or economic sanctions lists, such as the United Nations Security Council Sanctions List.</p></li>
                                <li><p>You are not restricted or prohibited from trading by administrative law enforcement agencies or authorities in any jurisdictions.</p></li>
                                <li><p>You will ensure that your access to the Sites and use of the Services comply with your local laws and regulations.</p></li>
                                <li><p>We may restrict or prohibit the use of all or part of the Services in certain markets and jurisdictions based on our sole and absolute discretion and local applicable laws. These restricted locations include but are not limited to the United States of America, Mainland China, Singapore, Canada, France, Germany, Hong Kong, Lithuania, Malaysia, Malta, Cuba, Iran, North Korea, Sudan, Syria, Crimea Region, Spain, Luhansk, Donetsk, Netherlands, Bolivia, and others. We may update the list of Restricted Locations without prior notice, and it is your responsibility to check the Sites and immediately stop using the Services if you are located in any of the Restricted Locations.</p></li>
                            </ol>
                            <h5>2.4</h5>
                            <p>f the Terms are excluded from the laws of your country or region, you must immediately stop accessing the Sites and using the Services. If you do not meet the eligibility requirements, please refrain from accessing our Sites and using our Services.</p>
                            <h5>2.5</h5>
                            <p>To use our Services, you are responsible for having proper devices and covering associated costs, including but not limited to computer, mobile phone, modem, internet connection, and phone call expenses.</p>
                            <h5>2.6</h5>
                            <p>By clicking "I agree" to the {title}.io User Agreement, you agree to all the terms and conditions in this Agreement and affirm that you have the legal right and full capacity to use all Services, including but not limited to checking information, placing orders, and conducting trading activities rationally. You acknowledge and assume all risks associated with such activities.</p>
                            <h5>2.7 When registering an account with us, you agree to:</h5>
                            <ol>
                                <li><p>Create a strong password that is not used for any other websites or online services and enable two-factor authentication for added protection.</p></li>
                                <li><p>Maintain the security of your account by safeguarding your login credentials and restricting access to yourself only.</p></li>
                                <li><p>Take responsibility for all activities that occur under your account and assume all risks of authorised or unauthorised access.</p></li>
                                <li><p>Be bound by all terms and conditions of this Agreement.</p></li>
                                <li><p>Confirm that you have reached the age of 18 or the statutory age required for entering into contracts as required by applicable law. Your registration with our Sites, purchases or sales via the Sites, and other actions indicating your acceptance of our Services must comply with relevant laws and regulations in your jurisdiction. You confirm that you have sufficient capacity to accept these terms, enter into transactions, and use the Services.</p></li>
                                <li><p>Assume all liabilities for your own transactions and non-transaction activities, as well as all profits and losses arising from them.</p></li>
                                <li><p>Ensure that the information provided at the time of account registration is true and accurate.</p></li>
                                <li><p>Comply with all relevant laws, including reporting any transaction profits for tax purposes.</p></li>
                                <li><p>Receive emails or messages related to the management and operation of our Sites.</p></li>
                            </ol>
                            <h1>3. OUR RIGHTS AND OBLIGATIONS</h1>
                            <h5>3.1</h5>
                            <p>We reserve the right to suspend or terminate your account or use of the Service, or halt the processing of any digital asset transaction, at any time if, in our sole discretion, we suspect, notice, or determine that you have violated this Agreement or any applicable laws in your jurisdiction or if your use of the Service in your jurisdiction is deemed unlawful.</p>
                            <h5>3.2</h5>
                            <p>If we, at our sole discretion, determine that you or any associated account user is not suitable for high-risk investment, we have the authority to immediately suspend or terminate the use of your account and all related accounts.</p>
                            <h5>3.3</h5>
                            <p>If you do not meet the relevant registration qualifications as set forth in this Agreement, we have the right to refuse your registration. In case you have already registered, we may revoke your account, and we reserve the right to hold you or your authorised agent accountable. Additionally, we retain the discretion to decide whether to accept your application for registration under all circumstances.</p>
                            <h5>3.4</h5>
                            <p>If we discover that the person using an Account is not the original registrant of that Account, we are entitled to immediately suspend or terminate the user's access to that Account.</p>
                            <h5>3.5</h5>
                            <p>If we reasonably suspect that the information you provide is inaccurate, untrue, invalid, or incomplete, we have the right to notify you to correct or update the information or suspend or terminate the provision of services to you.</p>
                            <h5>3.6</h5>
                            <p>When you upload, post, publish, or transmit any information on or via our Site, or communicate with other users of our Site, you are fully responsible for such posts and communications. You agree to use the Site in a legal and considerate manner, refraining from misuse or abuse, including but not limited to defamation, harassment, sedition, data collection of other users for personal purposes, and introducing viruses, trojans, or hacks. In case of misuse or abuse, we reserve the right to suspend or ban your account and/or remove your information from our Site without prior notice or liability to you. However, we shall not be liable for any posts or communications by third parties that may offend you, though we will genuinely address any complaints related to such posts or communications that you report to us in writing.</p>
                            <h5>3.7</h5>
                            <p>We may take necessary and reasonable actions at our sole discretion, including but not limited to suspending, restricting, or terminating your access to any or all of the Service and Sites, deactivating or cancelling your Account immediately, or cancelling, suspending, blocking, or refusing to complete a digital assets transaction if we believe it necessary to fulfil our legal obligations, protect our reputation, ensure market fairness and integrity, and enforce our rights. We may cooperate with relevant government and judicial authorities to freeze, forfeit, and transfer Digital Assets in your Account to a designated account as directed by these authorities, as deemed appropriate.</p>

                            <h1>4. MARGIN TRADING & LENDING AND PERPETUAL CONTRACTS</h1>
                            <p>This section (Section 4) specifically applies to your engagement in margin trading, lending, and perpetual contracts on {title}.io. Please note that these activities may be prohibited in certain jurisdictions as per applicable laws and regulations or this Agreement. We reserve the right to amend or remove the functionalities related to margin trading, lending, and perpetual contracts in your country with or without prior notice to you.</p>
                            <h5>4.1 Margin Trading and Lending</h5>
                            <p>Margin trading allows you to trade with borrowed blockchain assets in addition to the assets you already possess. On our Sites, you can use borrowed blockchain assets from other users for long or short positions.</p>
                            <ol>
                                <li>
                                    <span>Risks of Margin Trading</span>
                                    <p>Margin trading is highly risky, and you acknowledge and agree to access and use the margin trading and borrowing service at your own risk. Furthermore, you confirm the following:</p>
                                    <ul>
                                        <li><p>Trading markets are extremely volatile, and the use of leverage can lead to significant losses or gains.</p></li>
                                        <li><p>You are not entitled to receive forked coins resulting from any blockchain asset in your margin account, even if you have not engaged in margin trading or borrowing.</p></li>
                                        <li><p>The loan carries risks, and the value of your blockchain assets may decline. If the value drops below a certain level, you are responsible for managing this market condition.</p></li>
                                        <li><p>Under certain market conditions, liquidating a position may be difficult or impossible due to insufficient liquidity or technical issues.</p></li>
                                        <li><p>Placing contingent orders may not necessarily limit your losses, as market conditions can prevent the execution of such orders.</p></li>
                                        <li><p>There is no guaranteed protection against losses in margin trading, and as a borrower, you may sustain a total loss beyond your deposited margin.</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <span>Risks of Lending</span>
                                    <p>As a lender, you can lend your blockchain assets to other users by offering a loan at a fixed interest rate and duration on the lending page. When lending your assets:</p>
                                    <ul>
                                        <li><p>You may suffer a loss of unpaid principal and interests if the borrower defaults on a loan, and liquidation of the borrower's margin account fails to cover the outstanding loan.</p></li>
                                        <li><p>You may suffer a loss of forked coins split from the blockchain assets if your assets are in lending offers or loaned out during a fork event.</p></li>
                                        <li><p>You may experience a drop in the value of the blockchain assets you loaned out during significant market declines when timely selling becomes difficult.</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <span>Entering Margin Trading or Lending:</span>
                                    <ul>
                                        <li><p>Before engaging in margin trading or lending, you must fully understand the risks involved and be solely responsible for all your trading activities on your account with us. Do not undertake transactions or invest funds beyond your financial capacity or risk tolerance.</p></li>
                                        <li><p>You are solely responsible for knowing the true status of any position or lending arrangement with any other party on our Sites.</p></li>
                                        <li><p>You agree to maintain a sufficient amount of blockchain assets in your {title}.io margin account as required for margin trading and to return your loan promptly. Failure to do so may lead to forced liquidation of assets in your margin account.</p></li>
                                        <li><p>We cannot guarantee the prevention of losses, even with forced liquidation. If your assets are insufficient to cover the outstanding loan after liquidation, you will still be responsible for any additional shortfall.</p></li>
                                        <li><p>We may, at our absolute discretion, take reasonable measures to mitigate potential losses on your behalf, including transferring balances between your margin and exchange accounts.</p></li>
                                        <li><p>When lending your blockchain assets, we will not be responsible for any losses resulting from offering loans on our Sites or incurred by borrowers or any other parties on the Sites.</p></li>
                                        <li><p>You agree that all trading, margin trading, lending, and borrowing will be conducted on your own account, and you assume full responsibility for your activities. We are not liable for any loss or damage resulting from your use of our Services or your failure to understand associated risks related to accessing our Sites.</p></li>
                                    </ul>
                                </li>
                            </ol>
                            <h5>4.2 Perpetual Contract</h5>
                            <ol>
                                <li><p>A Perpetual Contract is a financial product similar to a traditional futures contract but with no expiration date. Perpetual Contracts function like spot trading, closely tracking the underlying Index Price. This is achieved through a funding component, where users holding a position over the funding time stamp either pay or receive funding based on a funding rate and the position's value.</p></li>
                            </ol>
                            <ol>
                                <li>
                                    <span>Perpetual Contract Risks</span>
                                    <p>Perpetual contract trading carries significant risk and may not be suitable for most investors. You acknowledge and understand the risks involved in trading perpetual contracts and have carefully considered whether it aligns with your financial circumstances and resources. You further agree and confirm the following:</p>
                                    <ul>
                                        <li><p>Perpetual contracts involve high leverage, which means even small market movements can lead to substantial losses, risking your entire margin on the contract account.</p></li>
                                        <li><p>We may, at our discretion, cancel or close your open orders and liquidate your position without prior notice to manage risks.</p></li>
                                        <li><p>In the event of Auto-Deleveraging, we may close your position at the bankruptcy price of other users, given certain priority ranks of profit and leverage, without prior notice to you.</p></li>
                                        <li><p>Holding a position over the funding timestamp may lead to substantial funding payments based on the funding rate and position value.</p></li>
                                        <li><p>We may require you to increase your margin levels for larger position sizes at our sole discretion.</p></li>
                                        <li><p>You will not receive forked coins for any assets in your Contract account during a fork event.</p></li>
                                        <li><p>Certain market conditions may make it challenging or impossible to liquidate a position, especially with insufficient liquidity or technical issues on our platform. Placing contingent orders may not limit losses to your intended amounts due to market conditions.</p></li>
                                    </ul>
                                </li>
                            </ol>
                            <ol>
                                <li>
                                    <span>To participate in Perpetual Contracts:</span>
                                    <ul>
                                        <li><p>You must be at least 18 years old and capable of entering into perpetual contracts trading on the Sites.</p></li>
                                        <li><p>You fully comprehend the rules and risks associated with perpetual contracts and accept the consequences of engaging in such trading.</p></li>
                                        <li><p>We reserve the right to close your positions, seize your earnings, and block your Account if we suspect illegal or suspicious trading or other activities, or if you breach this Agreement or any of our policies. You agree not to hold us liable for any losses resulting from perpetual contract trading, and you may be liable for any damages to us.</p></li>
                                        <li><p>You agree to maintain sufficient margin at all times to meet our minimum requirements, which may change periodically. Failure to do so may result in forced liquidation of your positions. In such cases, your position will be taken over by us and liquidated at the bankruptcy price.</p></li>
                                        <li><p>In case of a forced liquidation, if we cannot liquidate the position at the bankruptcy price, insurance funds will be used to attempt settlement. If this is still insufficient, an Auto-Deleveraging event may occur, automatically deleveraging positions of opposing traders based on profit and leverage priority. Any excess funds obtained from better liquidation prices will be added to the insurance fund.</p></li>
                                        <li><p>If you hold a position over the funding time stamp, you agree to pay or receive funding based on the funding rate and position value as determined by us.</p></li>
                                        <li><p>You assume full responsibility and liability for all trading and non-trading activities on the Sites and accept all associated risks.</p></li>
                                        <li><p>You are solely responsible for verifying the true status of any position with any counterparty on the Sites, even if we provide incorrect information at any time.</p></li>
                                    </ul>
                                </li>
                            </ol>
                            <h1>5. NON-FUNGIBLE TOKENS</h1>
                            <h5>5.1</h5>
                            <p>The Sites provide account holders with the ability to buy, list, and sell non-fungible tokens ("NFTs"). Our NFT services offer a convenient, user-friendly platform for NFT trading.</p>
                            <h5>5.2</h5>
                            <p>To access our NFT services, you must have a {title}.io account.</p>
                            <h5>5.3</h5>
                            <p>By using our NFT services, you acknowledge that you have read, understood, and agreed to all the terms and conditions that govern our NFT services.</p>
                            <h5>5.4</h5>
                            <p>Our NFT services are limited to Eligible NFTs available on our Sites, which are NFT collections verified as eligible for purchase by us. The list of Eligible NFTs can be found on our website ("Eligible NFT List").</p>
                            <h5>5.5</h5>
                            <p>We reserve the right to add or remove NFT collections from the Eligible NFT List at our absolute discretion. If a NFT collection is removed from the list, any open orders for that collection on the Sites will be immediately cancelled.</p>
                            <h5>5.6</h5>
                            <p>Only NFTs sent from an Approved Platform to the managed wallets or accounts by us will be accepted. Approved Platforms are external NFT platforms or chains recognized and approved by us, such as Opensea, Magic Eden, and Binance Smart Chain (BSC). It is your responsibility to stay updated with the list of Approved Platforms on our Sites. We will not be responsible for any NFTs sent from other unapproved sites, devices, platforms, or wallets.</p>
                            <h5>5.7</h5>
                            <p>Eligible NFTs on our Sites can only be purchased using "Eligible Cryptos," which are cryptocurrencies approved by us for specific NFT purchases. The list of "Eligible Cryptos" will be provided at the time of purchase.</p>
                            <h5>5.8</h5>
                            <p>Eligible NFTs listed for sale on our Sites can be sold for a currency type approved and accepted by us, such as USDT or another cryptocurrency determined at our discretion.</p>
                            <h5>5.9</h5>
                            <p>All transactions involving Eligible NFTs on our Sites will be subject to a service fee. We may adjust the service fee at any time and will notify you of the applicable fee during the transaction.</p>
                            <h5>5.10</h5>
                            <p>Third-party network fees will also apply to all transactions involving Eligible NFTs on our Sites. We do not set these fees, and you accept the third-party fees as they apply at the time of the transaction. The fees will be displayed during the transaction.</p>
                            <h5>5.11</h5>
                            <p>Transactions using some Eligible Cryptos may be subject to a conversion rate. By using our NFT services, you accept the applicable conversion rate at the time of the transaction, which will be included in the purchase price shown during confirmation.</p>
                            <h5>5.12</h5>
                            <p>You have the option to cancel the listing of an Eligible NFT before the sale is confirmed. However, we may, at our discretion, prevent the cancellation of listed Eligible NFTs in certain cases.</p>
                            <h5>5.13</h5>
                            <p>Eligible NFTs purchased on our Sites will be stored in secure custodial wallets managed by us.</p>
                            <h5>5.14</h5>
                            <p>NFTs are unique and non-fungible, meaning they cannot be replaced. As the security of your account and any held Eligible NFTs is essential, we are not responsible for any misappropriation or theft of Eligible NFTs on our Sites.</p>
                            <h5>5.15</h5>
                            <p>You agree that you use our NFT services at your own risk and "as is." We strongly recommend conducting your research and seeking advice if you are unsure about any aspect.</p>
                            <h5>5.16</h5>
                            <p> All clauses in this Agreement apply to our NFT services without exception.</p>


                            <h1>6. RISK DISCLOSURE STATEMENT</h1>
                            <h5>6.1</h5>
                            <p>Trading digital assets can be highly risky. The markets for digital asset trading are incredibly volatile and subject to rapid shifts. Blockchain networks may experience interruptions due to attacks, bugs, hard forks, or other unforeseen events. We may encounter sophisticated cyber-attacks, sudden spikes in activity, or operational and technical challenges that could lead to service disruptions. You are solely responsible and liable for all trading and non-trading activities on your account on the Sites and must take full responsibility for safeguarding access to your account and any information provided through the site.</p>
                            <h5>6.2</h5>
                            <p>You are responsible for conducting your own assessments and investigations and assuming the risks associated with trading blockchain assets. By accessing our Sites and using our Services, you acknowledge that you are solely responsible for evaluating the risks of each transaction and the underlying blockchain assets. You affirm that you possess sufficient knowledge and experience to make informed evaluations. The listing of a digital asset on the Sites does not signify our endorsement or disapproval of the underlying technology related to that digital asset, and it should not replace your own understanding of the risks associated with each digital asset. We provide NO warranty regarding the suitability of digital assets and assume no fiduciary duty in our relationship with you.</p>
                            <h5>6.3</h5>
                            <p>You accept all risks associated with transferring your digital assets to an address. Digital asset transactions are irreversible. Once you send digital assets to an address, you accept the risk of potentially losing access to your assets immediately and indefinitely. It is your sole responsibility to verify the address to which you are sending your digital assets. If you deposit a digital asset type that is not listed on your deposit page, it means that any transactions related to such type of digital asset are not supported by us, and as a result, we will be unable to return any of such assets claimed to be transferred to us. Ensuring that the address you are depositing to is listed on your deposit page is your responsibility. We bear no responsibility or liability for any digital assets sent to an incorrect address (including any external or internal wallet address and {title}.io UID).</p>
                            <h5>6.4</h5>
                            <p>We do not provide advice on trading risk. You must seek advice from a financial advisor or other professional advisor and assume full responsibility for the risks involved in trading digital assets on our Sites. Under no circumstances shall any information, charts, pictures, contents of any kind, or documents shown on the Sites or provided by us constitute investment or professional advice of any kind.</p>
                            <h5>6.5</h5>
                            <p>We reserve the right to delist a blockchain asset at any time at our sole discretion.</p>
                            <h5>6.6</h5>
                            <p>We may correct, reverse, or cancel any trade affected by an error in processing your purchase or sale, or correct, reverse, or cancel your deposit or withdrawal of blockchain assets affected by an error in processing.</p>
                            <h5>6.7</h5>
                            <p>We may seize your abandoned property. If you have not logged into your account on the site for an uninterrupted period of two years, we reserve the right to consider any and all property you hold on the Sites, including blockchain assets, as abandoned, without prior notice to you. In case of abandonment, your property will be immediately forfeited to and seized by us, and we may donate them to a nonprofit group. You assume all risks associated with or arising from missed communication from us. You acknowledge that we may post information on the Sites or communicate with you via email or support ticket from time to time. You understand and agree that if we post or send you information and you fail to receive it, we will be considered to have provided the information to you regardless.</p>


                            <h1>7. PRIVACY POLICY</h1>
                            <h5>7.1</h5>
                            <p>We collect information about you in compliance with applicable laws and regulations, which includes:</p>
                            <ol>
                                <li><p>Information collected when you create an online account, complete a transaction, fill out a form, respond to surveys, post messages in our chatroom or on social media platforms such as Twitter, or engage in other communications with us.</p></li>
                                <li><p>Information automatically collected when you use the site and service, including but not limited to device identifiers, mobile network information, the type of browser used, IP address, and other standard web log data and information obtained through Cookies and similar tracking technologies.</p></li>
                                <li><p>Information obtained from third parties, including identity verification services.</p></li>
                                <li><p>Information obtained from other legally permissible sources.</p></li>
                            </ol>
                            <h5>7.2</h5>
                            <p>We will implement reasonable security measures to safeguard your information and will not disclose your non-public information, such as name, password, and phone number, to any third party without your prior consent, except for the following circumstances:</p>
                            <ol>
                                <li><p>We may disclose your information to yourself or a third party with your explicit consent.</p></li>
                                <li><p>We may disclose your information with your consent to provide you with products or services.</p></li>
                                <li><p>We may disclose or share your information to comply with our legal obligations to authorities as stipulated by laws and regulations. This includes, but is not limited to, administrative authorities and financial authorities.</p></li>
                                <li><p>We may, in our sole discretion, disclose your information if we believe it is necessary to prevent physical harm or financial loss, report suspected illegal activity, or investigate violations of, or enforce, our user agreements or other policies and agreements.</p></li>
                            </ol>
                            <span>FOR FURTHER DETAILS REGARDING THE TYPE OF INFORMATION WE COLLECT AND HOW WE MAY USE OR DISCLOSE YOUR INFORMATION, PLEASE REFER TO OUR PRIVACY POLICY. WE STRONGLY RECOMMEND THAT YOU READ OUR PRIVACY POLICY AND FAMILIARISE YOURSELF WITH ITS CONTENTS.</span>

                            <h1>8. SERVICE MODIFICATION, SUSPENSION AND TERMINATION</h1>
                            <h5>8.1</h5>
                            <p>You are allowed to check transaction information and relevant materials posted on the Sites and participate in specific financial activities provided by us, both currently and in the future, in accordance with this Agreement and the service rules posted.</p>
                            <h5>8.2</h5>
                            <p>You should use the services on the Sites in a voluntary, fair, and honest manner, without infringing on the legitimate rights and interests of others or engaging in improper profit-making activities or disrupting the normal order of online trading.</p>
                            <h5>8.3</h5>
                            <p>When you accept the use of our Services, you also agree to receive information services provided by us and authorise us to send commercial information to your email address, mobile phone, and other communication addresses. However, you can choose to decline such information services by following our instructions.</p>
                            <h5>8.4</h5>
                            <p>While we strive to maintain the Site and provide you with sustainable, safe, stable, and smooth services, there is no guarantee that the Services will be free from delays, failures, errors, omissions, or loss of transmitted information. You understand and agree that we have the right to suspend services for system updates and upgrades, the addition of new functions, and other cases where service interruptions are necessary at our discretion.</p>
                            <h5>8.5</h5>
                            <p>We may, at our sole discretion and without any liability to you, modify or discontinue, temporarily or permanently, any portion of our Services with or without prior notice, at any time.</p>
                            <h5>8.6</h5>
                            <p>If you disagree with any of the amended terms of the Agreement or are unsatisfied with our services, you have the right to:</p>
                            <ol>
                                <li><p>Stop accessing the Sites or using the Services:</p></li>
                                <li><p>Notify us to terminate the Services for you by contacting our support. Upon termination, we have no obligation to deliver to you or any third party any pending information or services.</p></li>
                            </ol>
                            <h5>8.7</h5>
                            <p>We may suspend, restrict, or terminate your access to any or all of the Services and Sites, deactivate or cancel your {title} Account with immediate effect, or cancel, suspend, block, place on hold, or refuse to complete a digital assets transaction in the event that:</p>
                            <ol>
                                <li><p>We are, in our reasonable opinion, required to do so by applicable law, regulation, or any court or other authority to which we are subject in any jurisdiction.</p></li>
                                <li><p>We reasonably suspect you of breaching this Agreement or any other policies displayed on our Sites from time to time.</p></li>
                                <li><p>We have concerns regarding a transaction's accuracy or the security of your {title} Account, or if we suspect that the Services are being used fraudulently or in an unauthorised manner.</p></li>
                                <li><p>We suspect money laundering, terrorist financing, fraud, or any other financial crime.</p></li>
                                <li><p>Your {title} Account is subject to any pending litigation, investigation, or government proceeding, and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your {title} Account activity.</p></li>
                                <li><p>You take any action that may circumvent our controls, such as opening multiple accounts or abusing promotions that we may offer from time to time.</p></li>
                                <li><p>We reasonably believe that taking such actions is necessary to protect our reputation and/or comply with requests, orders, or judgments issued by a court or government authority or competent authority.</p></li>
                            </ol>
                            <h5>8.8</h5>
                            <p>We may suspend, restrict, or terminate your access to any or all of the Sites and/or Services, and/or deactivate or cancel your {title} Account without providing a reason, giving you 45 days' notice. You agree and acknowledge that our decision to take any of these actions may be based on confidential criteria that are essential for the purpose of our risk management and security protocols. You understand and agree that we are not obligated to disclose the details of our risk management and security procedures to you.</p>


                            <h1>9. USER RIGHTS</h1>
                            <h5>9.1 Your Account Login Credentials and Security</h5>
                            <ol>
                                <li><p>You have the option to become a member of {title}.io by creating an account and setting a preferred nickname. However, the nickname must not contain any words with insulting, threatening, or pornographic connotations, or infringe on the legitimate rights of others.</p></li>
                                <li><p>Once you successfully create an account and obtain a combination of username (or email address) and password, you become a member of {title}.io. You are solely responsible and liable for all activities and events that occur using this combination of username (or email) and password, and you accept all associated risks.</p></li>
                                <li><p>It is your responsibility to protect all your registered accounts with us, usernames, passwords, SMS verification codes (sent to your phone number via messages), and TOTP codes (dynamic codes generated by the authentication app associated with your {title}.io account, which can be set up in Two Factor Authentication on the {title}.io page), as well as your API Key and Secret. You are solely responsible for the security of these credentials and agree to bear legal consequences in case of any information leakage, not caused by us, that leads to asset loss.</p></li>
                                <li><p>If you discover or suspect any security breaches related to your {title}.io account or unauthorised use of your account, you must promptly notify us.</p></li>
                                <li><p>We will never ask for your password or instruct you to deposit assets to an address not provided on the Sites. You should never trust any discounts or promotions that ask you to send assets to an address not provided by the Sites. It is your responsibility to ensure that you send assets to the correct address provided by us for that specific asset. We shall not be held liable for any loss of assets resulting from sending assets to an address not provided by us.</p></li>
                            </ol>
                            <h5>9.2</h5>
                            <p>If you make contributions to the Sites, you may receive rewards or privileges from us (though there is no guarantee), subject to our rewarding policies.</p>
                            <h5>9.3</h5>
                            <p>You have the right to modify some of your account information.</p>
                            <h5>9.4</h5>
                            <p>You are entitled to join the {title}.io community and post comments or articles, provided that you adhere to the rules of {title}.io and comply with applicable laws and regulations.</p>
                            <h5>9.5</h5>
                            <p>You have the right to participate in online or offline activities organized or provided by {title}.io.</p>
                            <h5>9.6</h5>
                            <p>You have the right to enjoy related services provided by {title}.io according to the related rules and terms of {title}.io.</p>


                            <h1>10. USER OBLIGATIONS</h1>
                            <h5>10.1</h5>
                            <p>You are permitted to provide feedback on the products or services we offer and make appropriate modifications or deletions within reasonable limits. However, the use of the Sites for activities that pose a threat to national security, divulge national secrets, infringe on the legitimate rights of individuals, social communities, or nations, or engage in any other illegal activities is strictly prohibited. Moreover, you must not propagate information that violates policies, laws, regulations, or social norms through the Sites.</p>
                            <h5>10.2</h5>
                            <p>Registering an Account with malicious intent is strictly forbidden. This includes, but is not limited to, creating multiple Accounts for the purpose of hyping, profit-making, receiving unwarranted awards or financial gains. It is also prohibited to use another user's account or exploit any potential gaps or loopholes in our rules, policies, or event designs to gain advantages. If we suspect or detect such activities, we reserve the right to take necessary actions at our discretion. This may include deleting any content you have posted, cancelling your rewards, deducting any profits gained through fraudulent activities, freezing your assets, suspending or terminating your account, or taking legal actions against you.</p>
                            <h5>10.3</h5>
                            <p>The Sites must not be utilised as a platform for unauthorised or illegal activities. Using the name of {title}.io for any commercial activity or commercial purposes without our prior authorization is not allowed. If we identify any such activities, we reserve the right to take appropriate actions at our discretion. This may include deleting any content you have posted, cancelling your rewards or privileges on the Sites, suspending or terminating your account, and potentially initiating legal proceedings against you.</p>
                            <h5>10.4</h5>
                            <p>Any information you post on the Sites in any form must comply with social norms and should not violate laws, regulations, or our policies, terms, and rules. Additionally, it must not harm or negatively impact the legitimate rights and interests of others. You are solely responsible and liable for your actions and the information you post, and we retain the right to seek damages from you in case of any violations.</p>


                            <h1>11: LIABILITY LIMITATION AND WAIVER</h1>
                            <h5>11.1</h5>
                            <p>We shall not be held responsible for any damages caused by delays or failures to perform our obligations when such delays or failures result from events beyond our control. These events include but are not limited to fires, strikes, floods, power outages or failures, acts of God or state enemies, lawful acts of public authorities, market movements, shifts, or volatility, computer, server, or internet malfunctions, security breaches or cyberattacks, criminal acts, delays or defaults caused by common carriers, acts or omissions of third parties, or any other delays, defaults, failures, or interruptions that cannot reasonably be foreseen ("Force Majeure"). In the event of Force Majeure, we will be excused from any and all performance obligations, and the Services shall be fully and conclusively terminated.</p>
                            <h5>11.2</h5>
                            <p>We do not guarantee that the information on the Sites and Services will fully satisfy your needs, and we shall not be liable for any errors, inaccuracies, interruptions, defamation, inaction, blasphemy, or pornography that you may encounter while using the Sites or our Services. Before using any of our Services, it is essential to seek your own professional advice based on your specific circumstances.</p>
                            <h5>11.3</h5>
                            <p>Due to the nature of the internet and cyber environment, we cannot guarantee that our Services will be uninterrupted or that they will be provided in a timely and secure manner. We do not take liability for damages not directly caused by us.</p>
                            <h5>11.4</h5>
                            <p>While we strive to provide safe access to the Sites and Services, we do not guarantee that they are free from malicious elements, viruses, or other potential risks. It is your responsibility to scan and process any files or elements downloaded from the Sites using industry-recognized software. You should exercise your own judgement to identify and avoid potential risks.</p>
                            <h5>11.5</h5>
                            <p>We will not be responsible for any failure to save, modify, delete, or store information posted by you. Additionally, we will not be liable for typographical errors or negligence that were not intentional on our part. It is your responsibility to exercise judgment and caution to avoid risks.</p>
                            <h5>11.6</h5>
                            <p>We have the right, though not the obligation, to correct or update unintentional omissions or errors.</p>
                            <h5>11.7</h5>
                            <p>Unless expressly agreed in written form, we do not guarantee the accuracy, completeness, or reliability of information (including but not limited to advertisements) obtained from, via, or through links on the site or through any other methods related to the site. We will not be responsible for any purchases or obtainment of products, services, information, or materials based on such information. You assume all risks associated with using such information.</p>
                            <h5>11.8</h5>
                            <p>Comments or information posted by users on the Sites solely represent the opinions of the individuals who posted them and should not be considered as our agreement with or confirmation of the information or description. We will not be liable for the comments or information posted by users.</p>
                            <h5>11.9</h5>
                            <p>We reserve the right to delete any information posted on the Sites that violates laws, regulations, or user agreements without notice.</p>
                            <h5>11.10</h5>
                            <p>We may deliver notices or messages to you through announcements on webpages, email addresses, support phone lines, mobile phone messages, or regular mail delivery. We will not be responsible for information delivered through other channels related to prize winnings, promotions, or other activities or events.</p>
                            <h5>11.11</h5>
                            <p>We have the right to adjust fees for deposits, withdrawals, trading, and other services according to market conditions and may terminate promotions at any time.</p>
                            <h5>11.12</h5>
                            <p>It is your responsibility to determine which laws may apply to you, including tax-related laws. You are solely responsible for reporting and paying any taxes arising from your use of our Services and Sites.</p>
                            <h5>11.13</h5>
                            <p>By using our Service, you agree to pay any applicable fees and authorise us to deduct fees from your Account. We may revise or update the Fee schedule from time to time at our sole discretion, and changes to the fee schedule are effective immediately.</p>


                            <h1>12. LIMITATION AND EXEMPTION OF LIABILITY</h1>
                            <h5>12.1</h5>
                            <p>You acknowledge and agree that we shall not be held liable under any circumstances for any of the following events:</p>
                            <ol>
                                <li><p>Loss of income.</p></li>
                                <li><p>Loss of transaction profits or contractual losses.</p></li>
                                <li><p>Business disruptions</p></li>
                                <li><p>Loss of expected currency value.</p></li>
                                <li><p>Information loss.</p></li>
                                <li><p>Missed business opportunities.</p></li>
                                <li><p>Damage to goodwill or reputation.</p></li>
                                <li><p>Data damage or loss.</p></li>
                                <li><p>Costs of purchasing alternative products or services.</p></li>
                                <li><p>Any indirect, special, or incidental loss or damage resulting from infringement (including negligence), breach of contract, or any other cause, regardless of whether such loss or damage could reasonably be foreseen by us, and regardless of whether we were notified in advance of the possibility of such loss or damage.</p></li>
                                <li><p> The items listed in (a) to (j) above are independent of each other.</p></li>
                            </ol>
                            <h5>12.2</h5>
                            <p>You understand and agree that we shall not be held liable for any damages caused by any of the following events:</p>
                            <ol>
                                <li><p>When we have reasonable justification to believe that your specific transactions may involve serious violations or breaches of the law or agreements.</p></li>
                                <li><p>When we have reasonable justification to believe that your conduct on the Sites is suspected of being illegal or immoral.</p></li>
                                <li><p>Expenses and losses arising from the purchase or acquisition of any data, information, or transactions through the services offered by the Sites</p></li>
                                <li><p>Your misunderstanding of the Services offered by us.</p></li>
                                <li><p>Any other losses related to the Services that cannot be attributed to us.</p></li>
                            </ol>
                            <h5>12.3</h5>
                            <p>In the event of our failure to provide the Services or delays in providing such Services due to factors beyond our control, including but not limited to information network equipment maintenance, connectivity failures, system errors, power failures, weather conditions, unexpected accidents, labour disputes, uprisings, riots, lack of productivity or production materials, fires, floods, explosions, wars, actions by government, judicial or administrative authorities, or causes on the part of third parties, we shall not be held responsible for such failure to provide Service or delay, or for any resulting loss you may sustain.</p>
                            <h5>12.4</h5>
                            <p>We cannot guarantee that all the information, programs, texts, etc. contained on our Sites are completely safe and free from interference and destruction by malicious programs such as viruses, trojans, etc. Therefore, your use of the Sites, any services offered, and the download of any program, information, and data from the Sites, as well as your use thereof, are at your own risk, and you shall bear any and all risks and losses that may possibly arise.</p>
                            <h5>12.5</h5>
                            <p>We do not make any warranties or commitments regarding the information, products, and business of any third-party websites linked to the Sites, as well as any other forms of content that do not belong to us. Your use of any Services, information, and products provided by a third-party website is your personal decision, and therefore you shall assume any and all responsibilities arising from it.</p>
                            <h5>12.6</h5>
                            <p>We do not provide any explicit or implicit warranties regarding your use of the Services or the Sites, including but not limited to their applicability, freedom from errors or omissions, consistency, accuracy, reliability, and suitability for a specific purpose. Additionally, we do not make any commitments or guarantees regarding the validity, accuracy, correctness, reliability, quality, stability, integrity, and timeliness of the technology and information covered by the services offered on the Sites. The decision to log in to the Sites or use the Services is entirely your own, and you shall bear all risks and possible losses arising from such decisions. We also do not provide any explicit or implicit warranties concerning the market, value, and price of digital assets. You understand and acknowledge that the digital asset market is volatile, and asset prices may fluctuate or collapse at any time. The transaction of digital assets is based on your personal free will and decision, and you shall assume any and all risks and losses that may arise from it.</p>
                            <h5>12.7</h5>
                            <p>During periods of market volatility, high volume trades, fast movement, and illiquidity, the market rate at which you buy, sell, or trade digital assets may differ from the price indicated on our Sites. In such cases, we may be unable to execute your order and will return the funds to your account. We shall not be liable for any price variation due to market volatility, disruptions, delays, or any other matters outside our control. We may reasonably decide not to execute a trade if the market rate significantly increases or decreases, or in the event of a market disruption or other factors beyond our control. In such situations, we may cancel your buy or sell order and return the funds to your account. We will make reasonable efforts to notify you of these events, and you must check your account and any pending or cancelled transactions upon receiving a notification.</p>
                            <h5>12.8</h5>
                            <p>The guarantees and undertakings specified in this Agreement are the only guarantees and statements we make regarding the Services provided by us under this Agreement and through the Sites. They supersede any other warranties and commitments, whether written or oral, express or implied. These guarantees and statements represent our commitments and undertakings and do not guarantee compliance by any third party with the guarantees and commitments contained in this Agreement.</p>
                            <h5>12.9</h5>
                            <p>We do not waive any rights not explicitly mentioned in this Agreement, and to the maximum extent permitted by applicable law, we limit, exempt, or offset our liability for damages.</p>
                            <h5>12.10</h5>
                            <p>By registering your account with the Sites, you implicitly approve of any and all operations performed by us in accordance with the rules outlined in this Agreement. You shall assume all risks arising from such operations.</p>

                            <h1>13. APPLICABLE LAWS</h1>
                            <h5>13.1</h5>
                            <p>The making, execution, and interpretation of this agreement, as well as the settlement of disputes, shall be governed by the laws of the Republic of Panama.</p>
                            <h5>13.2</h5>
                            <p>The terms and conditions of this Agreement are severable. If any term or condition is deemed invalid or unenforceable, it may be interpreted in accordance with applicable laws, without affecting the validity and enforceability of the remaining terms and conditions.</p>
                            <h5>13.3</h5>
                            <p>This Agreement constitutes the entire agreement and understanding between the parties regarding the subject matter contained herein, superseding all prior agreements, promises, covenants, arrangements, communications, representations, or warranties, whether oral or written, relating to the subject matter hereof. We reserve the right of final interpretation of this Agreement.</p>


                            <h1>14. ANTI-MONEY LAUNDERING AND COUNTER-TERRORIST FINANCING</h1>
                            <p>We are dedicated to providing you with safe, compliant, and reputable Services. As part of our commitment, we implement a comprehensive and thorough customer due diligence process that includes ongoing analysis and reporting. This involves monitoring transactions that may appear suspicious and reporting such activities to international regulators as required by law. To adhere to applicable laws and fulfil our contractual obligations, we may need to retain certain information and documentation on file. We expressly reserve the right to store such information and documentation, even in cases where you decide to terminate your relationship with {title}.io or abandon your application for an account with {title}.io.</p>


                            <h1>15. INDEMNITY</h1>
                            <h5>15.1</h5>
                            <p>Under no circumstances will our liability for your direct damages surpass the total cost you incurred during three months of using our services.</p>
                            <h5>15.2</h5>
                            <p>If you violate this Agreement or any relevant laws or administrative regulations, you will be held responsible for and required to compensate us for all damages, losses (whether direct or consequential), costs, and expenses associated with the breach, including attorney's fees and other related charges.</p>


                            <h1>16. DISPUTE RESOLUTION</h1>
                            <h5>16.1</h5>
                            <p>Both Parties acknowledge that any dispute arising from or related to this Agreement, any associated document, or any transaction linked to this Agreement (including disputes or claims concerning (a) the existence, validity, or termination of this Agreement, and (b) any non-contractual obligations arising from or in connection with this Agreement) shall first be attempted to be resolved amicably through negotiations.</p>
                            <h5>16.2</h5>
                            <p>If the Dispute remains unresolved through negotiation for a period of thirty (30) days from the initiation of such negotiations, indicated by one Party issuing a notice of Dispute to the other Party, the matter shall be submitted to and ultimately settled through arbitration in Panama. The arbitration proceedings shall be conducted exclusively in accordance with the Arbitration Rules of the International Chamber of Commerce, which are currently in effect and are deemed to be incorporated by reference in this Clause. The seat of arbitration shall be in Panama, and the arbitration tribunal shall comprise a single arbitrator. The language used in the arbitration proceedings shall be English.</p>
                            <h5>16.3</h5>
                            <p>Subsequent to the commencement of arbitration, both Parties further agree to make a genuine effort to resolve the Dispute through mediation. Any resolution achieved during the mediation process shall be referred to the ongoing arbitral tribunal, and it may be incorporated into a consent award based on agreed terms.</p>



                            <h1>17. CALCULATION</h1>
                            <p>We verify all transaction calculations, and the methods used for these calculations have been made available on the Website. However, we cannot guarantee that your use of the Website will be uninterrupted or free from errors.</p>


                            <h1>18. INTELLECTUAL PROPERTY</h1>
                            <p>Unless explicitly stated otherwise by us, all intellectual property rights pertaining to the material used on the Sites, associated mobile applications, or provided in connection with the Services, including but not limited to design, structure, layouts, graphical images, and underlying source code, belong to us. All intellectual property rights are reserved.</p>
                            <p>We grant you a limited, non-exclusive, non-sublicensable, and non-transferable license to access and use the Services solely for approved purposes as determined by us from time to time. Any other use of the Services is strictly prohibited.</p>


                            <h1>19. SUPPLEMENTARY PROVISIONS</h1>
                            <p>The rules of service or use of the product, reminders on pages, are integral parts of this Agreement and hold equal legal effectiveness with the main body of the agreement. The rules of service encompass, but are not limited to, the privacy policy, anti-cheating statement, anti-money laundering policy, and other trading rules.</p>


                            <h1>20. KNOW-YOUR-CUSTOMERS AND ANTI-MONEY LAUNDERING POLICIES PREAMBLE</h1>
                            <p>We assure compliance with know-your-customer and anti-money laundering laws and regulations and pledge not to knowingly violate these policies. To the best of our ability, we will implement necessary measures and technology to provide you with safe and secure Services, minimising the risk of loss caused by money laundering.</p>
                            <p>Our know-your-customer and anti-money laundering policies form a comprehensive system that adheres to international standards, including those of the jurisdictions to which you are subject. Our robust compliance framework ensures we meet regulatory requirements at both local and global levels, ensuring the sustainable operation of our website.</p>
                            <h5>20.1 Content of Our Know-Your-Customer and Anti-Money Laundering Policies</h5>
                            <ol>
                                <li><p>We establish and update know-your-customer and anti-money laundering policies to align with the standards set by relevant laws and regulations.</p></li>
                                <li><p>We publish and update guidelines and rules related to the operation of the Sites, and our staff provides whole-process service in accordance with these guidelines and rules.</p></li>
                                <li><p>We design and implement internal monitoring and transaction control procedures, including rigorous identity authentication processes, managed by a professional team responsible for anti-money laundering compliance.</p></li>
                                <li><p>We adopt a risk-prevention-based approach for conducting due diligence and ongoing supervision of customers.</p></li>
                                <li><p>We regularly review and inspect existing transactions.</p></li>
                                <li><p>Any suspicious transactions will be reported to the appropriate authorities.</p></li>
                                <li><p>Proof documents of identity, address certificates, and transaction records will be maintained for at least six (6) years. If they are submitted to regulatory authorities, a separate notice may not be provided to you.</p></li>
                                <li><p>The use of credit cards is prohibited throughout the course of transactions.</p></li>


                                <h1>20.2 Identity Information</h1>
                                <ol>
                                    <li><p>Your information collected by us may vary based on the laws and regulations of relevant jurisdictions and the nature of your entity. For individual registration, we generally collect the following information</p>
                                        <ul>
                                            <li><p>Basic personal information: name, address (permanent and current, if different), date of birth, nationality, and other relevant details. Identity authentication requires official documents issued by relevant authorities, such as passports, identity cards, or similar identity documents as required by the applicable jurisdictions. The address provided will be validated through appropriate means, such as checking fare tickets or interest rate bills.</p></li>
                                            <li><p>Valid photo: Prior to registration, you must provide a photograph of yourself holding your identity document in front of your chest.</p></li>
                                            <li><p>Contact information: phone number and valid email address.</p></li>
                                        </ul>
                                    </li>
                                    <li><p>For companies or other legal entities, the following information will be collected to determine the ultimate beneficiary of your account or trust account.</p>
                                        <ul>
                                            <li><p>Enrollment and registration certificates of the company.</p></li>
                                            <li><p>Copy of the company's articles of association and memorandum.</p></li>
                                            <li><p>Detailed certification materials regarding the ownership structure and ownership description of the company.</p></li>
                                            <li><p>Board of directors' decision designating the authorised agent responsible for the company's account with us.</p></li>
                                            <li><p>Identity documents of directors, major shareholders, and authorised signatories for the company's account as required by relevant rules.</p></li>
                                            <li><p>Company's main business address and mailing address if different, with additional documentation required if the local address differs from the main business address (considered high-risk).</p></li>
                                            <li><p>Any other certification or documents deemed necessary based on the laws and regulations of relevant jurisdictions and the nature of the entity.</p></li>
                                        </ul>
                                    </li>
                                    <li><p>Only English and Chinese versions of identity information are accepted. Documents in other languages must be translated into English and notarized.</p></li>
                                </ol>
                                <h5>20.3</h5>
                                <ol>
                                    <li><p>You must provide both the front and back sides of your identity documents.</p></li>
                                    <li><p>You must provide a photograph of yourself holding your identity documents in front of your chest.</p></li>
                                    <li><p>Copies of certification documents will be checked against the originals unless a trusted and suitable certified person confirms the accuracy and completeness of the duplicates. Such certifiers include ambassadors, members of the judiciary, and magistrates.</p></li>
                                    <li><p>The identification of the ultimate beneficiary and controller of the account will be based on determining the individuals who ultimately own or control the direct customer, or if the ongoing transaction is performed by another person. For business enterprises, the identity of major shareholders (holding 10% or more of voting equity) will be verified, with higher risk thresholds for shareholders holding 25% or more of shares, requiring their identity verification.</p></li>
                                </ol>
                                <h5>20.4 Transaction Supervision</h5>
                                <ol>
                                    <li><p>We regularly establish and adjust daily trading and cash withdrawal limits based on security requirements and the actual state of transactions.</p></li>
                                    <li><p>If transactions occur frequently in an account registered by you or exceed reasonable circumstances, our professional team will assess and determine whether these transactions are suspicious.</p></li>
                                    <li><p>Upon identifying a specific transaction as suspicious through our assessment, we may take restrictive measures such as suspending or denying the transaction. In certain cases, we may even promptly reverse the transaction and report it to the competent authorities, without providing prior notification to you.</p></li>
                                    <li><p>We retain the right to reject registration applications from individuals who do not comply with international standards against money laundering or who may be considered political or public figures. Additionally, we reserve the right to suspend or terminate any transaction deemed suspicious based on our own assessment. However, such actions will not constitute a breach of our obligations and duties to you.</p></li>
                                </ol>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAgreement
