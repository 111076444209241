import { Link } from "react-router-dom";
import site_logo from "../Assets/Icon/logo.png";
export const logo = site_logo;
export const title = 'Yo Exchange';
export const website = 'yoex.live';
export const shortTitle = 'Yo Ex';
export const ShortName = 'Yo Ex';
export const titleDescription = 'Yo Exchange';
// export const api_url = 'http://192.168.29.196:8000/api/v1/';
export const kycImage_path = 'https://api.yoex.live/images/kyc/';
export const referral_link = 'https://trade.yoex.live/sign-up/';
export const receipt_img = 'https://api.yoex.live/receipt/';

export const site_url = 'https://trade.yoex.live';

export const p2pchat_img = 'https://api.yoex.live/p2pCImage/';

export const api_url = 'https://api.yoex.live/api/v1/';

export const markit_url = 'https://market.yoex.live/api/v1/';

export const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

