import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from "react-bootstrap";
import { title } from '../../config/config';
const PrivacyPage = (props: any) => {
    return (
        <div>
            <div className="min-tab-sec">
                <Row className="m-0 p-0">
                    <Col className="left col-12">
                        <div className='policy_sec_body'>
                            <h3 className='pt-3 pb-3'> Introduction </h3>
                            <h2>{title} ("We," "Us," and "{title}") is dedicated to safeguarding and respecting your privacy.</h2>
                            <p>Any capitalized terms not explicitly defined in this Privacy Policy shall hold the meanings ascribed to them in the Terms of Service.</p>
                            <p>This policy, in conjunction with the Terms of Service, outlines how We handle information collected from you or provided by you. It covers both Personal Information, which can identify you, and non-personal information. Within the context of the law and this policy, "process" entails collecting, storing, transferring, using, or otherwise acting on information. We encourage you to carefully read this policy to comprehend Our views and practices concerning your Personal Information and how We will manage it.</p>
                            <p>By revealing your personal information to Us, which includes but is not limited to, using the Platform, contacting Us via email, or speaking to Us over the telephone, you consent to the collection, storage, and processing of your personal information as detailed in this Privacy Policy. If you disagree with the following terms, regrettably, your only recourse is to refrain from using our website.</p>

                            <h3>What personal information do we collect?</h3>
                            <p>We may gather, retain, and utilize the following types of Personal Information, all of which will be stored for a minimum of five years:</p>
                            <p>If you register as a user of the Platform and wish to enable Stablecoin deposits/withdrawals, you will need to complete an application that includes your personal details, such as your name, address, email address, proof of identity, and proof of address.</p>
                            <p>When you contact us via email, telephone, or any provided contract form, we may request some or all of the information mentioned above. We retain personally identifiable details, like your name and email address, to track our communications with you and ensure a high-quality service.</p>
                            <p>We may collect, retain, and use information about your computer, mobile device, or hardware used to access the Platform, including your IP address, geographical location, browser/platform type and version, Internet Service Provider, operating system, referral source/exit pages, length of visit, page views, Platform navigation, and search terms.</p>
                            <p>We also collect data related to your deposits, withdrawals, and trades on our Platform, as well as any provided bank details and cryptocurrency wallet addresses.</p>
                            <p>To find out more about our use of cookies, kindly refer to our cookies notice.</p>
                            <p>Occasionally, we may request you to participate in surveys and provide feedback. Your participation is voluntary, and we use this information for research purposes, even if you choose not to respond.</p>

                            <h3>How do we use personal information?</h3>
                            <p>We may utilize your Personal Information for the following purposes:</p>
                            <ul>
                                <li><p>Managing and administering the Platform.</p></li>
                                <li><p>Enhancing your browsing experience by personalizing the Platform.</p></li>
                                <li><p>Enabling you to use the Platform.</p></li>
                                <li><p>Sending you newsletters and other relevant promotional communications.</p></li>
                                <li><p>Addressing your enquiries, complaints, and feedback, as well as those from our service providers.</p></li>
                                <li><p>Providing statistical anonymized information about our users to third parties.</p></li>
                                <li><p>Publishing generic transactional information related to trades conducted on the {title} platform.</p></li>
                                <li><p>Performing compliance checks.</p></li>
                                <li><p>Sending you emails inviting you to participate in surveys and/or provide feedback.</p></li>
                                <li><p>Analyzing and improving the services offered on the Platform.</p></li>
                            </ul>

                            <h3>Under what circumstances may we reveal personal information?</h3>
                            <p>We will not disclose your Personal Information to any third party except when required by applicable law, governmental, regulatory, or judicial bodies, or when necessary to engage service providers to perform specific tasks for us.</p>
                            <p>{title} may engage a qualified third party to conduct anti-money laundering checks and other necessary verifications on you. For this purpose, {title} may share documentation containing personal information about you, such as proof of identity. However, such disclosures will be limited to what is required by law or for essential service provision.</p>
                            <p>Newsletters and other marketing communications. If you would like to receive newsletters or targeted promotional communications from us or our carefully chosen affiliates, you have the option to subscribe to this service by 'opting-in'. If you decide to stop receiving such communications, you can click on the 'unsubscribe' link provided in any email from us.</p>


                            <h3>How we secure your personal information?</h3>
                            <p>The security of your personal data is of utmost importance to us, and we take it very seriously. We make every reasonable effort to implement appropriate security measures to protect your personal data. To ensure secure data transmission, we utilize Secure Sockets Layer (SSL) certificates to verify our identity to your browser and encrypt any data you provide.</p>
                            <p>However, it's important to note that transmitting information over the internet is not entirely risk-free, and we cannot guarantee the absolute security of data transmitted through the Platform. Any transmission is undertaken at your own risk. Once we receive your information, we employ strict procedures and security features to prevent unauthorized access.</p>

                            <h3>Retention Period for Personal Information:</h3>
                            <p>To facilitate the provision of our services, comply with relevant laws and regulations, and support any potential legal claims, we retain your personal information for a minimum of five years.</p>

                            <h3>Review of this Privacy Policy:</h3>
                            <p>This Privacy Policy will be periodically reviewed and may be updated without prior notice. Any information we hold will be governed by the most current version of our Privacy Policy.</p>
                            <p>If you have any questions, comments, or requests concerning this Privacy Policy, please feel free to contact us at {props.supportAccount}. We welcome your inquiries. <Link to={`http://` + props.supportAccount}> {props.supportAccount}</Link>. We welcome your inquiries.</p>
                        </div>
                    </Col></Row>
            </div>


        </div>
      
       
    )
}

export default PrivacyPage
