import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ShoppingBagTwoTone, SecurityTwoTone, FormatQuoteTwoTone } from '@mui/icons-material';
import Card from 'react-bootstrap/Card';
import { api_url, title } from '../../config/config';
import _fetch from '../../config/api';
import Select from 'react-select';




const BuyCrypto = () => {

  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);

  //  handle onChange event of the dropdown










  const handleChange = (e) => {
    setSelectedOption(e);
  }
  const handleChange2 = (e) => {
    setSelectedOption2(e);
  }
  const handleChange3 = (e) => {
    setSelectedOption3(e);
  }
  const handleChange4 = (e) => {
    setSelectedOption4(e);
  }

  async function activityLog() {
    setElpMarket([])
    let d = await _fetch(`${api_url}markets/getAll-ElpMarketby-Public`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" alt="image" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" alt="image" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }
  // console.log('elpMarket',elpMarket);

  // useEffect(() => {
  // }, [])




  const checkAuth = localStorage.getItem('auth');
  const Authbutton = () => {
    if (checkAuth !== "true") {
      return (
        <>
          <Link to="/login" className='main-button'>Log In</Link>

        </>
      )
    }
    else {
      return (
        <>
          <Link to="/dashboard" className='main-button'> Dashboard</Link>

        </>
      )
    }
  }



  useEffect(() => {
    Authbutton()
    activityLog()

  }, [checkAuth]);

  return (

    <div className="buycrypto_page">
      <div className="coustom_container buycrypto_banner pt-5">


        <div className="row row_mdd">
          <div className="col-12 col-md-7  ">
            {/* <div className='logo'>

            <img src={logo} alt='logo'></img>
            </div> */}
            <div className='left_text'>

              <div className='right__txt-area'>
                <h5 className='main_heading_buy_crypto'>What is the Process of buy cryptocurrency?</h5>
                <div className='info another-info'>
                  <span>1</span>
                  <h1>Select the [buy] section, pick the fiat currency for payment along with the desired cryptocurrency for acquisition. Afterward, input the preferred payment amount or the intended receiving amount.
                  </h1>
                  {/* <p>We Support 40+ Fiat Currencies And 100+ Cryptocurrencies</p> */}
                </div>
                <div className='info another-info'>
                  <span>2</span>
                  <h1>Proceed by clicking [buy] and choosing an appropriate payment approach.
                  </h1>
                  {/* <p>We Support 70+ Payment Methods Including Mastercard, Visa, SEPA, SWIFT, Fast Payment Poli And Many Other Local Payment Methods.</p> */}
                </div>
                <div className='info'>
                  <span>3</span>
                  <h1>Once the payment is verified, the buy cryptocurrency will be automatically added to your {title} account.
                  </h1>
                  {/* <p>The {title} Ecosystem Supports A Wide Range Of Cryptocurrency Products And Services, Including Spot Trading, Futures Trading, A Token Launchpad, And Much More!</p> */}
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 col-md-5 ">
            <div className='right_content'>
              <Tabs className="nested_tab">
                <TabList>
                  <Tab>Buy</Tab>
                  <Tab className="react-tabs__tab Sell__IDSD">Sell</Tab>
                </TabList>

                <TabPanel>
                  <div className="form">
                    <p>I want to pay</p>
                    <div className="first_input">
                      <div className="first">
                        <input type="number" placeholder="10.00-5000.00" />
                      </div>

                      <div className="second">

                        <Select
                          placeholder="Coins"
                          value={selectedOption}
                          className="select-option-s"
                          options={elpMarket}
                          onChange={handleChange}
                          getOptionLabel={(e) => (
                            <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                              {e.icon}
                              <span style={{ marginLeft: '5px' }}>{e.text}</span>
                            </div>
                          )}
                        />

                      </div>

                    </div>

                    <p>I will get ≈</p>
                    <div className="first_input">
                      <div className="first">
                        <input type="number" placeholder="Enter Amount" />
                      </div>

                      <div className="second">

                        {/* <Form.Select aria-label="Default select example">
                          {
                            elpMarket?.map((val) => {
                              return (
                                <option value={val.symbol}>{val.symbol?.toUpperCase()}</option>
                              )
                            })
                          }
                        </Form.Select> */}

                        <Select
                          autoFocus={true}
                          placeholder="Coins"
                          value={selectedOption2}
                          className="select-option-s"
                          options={elpMarket}
                          onChange={handleChange2}
                          getOptionLabel={(e) => (
                            <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                              {e.icon}
                              <span style={{ marginLeft: '5px' }}>{e.text}</span>
                            </div>
                          )}
                        />
                      </div>

                    </div>

                    <div className="tooltip_sec">
                      {/* <div className="tooltp">
                        <p>Reference price <span>19.24 TRY </span></p>

                        <Tooltip title="This price is for reference only. In the event of extreme market conditions, the actual price matched will deviate from the reference price. If you do not agree with the actual price, you can cancel the order or contact customer support." placement="top">
                          <ErrorRounded className='icon' />
                        </Tooltip>
                      </div> */}

                      <div>
                        <Link to={'/dashboard/p2p'} class="custom-btn btn-6"><span>Next</span></Link>
                      </div>

                      <h6>No withdrawals or selling is allowed for 48 hours after crypto purchases. During this period, transfer and trading will not be affected.</h6>


                    </div>

                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="">
                    <div className="form">
                      <p>I want to sell</p>
                      <div className="first_input">
                        <div className="first">
                          <input type="number" placeholder="Enter Amount" />
                        </div>

                        <div className="second">
                          {/* <Form.Select aria-label="Default select example">
                            {
                              elpMarket?.map((val) => {
                                return (
                                  <option value={val.symbol}>{val.symbol?.toUpperCase()}</option>
                                )
                              })
                            }
                          </Form.Select> */}

                          <Select
                            placeholder="Coins"
                            value={selectedOption2}
                            className="select-option-s"
                            options={elpMarket}
                            onChange={handleChange2}
                            getOptionLabel={(e) => (
                              <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                {e.icon}
                                <span style={{ marginLeft: '5px' }}>{e.text}</span>
                              </div>
                            )}
                          />
                        </div>

                      </div>

                      <p>I will get ≈</p>
                      <div className="first_input">
                        <div className="first">
                          <input type="number" placeholder="10.00-5000.00" />
                        </div>

                        <div className="second">
                          {/* <Form.Select aria-label="Default select example">
                            {
                              elpMarket?.map((val) => {
                                return (
                                  <option value={val.symbol}>{val.symbol?.toUpperCase()}</option>
                                )
                              })
                            }
                          </Form.Select> */}

                          <Select
                            placeholder="Coins"
                            value={selectedOption}
                            className="select-option-s"
                            options={elpMarket}
                            onChange={handleChange}
                            getOptionLabel={(e) => (
                              <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                {e.icon}
                                <span style={{ marginLeft: '5px' }}>{e.text}</span>
                              </div>
                            )}
                          />
                        </div>

                      </div>

                      <div className="tooltip_sec">
                        {/* <div className="tooltp">
                          <p>Reference price <span>19.24 TRY </span></p>

                          <Tooltip title="This price is for reference only. In the event of extreme market conditions, the actual price matched will deviate from the reference price. If you do not agree with the actual price, you can cancel the order or contact customer support." placement="top">
                            <ErrorRounded className='icon' />
                          </Tooltip>
                        </div> */}

                        <div>
                          {/* <Button className='main-button' variant="success btn_style">Next</Button> */}

                          <Link to={'/dashboard/p2p'} state={{ active: true }} class="custom-btn btn-6 sell_button"><span>Next</span></Link>
                        </div>

                        <h6>No withdrawals or selling is allowed for 48 hours after crypto purchases. During this period, transfer and trading will not be affected.</h6>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>



        </div>
      </div>



      {/* ===========================================
      ===========================================
      =========================================== */}

      <div className="coustom_container buycrypto_advantages">

        <h5>Benefits Of {title}</h5>

        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 m-non">
            <Card>

              {/* <Card.Img variant="top"  src={buy__crypto_1} /> */}
              <div className='img'>
                <ShoppingBagTwoTone />
              </div>

              <Card.Body>
                <Card.Title>Buy cryptocurrency</Card.Title>
                <Card.Text>Buy Cryptocurrency Instantly with a Single Click, No Professional Experience Required to Begin.</Card.Text>
              </Card.Body>

            </Card>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <Card>
              <div className='img'>
                <SecurityTwoTone />
              </div>

              <Card.Body>
                <Card.Title>Securing Assets</Card.Title>
                <Card.Text>Upon Order Placement by the Buyer, the Platform Safeguards the Seller's Assets. Customer Support Will Mediate Disputes, if Any.</Card.Text>
              </Card.Body>

            </Card>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <Card>
              <div className='img'>
                <FormatQuoteTwoTone />
              </div>
              <Card.Body>
                <Card.Title>Optimal Quotation</Card.Title>
                <Card.Text>Upon User Order Placement, the System Matches it with the Most Favorable Price based on the Chosen Payment Method.</Card.Text>
              </Card.Body>

            </Card>
          </div>
        </div>
      </div>





    </div>
  )
}

export default BuyCrypto