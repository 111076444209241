import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { title } from '../../config/config';


const Webform = () => {

    const [selectValue, setselectValue] = useState()
    return (
        <>
            <div className='webform__new'>
                <div className='coustom_container'>
                    <div className='webform_sec'>
                        <div className='webform_sec_header'>
                            <h3>Submit Case</h3>
                            <p>Kindly navigate the options below ( {"<"} 5 mins to complete) to submit a request ticket. Our dedicated 24/7 customer support will get back to you shortly.</p>
                        </div>

                        <div className='webform_sec_body'>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>

                            <div className='Notice_web'>
                                <h4>Notice</h4>
                                <p className='mb-0 large-volume'>We are currently experiencing a large volume of inquiries. To facilitate a faster response time, please do not resubmit if you have already submitted your inquiry through email or webform previously. Instead, we would like to invite you to find out more about the commonly asked questions at the Bybit Help Center here</p>
                                <p className='text-center'>Thank you for your support !</p>
                            </div>

                            <form>
                                <div className='form-group'>
                                    <Form.Label htmlFor="inputPassword5">Please select an option</Form.Label>
                                    <Form.Select aria-label="Default select example" id='inputPassword5' onChange={(e: any) => { setselectValue(e.target.value) }}>
                                        <option>Open this select menu</option>
                                        <option value="1">WSOT 2023</option>
                                        <option value="2">{title} Account login , KYC And Other requests </option>
                                        <option value="3">Deposits, withdrawals and assets exchange </option>
                                        <option value="3">P2P Trading </option>
                                        <option value="3">{title} card </option>
                                        <option value="3">{title} Campaingns, Rewards and launchpad </option>
                                        <option value="3">others </option>
                                    </Form.Select>
                                </div>

                                {selectValue !== undefined && selectValue !== "" && <div className='form-group form-group-text-area'>
                                    <Form.Label htmlFor="text_ras">Fill form</Form.Label>
                                    <Form.Control as="textarea" placeholder="Leave a comment here" id="text_ras" />
                                </div>}

                                {selectValue !== undefined && selectValue !== "" &&
                                    <div className='form-group d-flex justify-content-center align-items-center'>
                                        <button type='button' className='submit_btn'>
                                            <span className='left__top__border'></span>
                                            <span className='right__top__border'></span>
                                            <span className='left__bottom__border'></span>
                                            <span className='right__bottom__border'></span>
                                            Submit
                                        </button>
                                    </div>
                                }



                            </form>

                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Webform