import { ShieldTwoTone, LockPersonTwoTone, SupportAgentTwoTone, AccountCircleTwoTone,SatelliteAltTwoTone,FlagTwoTone,SupportTwoTone } from '@mui/icons-material'
import React, { useState, useEffect } from 'react';
import { title } from '../../config/config';
import { Col, Row } from 'react-bootstrap';
import trustbanner from '../../Assets/Img__v2/trust_banner.png';

const Safty2 = () => {

    const text = "Security Through Design";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);


    return (
        <>
            <div className="SafetyByDesign-sec only-margin">
            <span className='bg_banner-trust'></span>
                <div className="coustom_container for__bg">
                    <Row>
                        <Col sm={12} md={6}>
                        <div className='safety_img-banner'>
                            <img src={trustbanner} alt="safety"/>
                        </div>
                        </Col>
                        <Col sm={12} md={6}>
                        <div className="top-sec">
                        <h4>SECURITY</h4>
                        <h1 className="sentence">{content}</h1>
                        <p>At {title}, Trust is the Core of Our Product Offering. Our Committed Team for Trust and Safety Works Diligently to Ensure a Secure and Enjoyable Experience for Every Customer.
                            Our platform prioritises your security through meticulous planning and advanced technology. With robust encryption, regular audits, and proactive measures, we ensure a protected trading space. Your assets and personal information are shielded, allowing you to trade with tranquillity. Join us in embracing a secure trading experience, built with safety as the foundation.
                        </p>
                    </div>
                        </Col>
                    </Row>
                   
                </div>
                
                <div className="sec-sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <h2>Contributing to the Protection of {title} Users: Our Responsibility and Input</h2>
                            <p>The {title} Platform's Trust and Safety Team has Enacted State-of-the-Art Industry Methods to Safeguard Our Users Against Fraud and Abuse.</p>
                        </div>
                        <div className="inner_next">
                            <div className="for_borders d-flex">

                                <div className="info1 info">
                                    <div className="inner">

                                        <ShieldTwoTone />
                                        <h3>Scam mitigation</h3>
                                        <p>To identify and prevent scams, we employ tailor-made tools and utilise third-party services to detect and block wallet addresses linked to fraudulent activities.</p>
                                    </div>
                                </div>


                                <div className="info2 info">
                                    <div className="inner">
                                        <LockPersonTwoTone />
                                        <h3>Account security controls</h3>
                                        <p>We prioritise your account's security through various measures, such as implementing multi-factor authentication and verifying new devices before granting access.</p>
                                    </div>
                                </div>


                                <div className="info3 info">
                                    <div className="inner">
                                        <SupportAgentTwoTone />
                                        <h3>Customer verification</h3>
                                        <p>To mitigate fraud and abuse, we adopt the same techniques employed by prominent financial institutions to carefully review and approve new user accounts.</p>
                                    </div>
                                </div>


                                <div className="info4 info">
                                    <div className="inner">
                                        <AccountCircleTwoTone />
                                        <h3>Account Takeover (ATO) mitigations</h3>
                                        <p>We leverage machine learning and personalised rules to assess signals that aid in identifying and addressing suspicious activities</p>
                                    </div>
                                </div>

                                <div className="w-100 class-main class-show class1">
                                    <div className="inner__main">
                                        <div className="info_another">
                                            <h3>Mitigating Scams</h3>
                                            <p>In order to Detect and Prevent Scams, We Utilize Customized Tools and Leverage Third-Party Services to Identify and Block Wallet Addresses Associated with Fraudulent Activities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 class-main class2">
                                    <div className="inner__main">
                                        <div className="info_another">
                                            <h3>Enhancing Account Security</h3>
                                            <p>We Focus on Securing Your Account with Diverse Measures, Including the Implementation of Multi-Factor Authentication and the Verification of New Devices Before Allowing Access.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 class-main class3">
                                    <div className="inner__main">
                                        <div className="info_another">
                                            <h3>Verification of Customers</h3>
                                            <p>In Order to Counteract Fraud and Abuse, We Apply Methods Similar to Those Used by Prominent Financial Institutions to Thoroughly Examine and Authorize New User Accounts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 class-main class4">
                                    <div className="inner__main">
                                        <div className="info_another">
                                            <h3>Preventing Account Takeovers (ATO)</h3>
                                            <p>Through the Application of Machine Learning and Customised Rules, We Analyse Indicators to Detect and Counteract Suspicious Activities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="next-sec">
                            <div className="inner">
                                <h2>Assisting in Safeguarding {title} Users: Our Role and Contribution</h2>
                                <p>The Trust and Safety Team on the {title} platform has implemented cutting-edge industry practices to ensure the protection of our users from fraud and abuse.</p>
                            </div>
                            <div className="inner_info">
                                <div className="first">
                                    <div className="card_sec CARD_1">
                                        <div className="info">

                                            <h2><span>1. </span>{title} Will NEVER Ask for Your Password or 2FA Code.</h2>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_2">
                                        <div className="info">

                                            <h2><span>2. </span> Our Support Team Will NEVER Request Remote Access to Your Computer or Phone.</h2>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_3">
                                        <div className="info">

                                            <h2><span>3. </span> We Will NEVER Ask You to Transfer Funds to Any Other Account, Including Different Wallet Addresses, Bank Accounts, or Third-Party Apps.</h2>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="first">
                                    <div className="card_sec CARD_4">
                                        <div className="info">

                                            <h2><span>4. </span> Remain vigilant against individuals or entities impersonating {title} through communication channels. Official communications will always be channelled through our verified platforms.</h2>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_5">
                                        <div className="info">

                                            <h2><span>5. </span> Our Support Team Will NEVER Request Remote Access to Your Computer or Phone.</h2>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_6">
                                        <div className="info">

                                            <h2><span>6. </span> We Will NEVER Ask You to Transfer Funds to Any Other Account, Including Different Wallet Addresses, Bank Accounts, or Third-Party Apps.</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="last-sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <h2>Getting in Touch with the Security Team</h2>
                            {/* <p>The Trust and Safety Team on the {title} platform has implemented cutting-edge industry practices to ensure the protection of our users from fraud and abuse.</p> */}
                        </div>
                        <div className="card_sec">

                                <div className="inner_sec">
                                    <div className="info">
                                        <SatelliteAltTwoTone />
                                        <h1>Genuine {title} Communications</h1>
                                        <p>Please note that {title} does not provide phone support. All support-related inquiries are handled solely through official {title} email channels. Legitimate {title} emails will consistently conclude with the {title}.com domain name. For example, email addresses such as support@support.com are considered legitimate, as they have the {title}.com domain ending.</p>
                                    </div>
                                </div>
                        
                                <div className="inner_sec">
                                    <div className="info">
                                        <FlagTwoTone />
                                        <h1>Kindly report any instances of fraud or account limitations without delay.</h1>
                                        <p>In our marketplace, we give utmost importance to regulations and security, enabling our users to concentrate on developing their crypto portfolios. If you encounter any unforeseen problems, our team is prepared to provide help. For reporting account limitations, scams, or deceptive actions, please reach out to the {title} Trust and Safety Team via our support center.</p>
                                    </div>
                                </div>
                        
                                <div className="inner_sec For_basis">
                                    <div className="info">
                                        <SupportTwoTone />
                                        <h1>We support Take Five in the UK</h1>
                                        <p>Promoting a national campaign that empowers individuals against financial fraud. Visit the Take Five website for insights on diverse scams, including impersonation and online fraud, enhancing your ability to identify and prevent potential threats targeting you or your loved ones.</p>
                                    </div>
                                </div>
                        </div>
                          
                    </div>
                </div>
            </div>
        </>
    )
}

export default Safty2
