import React from 'react'
import { logo, title } from '../../config/config';
import career from '../../Assets/Img__v2/About_us.png';
import speed from '../../Assets/Img__v2/speed.png';
import Topplatform from '../../Assets/Img__v2/Topplatform.png';
import future from '../../Assets/Img__v2/futuer__bg.png';
import teamwork from '../../Assets/Img__v2/teamwork.png';
import network from '../../Assets/Img__v2/network.png';
import Global from '../../Assets/Img__v2/Global.png';
import { Col, Row } from 'react-bootstrap';
import careesside from '../../Assets/Img__v2/28669437_747726.png'
import { Groups, Language, LocationOn, ThumbUpOffAlt } from '@mui/icons-material';
import CountUp from 'react-countup';
const Careers = () => {

  return (

    <>
      <div className="carrer_opportunity only-margin">
        <div className="carrer_opportunity_banner ">
          <div className="coustom_container">

            <Row className='carees_top_sec'>
              <Col md={7} className='careers_text'>
                <h1>Opportunity For Career</h1>
                <p className='digital-asset'>
                  Embark on the Digital Asset Express and join {title}.io to enhance accessibility to wealth. Collaborate with us in promoting the efficient flow of assets.
                </p>
              </Col>
              <Col md={5}>
                <div className='card_cent'>
                  <div className='card_wrok'>
                    <div className='banner_img'>
                    </div>
                    <img src={careesside} alt='careesside' />
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <div className='career-sec'>
          <div className="coustom_container">
            <div className='row who-are-row'>
              <div className='col-lg-6 col-md-6'>
                <div className="card-img-sec">
                  <img src={career} alt="career" />
                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
                <div className='about_text'>
                  <h1> About Us </h1>
                 <ul>
                  <li>At {title}, we are more than just a company – we're a community of innovative minds working together to revolutionize the cryptocurrency and blockchain industry.</li>
                  <li>Our journey began with a vision to create a platform that empowers individuals to harness the potential of blockchain technology and cryptocurrencies.</li>
                  <li>With a team of dedicated professionals from diverse backgrounds, we bring a wealth of expertise to every facet of our operations.</li>
                  <li>Our commitment to excellence drives us to constantly push boundaries, innovate, and develop cutting-edge solutions that cater to the evolving needs of the digital economy.</li>
                  <li>We firmly believe in the principles of transparency, integrity, and collaboration, which are the cornerstones of our company culture.</li>
                  {/* <li>At {title}, we are not just building products; we are shaping the future of finance, technology, and beyond</li>
                  <li>Our passion for cryptocurrencies and blockchain technology fuels our determination to create products and services that make a lasting impact on the world.</li>
                  <li>We take pride in fostering an environment where creativity thrives, and ideas are transformed into tangible realities.</li> */}
                 </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='choose-sec'>
          <span className='choose_img'></span>
          <div className="coustom_container">
            <h3>Why choose {title}.io ?</h3>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12} className='choose_imgsa'>
                    <div className="card speed_card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>
                      <div className="card-body">
                        <div className='card-img-sec'>
                          <img src={speed} alt='speed' />
                        </div>
                        <div className='right-sec-head'>
                          <h3>Speed track</h3>
                          <p>Renowned in the business world for its high-return and high-quality track record, it swiftly ascends in the cutting-edge financial technology field.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>
                      <div className="card-body">
                        <div className="card-img-sec">
                          <img src={Topplatform} alt="Topplatform" />
                        </div>
                        <div className='right-sec-head'>
                          <h3>Top platform</h3>
                          <p>At the forefront of the digital asset market, this platform ranks among the world's leading digital asset trading platforms</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>
                      <div className="card-body">
                        <div className="card-img-sec">
                          <img src={teamwork} alt="teamwork" />
                        </div>
                        <div className='right-sec-head'>
                          <h3>Elite team</h3>
                          <p>Our team is diverse and global, embracing a flat management structure, fostering an open atmosphere, and collaborating with innovative partners.</p>
                        </div></div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>
                      <div className="card-body">
                        <div className="card-img-sec">
                          <img src={network} alt='network' />
                        </div>
                        <div className='right-sec-head'>
                          <h3>Integrated ecosystem</h3>
                          <p>We have a comprehensive and in-depth layout of cutting-edge ecosystems, ensuring a balanced output of multiple industry-relevant values.</p>
                        </div></div>

                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>
                      <div className="card-body">
                        <div className="card-img-sec">
                          <img src={Global} alt='Global' />
                        </div>
                        <div className='right-sec-head'>
                          <h3>Global vision</h3>
                          <p>With a strong focus on global development, we are dedicated to constructing a world-class digital asset service platform.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={4}>
            <div className='why_choose'>
              <img src={whychoose} alt='whychoose'/>
            </div>
            </Col> */}
            </Row>

          </div>
        </div>
        <div className='about_us mt-3 mb-3'>
          <div className='coustom_container'>
            <Row>
              <Col xs={6} sm={6} md={3}>
                <div className='icon_about'>
                  <Language />
                </div>
                <div className='text_about'>
                  <CountUp end={500} />
                </div>
              </Col>

              <Col xs={6} sm={6} md={3}>
                <div className='icon_about'>
                  <LocationOn />
                </div>
                <div className='text_about'>
                  <CountUp end={500} />
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className='icon_about'>
                  <ThumbUpOffAlt />
                </div>
                <div className='text_about'>
                  <CountUp end={500} />
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className='icon_about'>
                  <Groups />
                </div>
                <div className='text_about'>
                  <CountUp end={500} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="coustom_container vacancies-sec">
          <h3>Vacancies That Are Waiting For You </h3>
          <div className='row why-choose-row'>
            <div className='col-lg-12'>
              <div className="card">
                <div className="card-body">

                  <p>Currently, there are no vacancies.
                  </p>

                </div>

              </div>
            </div>

          </div>
        </div>
        <div className='create-future-sec'>
          <div className="coustom_container">
           
            <Row>
              <Col sm={12} md={6}>
                  <div className='list-section'>
                  <h3> Create Your Future</h3>
                      <div className='list-items'>
                        <div className="card-img-sec">
                          <img src={logo} alt="logo"/>
                        </div>
                        <div className='list-desc'>Enjoy a relaxed and harmonious working environment</div>
                      </div>

                      <div className='list-items'>
                        <div className="card-img-sec">
                        <img src={logo} alt="logo"/>
                        </div>
                        <div className='list-desc'> Work at your own pace with free and flexible hours</div></div>

                      <div className='list-items'>
                        <div className="card-img-sec">
                        <img src={logo} alt="logo"/>
                        </div>
                        <div className='list-desc'>Thoughtful staff support</div></div>

                      <div className='list-items'>
                        <div className="card-img-sec">
                        <img src={logo} alt="logo"/>
                        </div>
                        <div className='list-desc'>Exceptionally wide international perspective</div>
                      </div>
                      <div className='list-items'>
                        <div className="card-img-sec">
                        <img src={logo} alt="logo"/>
                            </div>
                        <div className='list-desc'>Attractive salary package</div></div>

                      <div className='list-items'>
                        <div className="card-img-sec">
                        <img src={logo} alt="logo"/>
                            </div>
                        <div className='list-desc'>Fair and open advertising methods</div>
                      </div>

                      <div className='list-items'>
                        <div className="card-img-sec"> 
                         <img src={logo} alt="logo"/> 
                        </div>
                        <div className='list-desc'>Professional and skilled team assistance</div>
                      </div>

                      <div className='list-items'>
                        <div className="card-img-sec">  <img src={logo} alt="logo"/> </div>
                        <div className='list-desc'> Creative and varied incentive systems  </div>

                      </div>
                  </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="future_img">
                  <img src={future} alt='future'/>
                </div>
              </Col>

            </Row>
          </div>
          <div>
          </div>
        </div>

        {/* <div className="coustom_container career-bottom-sec">
          <div className='career_bgfooter'>

            <div className='row row_gap'>
              <div className="col">
                <h4>Together with {shortTitle}</h4>
                <h4>make wealth more accessible</h4>
              </div>

              <div className="col btn-align">
                <Button className='main-button' variant="success"> Apply Now</Button>
              </div>

            </div>


          </div>
          </div> */}
      </div>
    </>
  );
}

export default Careers