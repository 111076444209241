import React from 'react'
import { Link } from 'react-router-dom'
import { title } from '../../config/config'
import { Col, Row } from 'react-bootstrap'
import tradingview_ba from '../../Assets/Img__v2/tradingview_banner.png';
import tradingviewlast from '../../Assets/Img__v2/tradingviewlast.png';

const Tradingview = () => {
    return (
        <div>
            <div className="tradingview-sec only-margin">
                <div className='top_banner-trad'>
                <div className="coustom_container">
                    <Row className='tradingview_banner_top'>
                        <Col md={7}>
                            <div className="top-sec">
                                <h4>TRADING VIEW</h4>
                                <h1>Advanced Cryptocurrency Trading Strategies.</h1>
                                <p>{title} has joined forces with TradingView to empower users with the ability to seamlessly execute trades on the {title} directly from the TradingView interface.</p>
                                <Link className='main-button' to="/Sign-up">
                                    <span className='left__top__border'></span>
                                    <span className='right__top__border'></span>
                                    <span className='left__bottom__border'></span>
                                    <span className='right__bottom__border'></span>
                                    Sign Up</Link>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='tradingview_banner'>
                                <img src={tradingview_ba} alt='tradingview' />
                            </div>
                        </Col>
                    </Row>
                </div>
                </div>

                <div className="sec-sec">
                    <div className="coustom_container">
                        <h1>Our integration</h1>
                        <div className="info">
                            <p>TradingView acts as a social network with a community of 30 million traders and investors, utilising high-quality charts and tools to identify trading opportunities in global markets.</p>
                            <p>By integrating with us, you gain exclusive access to unparalleled market insights sourced from the world's largest community of active traders and investors.</p>
                            <Row>
                                <Col sm={6} md={4}>
                                    <div className='card_chart'>
                                        <h3>Enhanced Trading & Charting Capabilities:</h3>
                                        <p> Our integration with TradingView grants users effortless access to a wide range of professional trading tools and interactive charting features. These resources empower them to conduct comprehensive technical analysis and discover the most favourable trading opportunities.</p>
                                    </div>
                                </Col>
                                <Col sm={6} md={4}>
                                    <div className='card_chart'>
                                        <h3>Real-Time Tracking of Crypto Prices:</h3>
                                        <p>Stay informed with real-time intraday market quotes for cryptocurrencies, enabling precise technical analysis and swift identification of trading opportunities.</p>
                                    </div>
                                </Col>
                                <Col sm={6} md={4}>
                                    <div className='card_chart'>
                                        <h3>Seamless Trading in Brave:</h3>
                                        <p>Connect to {title} as your preferred crypto broker on TradingView to seamlessly access our lowest fee tier structure with Active Trader. This convenient setup allows you to execute trades directly within the Brave browser.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className="last-sec">
                    <div className="coustom_container">
                        <h1>How to link your {title} account with TradingView</h1>
                        <p>Connecting your Gemini account to TradingView is a simple and efficient process that allows you to access a wealth of trading features and market insights. Follow these easy steps to get started.</p>
                        <div className='mt-2'>
                            <div className='roadmap'>
                                <div className='box box-left'>
                                    {/* <Fade left> */}
                                    <div className='rm-box'>
                                        <div className='corner-box'>
                                            <h2>Create an Account or Log In</h2>
                                            <p>If you are already a TradingView user, simply log in to your account. If not, sign up for a TradingView account to get started.</p>
                                        </div>
                                    </div>
                                    {/* </Fade> */}
                                    <div className='rm-box'>
                                        <div className='corner-box'>
                                            <h2>Choose {title}</h2>
                                            <p>From the list of supported brokers, click on "{title}" to initiate the integration process.</p>
                                        </div>
                                    </div>
                                    <div className='rm-box'>
                                        <div className='corner-box'>
                                            <h2>Authorise the Connection</h2>
                                            <p>After a successful login, you will be prompted to authorize the connection between your {title} account and TradingView. Review the permissions required and grant authorization.</p>
                                        </div>
                                    </div>
                                    <div className='rm-box'>
                                        <div className='corner-box'>
                                            <h2>Begin Trading and Analyzing</h2>
                                            <p>Congratulations! You have successfully linked your {title} account to TradingView. Now, you can access a broad spectrum of professional trading tools, advanced charting features, and real-time market data for cryptocurrencies and other assets offered by {title}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='box box-right'>
                                    <div className='rm-box'>
                                        <div className='right_box'>
                                            <h2>Find {title} Integration</h2>
                                            <p>After logging in, go to the TradingView platform. Locate the "Integrations" or "Connect Brokers" section on the platform.</p>
                                        </div>
                                    </div>
                                    <div className='rm-box'>
                                        <div className='right_box'>
                                            <h2>Link Your {title} Account</h2>
                                            <p>You will be redirected to the {title} login page. Enter your {title} account credentials (username and password) to proceed.</p>
                                        </div>
                                    </div>
                                    <div className='rm-box'>
                                        <div className='right_box'>
                                            <h2>Verify the Connection</h2>
                                            <p>Upon completing the authorization process, you will receive a confirmation message indicating that your {title} account is now successfully connected to TradingView.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sec-sec">
                    <div className="coustom_container">
                        <Row>
                            <Col md={8}>
                                <h1>About TradingView</h1>
                                <div className="info">
                                    <p>TradingView is a dynamic platform where users from around the globe engage in real-time market charting, discussions, and trading. It boasts an advanced charting system that empowers traders and investors to analyse markets comprehensively. Additionally, it fosters a lively social network where members can exchange ideas, live stream content, and actively participate in community chat rooms. </p>
                                    <p><span>1. Analyse Trading Concepts: </span> Explore a vast array of trading ideas, tips, and strategies to enhance your trading skills and stay updated with the latest market outlook shared by renowned traders.</p>
                                    <p><span>2. Real-Time Intraday Market Quotes: </span> Obtain instantaneous intraday market quotes on TradingView, with thousands of tickers available, covering stocks, currencies, futures, and various other assets.</p>
                                    <p><span>3. Unlock Professional Traders' Scripts: </span> Access valuable insights into the most popular trading strategies on TradingView. Elevate your success rate by selecting the most effective and time-tested trading methods used by seasoned professionals.</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='tradingviewlast'>
                                    <img src={tradingviewlast} alt='tradingviewlast' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tradingview
