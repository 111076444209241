import React, { useState, useEffect } from 'react';
import coin_droped from "../../Assets/Img__v2/fire_coin.png"
import { Col, Row } from 'react-bootstrap';
import securitybanner from "../../Assets/Img__v2/security_banner.png";
import { title } from '../../config/config';

const Security: React.FC = () => {

    const text = "Safety and Guardianship";
    const text1 = "Ensuring the Security of Your Cryptocurrency";

    const [content, setContent] = useState('');
    const [content1, setContent1] = useState('');
    const [currentLength, setCurrentLength] = useState(0);
    const [currentLength1, setCurrentLength1] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if ((currentLength < text.length) || (currentLength1 < text1.length)) {
                const newText = text.slice(0, currentLength + 1);
                const newText1 = text1.slice(0, currentLength1 + 1);
                setContent(newText);
                setContent1(newText1);
                setCurrentLength(currentLength + 1);
                setCurrentLength1(currentLength1 + 1);
            } else {
                clearInterval(interval);
            }
        }, 30); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);



    return (
        <div>
            <div className="Security-sec only-margin">
                <div className="coustom_container Add__bg">
                    <div className="top-sec">
                        <Row>
                            <Col sm={12} md={6}>
                            <div className='security_banner_img'>
                                <img src={securitybanner} alt="securitybanner" width="100%"/>
                            </div>
                            </Col>
                            <Col sm={12} md={6} className='security_banner-text'>
                                <h4>SECURITY</h4>

                                <h1 className="sentence">{content}</h1>
                                <p>Safeguarding client possessions remains at the core of our operations. We never trade security for ease, which is the reason we have a flawless record of zero cryptocurrency losses.</p>
                            </Col>
                        </Row>

                    </div>
                </div>
                <div className="sec-sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <div className="main_sec">
                                <div className="info">
                                    <h1 className="sentence">{content1}</h1>
                                    <p>At the heart of our asset custody lies a strong emphasis on security. We employ a blend of cold and hot wallets, along with robust cryptographic key management practices widely recognized in the industry. These are further reinforced by meticulous operational security protocols.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="third__sec">
                    <div className="coustom_container">
                        <div className="next-sec">
                            <div className="inner">
                                <h2>An Overview of Our Operations at a Strategic Level</h2>
                                <p>Here, we've provided responses to some of the frequently asked questions from our community regarding the security measures in place at {title} to safeguard both the platform and your assets. These integrated systems and operational control protocols work collaboratively to remove any potential vulnerabilities, ensuring protection against cyber-attacks, and human mistakes.</p>
                            </div>
                            <div className="inner_info">
                                <div className="first">
                                    <div className="card_sec CARD_1">
                                        <div className="info">
                                            <h2><span>1.</span> What happens in the event of a breach?</h2>
                                            <p>We perform frequent checks on our positions and margins, conducting these verifications multiple times per minute. Balances are cross-referenced with on-chain records every 10 minutes. If any bugs, vulnerabilities, leading to discrepancies in positions, our exchange will be promptly halted.</p>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_2">
                                        <div className="info">
                                            <h2><span>2.</span> What is your approach to Bitcoin custody?</h2>
                                            <p>Our Bitcoin custody solution utilises multi-signature wallets, employing a collection of private keys that govern the access to the {title} public address. A randomly selected group of signers is needed to authorise any transfers.</p>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_3">
                                        <div className="info">

                                            <h2><span>3.</span> How is custody managed for other assets?</h2>
                                            <p>All additional assets, along with a small portion of Bitcoin to facilitate faster withdrawals, are protected using secure multi-party computation (MPC). In essence, private keys are never concentrated in a single location. </p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="first">
                                    <div className="card_sec CARD_4">
                                        <div className="info">
                                            <h2><span>4.</span> How can you ensure the security of your transactions?</h2>
                                            <p>Transfers have added security via enforced transaction rules, thwarting attacks at a policy level. Segregated duties prevent individual withdrawal authorization; transactions need multiple signatures and untampered HSM interaction for validation.</p>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_5">
                                        <div className="info">
                                            <h2><span>5.</span> How is the separation of funds handled?</h2>
                                            <p>You invest your funds; we ensure client funds' safety. Strict segregation shields them from company assets, offering top-tier protection. No lending, staking, or trading; we're a liability-free exchange. Read risk disclosure for details.</p>
                                        </div>
                                    </div>
                                    <div className="card_sec CARD_6 border-0">
                                        <div className="info">
                                            <h2><span>6.</span> What security audits are conducted regularly?</h2>
                                            <p>We conduct comprehensive and routine security audits, engaging top-tier external experts to evaluate our systems and practices. These audits help us maintain robust security protocols and stay ahead of emerging threats.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forth_sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <h2>Getting in touch with our security experts.</h2>
                            <p>For confidential discussions requiring privacy, kindly use a secure ticket submission. Alternatively, within your account's security center, you can provide your PGP public key through the designated form.</p>
                            <button className='button' type='button'>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="bottom_sec">
                    <div className="coustom_container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-md-6">
                                    <h1>Join {title}</h1>
                                    <p>Ready to begin trading? Click below to either create an account or engage with our sales team.</p>
                                    <button className='button' type='button'>Register</button>
                                </div>
                                <div className="col-md-6">
                                    <div className="img">
                                        <img src={coin_droped} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security
