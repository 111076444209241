import React, { useState } from "react";
import { ShortName, logo, title } from "../../config/config";
import Marquee from "react-fast-marquee";
import { Card } from "react-bootstrap";
import btc_img from '../../Assets/Image/New_Home/btc.png'
import trx_img from '../../Assets/Image/trx_img.png'
import bnb__img from '../../Assets/Image/New_Home/bnb-bnb-logo.png'
import logo_solar from '../../Assets/Image/logo_solar.png';
import { Link } from "react-router-dom";





const Banner = () => {

  return (
    <>
      <div className="Banner__main_sec">
        <div className="coustom_container">
          <div className="Banner_sec_area">
            <div className="Banner__heading_area">
              <h1 className="banner__heading"> trade <img src={logo} alt="logo" className="Logo__banner" /> <span className="space__add"></span> crypto  {/* <br/> exchange */}</h1>

            </div>

            <div className="pragraph__sec_area">
              <h4> Welcome to {title} Where Financial Freedom Empowers You!</h4>
              <div className="btns_a">
                <Link to="/login" className="btn___start"> Get Start </Link>
              </div>
            </div>
          </div>



        </div>

        <div className="market__show">
          <Marquee className="mb-2" direction='left' pauseOnHover={true}>
            <Card>
              <Link className="stretched-link" to="/"></Link>
              <img src={logo_solar} alt="icon" />
              <div className='names_text_cars'>
                <h4>{title} ({ShortName}) <span>3-6% Flexible</span></h4>
                {/* <p>Lorem ipsum dolor sit amet </p> */}
              </div>
            </Card>
            <Card>
              <Link className="stretched-link" to="/"></Link>
              <img src={btc_img} alt="icon" />
              <div className='names_text_cars'>
                <h4>bitcoin (BTC) <span>2-5% Flexible</span></h4>
                {/* <p>Lorem ipsum dolor sit amet </p> */}
              </div>
            </Card>
            <Card>
              <Link className="stretched-link" to="/"></Link>
              <img src={bnb__img} alt="icon" />
              <div className='names_text_cars'>
                <h4>Binance Coin  (BNB) <span>2-5% Flexible</span></h4>
                {/* <p>Lorem ipsum dolor sit amet </p> */}
              </div>
            </Card>
            <Card>
              <Link className="stretched-link" to="/"></Link>
              <img src={trx_img} alt="icon" />
              <div className='names_text_cars'>
                <h4>Tron (TRX) <span>2-5% Flexible</span></h4>
                {/* <p>Lorem ipsum dolor sit amet </p> */}
              </div>

            </Card>
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default Banner;