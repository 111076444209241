import {  Col, Row } from 'react-bootstrap'
import Simplicity_img from '../../Assets/Image/New_Home/Simplicity.png'
import Services_img from '../../Assets/Image/New_Home/Services.png'
import Education_img from '../../Assets/Image/New_Home/Education.png'
import { ShortName, shortTitle, title } from '../../config/config'
import { Link } from 'react-router-dom'


const CryptoMIn = () => {
    return (
        <>


            <div className='crypto_min'>
                <div className='coustom_container'>
                    <h3 className='heading__center'> Why {title?.toLocaleLowerCase()} ({ShortName?.toUpperCase()})?</h3>
                    <Row className='why__sec_row'>
                        <Col md={4} sm={6} className='colom__1'>
                            <div className='card why__sec_card'>
                                <div className="why__card___IMG__SD">

                                    <img className='card_inner_img simplicity_img' src={Simplicity_img} alt='crypto_leptop' />
                                </div>
                                <div className='text__why__aes'>
                                    <h3>Simplicity</h3>
                                    <p>{title} - Simplifying Cryptocurrency Trading. Streamlined interface, easy deposits, and quick trades.</p>
                                </div>

                            </div>
                        </Col>
                        <Col md={4} sm={6} className='colom__1'>
                            <div className='card why__sec_card'>
                                <div className="why__card___IMG__SD">

                                    <img className='card_inner_img' src={Services_img} alt='Services_img' />
                                </div>
                                <div className='text__why__aes'>
                                    <h3>Service</h3>
                                    <p>Your Trusted Cryptocurrency Service. Secure wallets, fast transactions, and 24/7 customer support. Experience top-notch crypto services now. Join us!</p>
                                </div>

                            </div>
                        </Col>
                        <Col md={4} sm={12} className=''>
                            <div className='card why__sec_card'>
                                <div className="why__card___IMG__SD">

                                    <img className='card_inner_img' src={Education_img} alt='Education_img' />
                                </div>
                                <div className='text__why__aes'>
                                    <h3>Education</h3>
                                    <p>Feeling stuck on where to start? Unlock the world of crypto at our Learn Center. Begin your learning journey now!</p>
                                </div>

                            </div>
                        </Col>



                    </Row>
                    <div className='btn__why_sec'>
                        <Link to="/sign-up" className='btn'>
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>
                            Get started with {shortTitle}</Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CryptoMIn