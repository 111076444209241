import React, { useEffect, useState } from 'react'
import { shortTitle, website, } from '../../config/config';
import { Link } from 'react-router-dom';
import { ArrowBackIosNew } from '@mui/icons-material';


const Fees = () => {

    const auth = localStorage.getItem("auth");
    const SetLinkBg = () => {
        if (auth !== "true") {
            return (
                <>
                    <Link to="/" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
                </>
            )
        }
        else {
            return (
                <>
                    <Link to="/dashboard" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
                </>
            )
        }
    }

    const text = "Transparent Crypto Trading Fees";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);

    return (
        <>

            <div className="fees_page">
                <div className="innercum_margin pt-5">
                    <div className="coustom_container for__bg">
                        <div className="row">
                            <div className="col-md-10">

                                <div className="top-sec">
                                    <h4>FEES</h4>
                                    <h1 className="sentence">{content}</h1>
                                    <p>Discover transparent and competitive cryptocurrency trading fees on {website}. Our fee structure ensures fairness and cost-effectiveness, empowering you to trade with confidence. Explore our comprehensive fee breakdown and experience seamless trading with low fees and no hidden charges.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="coustom_container">
                        <div className="main_class_sec">
                            <div className="first_table_sec">
                                <h2>Opening an account with {shortTitle} is FREE!</h2>

                                <div className="overflow_table">
                                    <table className='fee_table'>

                                        <tr>
                                            <td><b>Account Creation Fee</b></td>
                                            <td>FREE</td>
                                        </tr>

                                        <tr>
                                            <td><b>Account Maintenance Fee</b></td>
                                            <td>FREE</td>
                                        </tr>

                                        <tr>
                                            <td><b>Bitcoin (BTC) Withdrawal Fee</b></td>
                                            <td>0.0004 BTC</td>
                                        </tr>

                                        <tr>
                                            <td><b>Ethereum (ETH)  Withdrawal Fee</b></td>
                                            <td>0.005 ETH</td>
                                        </tr>

                                        <tr>
                                            <td><b>Ethereum Classic (ETC) Withdrawal Fee</b></td>
                                            <td>0.005 ETC</td>
                                        </tr>

                                        <tr>
                                            <td><b>Litecoin (LTC) Withdrawal Fee</b></td>
                                            <td>0.001 LTC</td>
                                        </tr>

                                        <tr>
                                            <td><b>Bitcoin Cash (BCH) Withdrawal Fee</b></td>
                                            <td>0.0002 BCH</td>
                                        </tr>

                                        <tr>
                                            <td><b>Basic Attention Token (BAT) Withdrawal Fee</b></td>
                                            <td>5 BAT</td>
                                        </tr>

                                        <tr>
                                            <td><b>Stellar Lumens (XLM) Withdrawal Fee</b></td>
                                            <td>Free</td>
                                        </tr>

                                        <tr>
                                            <td><b>Tezos (XTZ) Withdrawal Fee</b></td>
                                            <td>0.1 XTZ</td>
                                        </tr>

                                        <tr>
                                            <td><b>Polkadot (DOT) Withdrawal Fee</b></td>
                                            <td>0.1 DOT</td>
                                        </tr>

                                        <tr>
                                            <td><b>Unit of Transaction (BTC)</b></td>
                                            <td>0.00000001 BTC (=1 satoshi)</td>
                                        </tr>

                                    </table>

                                </div>

                            </div>

                            <div className="first_table_sec">
                                <h2>BTC/USD Trading Fees</h2>

                                <div className="overflow_table">
                                    <table className='fee_table'>
                                        <tr>
                                            <th>Past 30 day volume<sup>1</sup></th>
                                            <th>Lightning Spot</th>
                                            <th>Buy/Sell</th>
                                        </tr>

                                        <tr>
                                            <td>$0 - Less than $50,000</td>
                                            <td>0.10%</td>
                                            <td rowSpan={8} className='combine_col'>FREE <sup>2</sup></td>
                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$500,000 - Less than $1 million</td>
                                            <td>0.08%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                    </table>

                                </div>

                                <div className="paragrapg_subtxt">
                                    <p>
                                        <sub>1</sub> Your Lightning trading fees are calculated daily between 0:00 and 0:10 AM (JST). Fees may be negotiated based on volume and trade frequency.

                                    </p>

                                    <p>
                                        <sub>2</sub> The purchase and sales prices displayed on the Buy/Sell Virtual Currencies displays a substantial fee between 0.1% and 6.0%. In the event of sudden price changes, the liquidity of the market, etc., purchase and sales prices that exceed the scope of the corresponding fees may be offered.
                                    </p>
                                </div>
                            </div>

                            <div className="first_table_sec">
                                <h2>ETH/USD Trading Fees</h2>

                                <div className="overflow_table">
                                    <table className='fee_table'>
                                        <tr>
                                            <th>Past 30 day volume<sup>1</sup></th>
                                            <th>Lightning Spot</th>
                                            <th>Buy/Sell</th>
                                        </tr>

                                        <tr>
                                            <td>$0 - Less than $50,000</td>
                                            <td>0.10%</td>
                                            <td rowSpan={8} className='combine_col'>FREE <sup>2</sup></td>
                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$500,000 - Less than $1 million</td>
                                            <td>0.08%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                    </table>

                                </div>

                                <div className="paragrapg_subtxt">
                                    <p>
                                        <sub>1</sub> Your Lightning trading fees are calculated daily between 0:00 and 0:10 AM (JST). Fees may be negotiated based on volume and trade frequency.

                                    </p>

                                    <p>
                                        <sub>2</sub> The purchase and sales prices displayed on the Buy/Sell Virtual Currencies displays a substantial fee between 0.1% and 6.0%. In the event of sudden price changes, the liquidity of the market, etc., purchase and sales prices that exceed the scope of the corresponding fees may be offered.
                                    </p>
                                </div>
                            </div>

                            <div className="first_table_sec">
                                <h2>BTC/JPY Trading Fees</h2>

                                <div className="overflow_table">
                                    <table className='fee_table'>
                                        <tr>
                                            <th>Past 30 day volume<sup>1</sup></th>
                                            <th>Lightning Spot</th>
                                        </tr>

                                        <tr>
                                            <td>$0 - Less than $50,000</td>
                                            <td>0.10%</td>
                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$500,000 - Less than $1 million</td>
                                            <td>0.08%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                    </table>

                                </div>

                                <div className="paragrapg_subtxt">
                                    <p>
                                        <sub>1</sub> Your Lightning trading fees are calculated daily between 0:00 and 0:10 AM (JST). Fees may be negotiated based on volume and trade frequency.
                                    </p>


                                </div>
                            </div>

                            <div className="first_table_sec">
                                <h2>ETH/BTC Trading Fees</h2>

                                <div className="overflow_table">
                                    <table className='fee_table'>
                                        <tr>
                                            <th>Past 30 day volume<sup>1</sup></th>
                                            <th>Lightning Spot</th>
                                        </tr>

                                        <tr>
                                            <td>$0 - Less than $50,000</td>
                                            <td>0.10%</td>
                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$500,000 - Less than $1 million</td>
                                            <td>0.08%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                        <tr>
                                            <td>$50,000 - Less than $500,000</td>
                                            <td>0.09%</td>

                                        </tr>

                                    </table>

                                </div>

                                <div className="paragrapg_subtxt">
                                    <p>
                                        <sub>1</sub> Your Lightning trading fees are calculated daily between 0:00 and 0:10 AM (JST). Fees may be negotiated based on volume and trade frequency.
                                    </p>


                                </div>
                            </div>

                            <div className="first_table_sec">
                                <h2>US Dollar Deposits and Withdrawals</h2>

                                <div className="overflow_table">
                                    <table className='fee_table width_50'>
                                        <tr>
                                            <td><b>Wire Deposits (Fedwire)</b></td>
                                            <td>Free</td>

                                        </tr>

                                        <tr>
                                            <td><b>Wire Withdrawals (Fedwire)</b></td>
                                            <td>$20</td>
                                        </tr>

                                    </table>

                                </div>

                            </div>

                            <div className="first_table_sec">
                                <h2>JPY Deposits and Withdrawals</h2>

                                <div className="overflow_table">
                                    <table className='fee_table width_50'>
                                        <tr>
                                            <td><b>Deposits</b></td>
                                            <td>Not available</td>
                                        </tr>

                                        <tr>
                                            <td><b>Withdrawals</b></td>
                                            <td>Not available</td>

                                        </tr>

                                    </table>

                                </div>

                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </>
    )
}

export default Fees
