import React, { useEffect, useState } from 'react'
import { title } from '../../config/config';

const LegalTerms = () => {

    const text = "Legal Terms and Conditions Overview";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (currentLength < text.length) {
          const newText = text.slice(0, currentLength + 1);
          setContent(newText);
          setCurrentLength(currentLength + 1);
        } else {
          clearInterval(interval);
        }
      }, 50); // Adjust the interval time for the typing speed
  
      return () => {
        clearInterval(interval);
      };
    }, [currentLength]);
    return (
        <div>
            <div className="LegalTerms only-margin">
                <div className="coustom_container for__bg">
                    <div className="top-sec">
                        <h4>LEGAL TERMS</h4>
                        <h1 className="sentence">{content}</h1>
                        <p>Explore {title}'s legal terms for a comprehensive understanding of platform usage. Learn about privacy, user responsibilities, and dispute resolution, ensuring a secure and transparent experience for all users.
                        </p>
                    </div>
                </div>
                <div className="sec-sec">
                    <div className="coustom_container">
                        <div className="inner___sec">
                            <div className="inner_info">
                                <p>This Risk Disclosure Statement (referred to as "Statement") pertains to the electronic trading platform (including any related mobile applications and websites facilitating access) collectively known as the "Platform," provided by {title} Limited (also referred to as "Company," "we," "us," or "our"). This Statement forms an integral part of the Service Agreement between the User and the Company. Any capitalised terms used herein but not defined hold the same meanings as ascribed in the Service Agreement.</p>
                                <p>By accessing or using the Platform and the Site, you acknowledge and affirm that you are fully aware of the risks associated with transactions involving Digital Assets or the use of the Platform. You agree and understand that you alone are responsible for assessing the nature, potential value, suitability, and appropriateness of these risks based on your own judgment. The Company does not provide advice or recommendations concerning any Digital Asset, including their suitability, appropriateness, or investment strategies. It is understood that your access and usage of the Platform and the Site are entirely at your own risk. However, please note that this concise statement does not encompass all the risks associated with Digital Assets and the use of the Platform. Consequently, you are strongly advised to carefully evaluate whether such usage aligns with your individual circumstances and financial capabilities. It is important to be aware that there is a possibility of incurring a complete loss of the Digital Assets in your Account, and certain market conditions may make it challenging or even impossible to liquidate a position.</p>
                            </div>
                            <div className="inner_info">
                                <h1>1. Risks Associated with Digital Assets</h1>
                                <ul>
                                    <li><p>The value and price of investments in Digital Asset products may fluctuate, potentially declining and even becoming valueless. Trading or investing in Digital Asset products carries inherent risks, which could result in losses rather than profits from buying and selling such products.</p></li>
                                    <li><p>Digital Assets are not considered legal tender and lack backing from governments or commodities like gold or silver. There is no central bank to intervene in times of crisis or issue more Digital Assets to protect their value. Instead, Digital Assets, such as Bitcoin and Ether, operate autonomously and with minimal regulation on global networks. Users place trust in a decentralised, digital, and partially anonymous system, relying on peer-to-peer networking and cryptography for security.</p></li>
                                    <li><p>Transactions involving Digital Assets may be irreversible, leaving little recourse for recovering losses due to fraudulent or accidental transactions.</p></li>
                                    <li><p>Some Digital Asset transactions are considered complete when recorded on a public ledger, which may not align with the date or time of initiating the transaction.</p></li>
                                    <li><p>The value of Digital Assets may be influenced by market participants' willingness to exchange fiat currency or other Digital Assets for them, leading to the risk of permanent and total value loss should the market for a specific Digital Asset collapse.</p></li>
                                    <li><p>The unique nature of Digital Assets increases the risk of fraud or cyber attacks, and technological issues with the Platform could prevent access to or use of Digital Assets.</p></li>
                                    <li><p>Trading Digital Assets is susceptible to irrational or rational bubbles, loss of confidence, and collapsed demand relative to supply. Confidence in a particular Digital Asset might diminish due to unforeseen changes by software developers, government interventions, the emergence of competing cryptocurrencies, or deflationary or inflationary trends. Confidence may also be compromised if the system's anonymity is breached, funds are lost or stolen, or if hackers or governments disrupt transactions.</p></li>
                                    <li><p>Deposits into your Account are not considered as deposits under the applicable laws, rules, or regulations in your jurisdiction.</p></li>
                                    <li><p>Digital Assets held in your Account are not covered by applicable deposit insurance protection.</p></li>
                                </ul>
                            </div>
                            <div className="inner_info">
                                <h1>2. Risks Associated with Derivatives, Margin Trading, and Borrowing of Security/Digital Assets</h1>
                                <ul>
                                    <li><p>Derivatives products carry a high level of risk due to leveraging or borrowing, resulting in a relatively small price movement in the underlying Digital Asset leading to a much larger movement, either in a favourable or unfavourable direction, in the value of the derivative product. If the market fluctuates opposite to the trade direction, significant losses can occur. To manage potential losses, investors may need to add margins or reduce positions; failure to do so may lead to forced liquidation of their positions, and investors will be responsible for all resulting losses.</p></li>
                                    <li><p>Financing a transaction by depositing collateral involves a substantial risk of loss. It is possible to incur losses exceeding the value of Digital Assets deposited as collateral with the Company. There might be situations where you are required to make additional margin deposits or interest payments on short notice. Failure to meet these requirements within the specified time may lead to the Company liquidating your collateral or positions at a loss without prior notification. Before opting for such financing arrangements, carefully consider their suitability based on your financial position and investment objectives.</p></li>
                                    <li><p>Borrowing securities and/or Digital Assets from the Company necessitates depositing a specified level of collateral. There may be instances where you are promptly asked to provide additional deposits if the existing collateral is deemed inadequate in comparison to the market value of the borrowed assets. If you fail to make the required deposit within the specified time, the Company reserves the right to repurchase the borrowed securities and/or Digital Assets without prior notice to you. On the other hand, when you lend securities and/or Digital Assets to the Company, you temporarily relinquish legal ownership rights to the lent assets while holding a right to claim equivalent products.</p></li>
                                </ul>
                            </div>
                            <div className="inner_info">
                                <h1>3.Other Risks</h1>
                                <ul>
                                    <li><p>Legislative and regulatory changes or actions at the state, federal, or international levels may have adverse effects on the use, transfer, exchange, and value of Digital Assets.</p></li>
                                    <li><p>THE COMPANY AND THE PLATFORM ARE NOT REGISTERED AS BROKERS OR DEALERS, NATIONAL SECURITIES EXCHANGES, CAPITAL MARKET SERVICE PROVIDERS, OR ALTERNATIVE TRADING SYSTEMS IN ANY JURISDICTION. THE TRADING AND SETTLEMENT OF TRANSACTIONS INVOLVING DIGITAL ASSETS ARE NOT GOVERNED BY ANY GOVERNMENTAL AUTHORITY OR SELF-REGULATORY INSTITUTION.</p></li>
                                    <li><p>The Platform or the Site may become unavailable due to changes in applicable laws, potentially leading to losses or liabilities due to the termination or suspension of the Platform or any or all of the Accounts.</p></li>
                                    <li><p>The Company's trading facilities rely on computer-based component systems for order-routing, execution, matching, registration, or clearing of trades. As with all computer systems, customers are exposed to risks related to these systems, including hardware and software failures. Such failures may result in your order not being executed as instructed or not being executed at all. Additionally, please be aware that the Internet is not entirely reliable, and there may be delays in service provision.</p></li>
                                    <li><p>It is important to note that proceeds from the sale of Digital Assets and other income may be subject to taxes, levies, duties, or other fees imposed by authorities in the relevant market, including withholding taxes. Tax laws and practices regarding Digital Assets in certain countries may not be well-established, and interpretations of the law or practices may change, including with retrospective effect.</p></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalTerms
