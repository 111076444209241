import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PrivacyPage from "./PrivacyPage";
import { shortTitle, title } from "../../config/config";
import { KeyboardArrowRight } from "@mui/icons-material";


const Policies = () => {


// listings@{title}.io

// contact@{title}.io
// partnership@{title}.io

let supportAccount = "support@{title}.io"




  const toggleMenuBar = () => {
    const ABC: HTMLElement | any = document.body;
    ABC.classList.toggle("policy__add__class");
  };

    return (
        <>
            <div className="policy__new__sds">

                <div className="inner-sec">
                    <Tabs>
                        <TabList>
                            <Tab>Introduction</Tab>
                            <Tab>Policy</Tab>
                            {/* <Tab>Terms Of Service</Tab> */}
                            <Tab>FlexAssets Terms</Tab>
                            <Tab>Affiliate Terms</Tab>
                            <Tab>Cookies Policy</Tab>
                            <Tab>Risk Policy</Tab>

                            <div className="button">
                                <button type="button" className="open-close_button" onClick={() => {  toggleMenuBar();  }}>
                                <KeyboardArrowRight/>
                                </button>
                            </div>
                        </TabList>

                        <TabPanel>
                            <PrivacyPage supportAccount={supportAccount} />
                        </TabPanel>



                    {/* Policy sec____ */}
                        <TabPanel>
                            <div className="min-tab-sec">

                                <Row className="m-0 p-0">
                                    <Col className="left col-12">
                                        <div className='policy_sec_body'>
                                            <h3>Policy</h3>
                                            <h2>At {title} ("We," "Us," and "{title}"), safeguarding and respecting your privacy is our utmost priority.
                                            </h2>
                                            <p>Any capitalized terms not explicitly defined in this Privacy Policy can be found in the Terms of Service.</p>
                                            <p>This policy, along with the Terms of Service, outlines the methods through which we may handle any information collected from you or provided by you. It encompasses both Personal Information, which can identify you, and non-identifying information. Within the scope of the law and this policy, the term "process" refers to actions such as collecting, storing, transferring, using, or any other interaction with information. To comprehend our perspectives and practices concerning your Personal Information and how we will handle it, we urge you to carefully review this policy.
                                            </p>
                                            <p>By sharing your personal information with Us, which includes, but is not limited to, using the Platform, contacting Us via email, or speaking to Us over the telephone, you provide your consent to the collection, storage, and processing of your personal information as outlined in this Privacy Policy. If you do not agree to abide by the terms stated here, we regret to inform you that your only option is to discontinue using our website.</p>

                                            <h3>What kind of personal information do we acquire?</h3>
                                            <p>We may acquire, store, and utilize various types of Personal Information, and all the data gathered will be retained for a minimum of five years.</p>
                                            <p>If you decide to register as a user on the Platform and wish to enable Stablecoin deposits/withdrawals, you will need to fill out an application. This application will require you to furnish personal details, including, but not limited to, your name, address, email address, proof of identity, and proof of address.</p>
                                            <p>When you reach out to us, whether it's through email, telephone, or any contact form provided by Us, we might request some or all of the information mentioned in the previous paragraph. We retain personally identifiable information associated with your message, such as your name and email address, to keep track of our communications and provide you with high-quality service.</p>
                                            <p> We May Acquire, Retain, And Utilize Information About The Computer, Mobile Device, Or Other Hardware You Use To Access The Platform, As Well As Details About Your Visits And Usage Of The Platform. This Includes, But Is Not Limited To, Your IP Address, Geographical Location, Browser/Platform Type And Version, Internet Service Provider, Operating System, Referral Source/Exit Pages, Length Of Visit, Page Views, Platform Navigation, And The Search Terms You Employ.</p>
                                            <p>Additionally, we collect data concerning your deposits, withdrawals, and trades on our Platform, along with any bank details and cryptocurrency wallet addresses you provide.</p>
                                            <p>For further details about our use of cookies, please refer to our cookies notice.</p>
                                            <p>Occasionally, we may request you to participate in surveys and provide feedback. These are used for research purposes, although your response to them is optional.</p>

                                            <h3>How do we utilize specific information</h3>
                                            <p>We may utilize your Personal Information for the following purposes:</p>
                                            <p>Managing and administering the Platform.</p>
                                            <p>Enhancing your browsing experience by customizing the Platform.</p>
                                            <p>Enabling you to utilize the Platform.</p>
                                            <p>Sending you newsletters and other relevant and targeted promotional communications.</p>
                                            <p>Handling inquiries, complaints, and feedback from you and our service providers.</p>
                                            <p>Providing third parties with statistical anonymized information about our users.</p>
                                            <p>Publishing generic transactional information relating to trades undertaken using the {title} platform.</p>
                                            <p>Conducting compliance checks.</p>
                                            <p>Sending you emails inviting you to participate in surveys and/or provide feedback.</p>
                                            <p>Analyzing and enhancing the services offered on the Platform.</p>

                                            <h3>At what instances could we disclose personal information? </h3>
                                            <p>Unless compelled by any applicable law or governmental, regulatory, or judicial authority, we shall refrain from disclosing your Personal Information to any third party, except when necessary to engage a service provider for their services on our behalf.</p>
                                            <p>{title} may employ a trusted third party to conduct essential anti-money laundering checks and other necessary verifications on your behalf. To facilitate this, {title} will share pertinent documentation containing your personal information, including, but not limited to, proof of identity.</p>

                                            <h3>Stay informed with our newsletters and receive personalized promotional content. </h3>
                                            <p>You have the opportunity to subscribe to our newsletters or receive tailored promotional communications from Us and our selected partners. To avail of this service, you can choose to ‘opt-in.’ Should you ever decide to discontinue receiving such communications, you can easily do so by clicking the ‘unsubscribe’ link provided in any email sent by Us.</p>
                                            <h3>How we secure your personal information</h3>

                                            <p>At our company, the security of your personal data is of utmost importance to us. We make every effort to ensure that appropriate and reasonable security measures are in place to protect your information. To bolster this protection, we utilize Secure Sockets Layer (SSL) certificates, which not only verify our identity to your browser but also encrypt any data you provide.</p>
                                            <p>However, it's essential to be aware that the internet's transmission of information is not entirely foolproof, and therefore, we cannot guarantee absolute security for data transmitted through the Platform. Any data transmission is undertaken at your own risk. Nevertheless, once we receive your information, we implement strict procedures and security features to prevent unauthorized access.</p>

                                            <h3>Duration of Personal Information Retention:</h3>
                                            <p>To ensure efficient service delivery, comply with legal requirements, and support possible legal claims, we retain your personal information for at least five years.
                                            </p>
                                            <h3>Updates to this Privacy Policy:</h3>
                                            <p>This policy will be periodically reviewed and subject to changes without prior notice. Any data we hold will be governed by our most up-to-date Privacy Policy.</p>

                                            <h3>Contact</h3>
                                            <p>We warmly welcome any questions, comments, or requests concerning this Privacy Policy. Please direct your inquiries to <Link to="#">mailto:support@{title}.io</Link></p>
                                        </div>

                                    </Col>


                                </Row>
                            </div>
                        </TabPanel>

                        {/* <TabPanel> ji</TabPanel> */}


                        <TabPanel>
                            <div className="min-tab-sec">

                                <Row className="m-0 p-0">
                                    <Col className="left col-12">
                                        <div className='policy_sec_body'>
                                            <h2>Flex Assets policy </h2>
                                            <p>Thank you for selecting token. {title}.io (referred to as the "Site"). These Terms of Service apply to all users of the Site and should be read alongside the Privacy Policy and Risk Disclosure Statement. By utilizing any of the Services offered on the Site (collectively or individually known as the "Services"), you (referred to as "you" or "your") agree to abide by these Terms of Service, and this agreement is made between you and Liquidity Technologies Limited, a company based in the Cook Islands ("{title}").
                                            </p>
                                            <p>These Terms of Service constitute the entire understanding and agreement between you and {title} regarding the use of the Services. The following documents are also incorporated into these Terms of Service by reference: the Risk Disclosure Statement, the Privacy Policy, the Anti-Spam Policy, the Law Enforcement Requests Policy, and the Fees Schedule. In case of any discrepancy between these Terms of Service and any other pages, policies, terms, conditions, licenses, limitations, or obligations found on the Site, these Terms of Service will take precedence.
                                            </p>
                                            <p>{title} reserves the right to impose fees on all transactions involving Digital Tokens, whether conducted on or off the Site, according to the Fees Schedule (https://{title}.io/fees), which may be updated periodically.
                                            </p>
                                            <p>By creating a Digital Tokens Wallet on the Site or utilizing any of the Services, you acknowledge that you have carefully read, comprehended, and agreed to these Terms of Service, subject to updates and amendments from time to time. If you do not consent to these Terms of Service, including any future modifications, you are not permitted to use any of the Services. By making use of any of the Services, you automatically bind yourself to these Terms of Service. If you do not wish to be bound by these Terms of Service, you must cease using all of the Services.
                                            </p>
                                            <p>{title} holds the right to amend these Terms of Service at any time without prior notice to you. It is your responsibility to regularly check the Site to ensure that you possess the current and accurate version of these Terms of Service. Your continued use of any Services following any amendments will indicate your acceptance of these updated Terms of Service.

                                            </p>
                                            <p>Please be aware that using the Site and any Services in regions where such usage is prohibited by law, constitutes a violation, or could lead to penalties, is strictly forbidden. Such usage shall not form the basis for asserting or acknowledging any interest, right, remedy, power, or privilege.
                                            </p>
                                            <h3>1.Interpretation:</h3>
                                            <p>1.1 Definitions: In these Terms of Service and all associated documents referred to herein, the following terms shall carry the following meanings, unless stated otherwise:
                                            </p>
                                            <p>1.1.1 "Affiliate" refers to a direct or indirect subsidiary of a Party, a holding company of the Party, and any other subsidiary of that holding company;

                                            </p>
                                            <p>1.1.2 "AML" stands for anti-money laundering, encompassing all applicable laws preventing money laundering for the Parties;

                                            </p>
                                            <p>1.1.3 "Anti-Corruption" encompasses all laws applicable to each Party, prohibiting corruption, bribery of Government Officials, kickbacks, inducements, and other forms of commercial corruption or bribery;
                                            </p>
                                            <p>1.1.4 "Associates" refers to {title} and all their respective shareholders, directors, officers, Affiliates, employees, contractors, agents, partners, insurers, and attorneys who are presently acting, have acted, or performed services for the benefit of or on behalf of the Site;
                                            </p>
                                            <p>1.1.5 "{title}" denotes Liquidity Technologies Ltd, an International Company registered in the Cook Islands;
                                            </p>
                                            <p>1.1.6 "{title} Platform" represents the website located at https://www.coinflex.com, along with the company Liquidity Technologies, Ltd., a Seychelles International Business Corporation, which owns and operates it;

                                            </p>
                                            <p>1.1.7 "{title} Platform Customers" refers to individuals or entities who possess an account on the {title} Platform for engaging in the trading of Perpetual Futures Contracts;

                                            </p>
                                            <p>1.1.8 "{title} Reserves" include USDC and other assets owned by {title} and held on the {title} Platform, as well as margin-collateral acquired by {title} in Perpetual Futures Contracts on the {title} Platform;
                                            </p>
                                            <p>1.1.9 "{title} Token" signifies the Digital Token issued and redeemed by {title}, which encompasses the flexUSD token backed by USDC, as well as other Digital Tokens backed by different Underlying Assets issued by {title} periodically;
                                            </p>
                                            <p>
                                                1.1.10 "Controlling Person" refers to any individual who owns more than a 25 percent interest in any Person or affiliate.
                                            </p>
                                            <p>1.1.11 "Copyrights" is defined as stated in paragraph 10 of these Terms of Service;

                                            </p>
                                            <p>1.1.12 "CRS" refers to the common reporting standard or the Standard for Automatic Exchange of Financial Account Information;
                                            </p>
                                            <p>1.1.13 "CTF" stands for counter-terrorist financing;
                                            </p>
                                            <p>1.1.14 "Digital Tokens" denotes a digital representation of value that serves as (i) a medium of exchange; (ii) a unit of account; (iii) a store of value, and/or (iv) other similar digital representations of rights or assets. These digital tokens are not issued or guaranteed by any country or jurisdiction and lack legal tender status in any country or jurisdiction. This category typically includes blockchain-based assets or rights, including sovereign cryptocurrency or virtual currency such as BTC, BCH, and ETH;
                                            </p>
                                            <p>1.1.15 "Digital Token Address" represents an alphanumeric identifier that signifies a potential destination for the transfer of a Digital Token. This identifier is typically associated with a user's Digital Tokens Wallet.

                                            </p>
                                            <p>1.1.16 "Digital Token Wallet" refers to a software application (or any other mechanism) that offers a means for holding, storing, and transferring Digital Tokens. It includes a user's Digital Token Address, Digital Token balance, and cryptographic keys.
                                            </p>
                                            <p>1.1.17 "Economic Sanctions" encompass financial sanctions, trade embargoes, export or import controls, anti-boycott measures, and restrictive trade measures that are enacted, administered, enforced, or penalized by laws applicable to you or the Site.

                                            </p>
                                            <p>1.1.18 "FATF" stands for the Financial Action Task Force, an international organization focusing on combating money laundering and terrorism financing.
                                            </p>
                                            <p>1.1.19 "Fiat" signifies the money or currency of any country or jurisdiction that is officially designated as legal tender, circulated, customarily used, and widely accepted as a medium of exchange in the country or jurisdiction of issuance.

                                            </p>
                                            <p>1.1.20 "Government" refers to any national, federal, state, municipal, local, or foreign branch of government. This includes any department, agency, subdivision, bureau, commission, court, tribunal, arbitral body, or other governmental, government-appointed, or quasi-governmental authority exercising executive, legislative, juridical, regulatory, or administrative powers. It also includes authority or functions related to a government instrumentality, such as parasternal companies or state-owned (majority or greater) or controlled business enterprises.
                                            </p>
                                            <p>1.1.21 "Government Approval" encompasses any authorization, license, permit, consent, approval, franchise, concession, lease, ruling, certification, exemption, exception, or waiver by or from any Government that is necessary for conducting the business of either Party or for executing and delivering the Services under these Terms of Service.
                                            </p>
                                            <p>1.1.22 "Government Official" refers to an officer or employee of any Government, a director, officer, or employee of any government-related entity, a candidate for public office, a political party, or a political party official. It also includes an officer or employee of a public international organization, and any individual acting in an official capacity for any of the aforementioned, even if temporarily and without compensation.
                                            </p>
                                            <p>1.1.23 "Losses" carries the meaning as set forth in paragraph 14 of these Terms of Service.
                                            </p>
                                            <p>1.1.24 "Margin-collateral" denotes an asset with sufficient value to repay a loan obligation.

                                            </p>
                                            <p>1.1.25 "Marks" has the meaning as defined in paragraph 10 of these Terms of Service.
                                            </p>
                                            <p>1.1.26 "OFAC" stands for the Office of Foreign Assets Control of the U.S. Department of the Treasury.
                                            </p>
                                            <p>1.1.26 "Perpetual Futures Contract" refers to a futures contract offered on the CoinFLEX Platform that does not have an expiry date.

                                            </p>
                                            <p>1.1.27 "Person" includes an individual, association, partnership, corporation, other body corporate, trust, estate, and any form of organization, group, or entity recognized as legal personalities.</p>
                                            <p>1.1.28 "Personal Information" carries the meaning as defined in the Privacy Policy.
                                            </p>
                                            <p>1.1.30 "Prohibited Jurisdictions" include Cuba; Democratic People's Republic of Korea (North Korea); Iran; Pakistan; Singapore; Syria; the Government of Venezuela; and Crimea.
                                            </p>
                                            <p>
                                                1.1.31 "Prohibited Use" has the meaning as set forth in paragraph 8 of these Terms of Service.
                                            </p>
                                            <p>1.1.33 "Sanctions List" comprises the "Specially Designated Nationals and Blocked Persons" ("SDN") List and the Non-SDN List, including the "Sectoral Sanctions Identifications List," as published by OFAC (Office of Foreign Assets Control). It also includes the Section 311 Special Measures for Jurisdictions, Financial Institutions, or International Transactions of Primary Money Laundering Concern published by FinCEN (Financial Crimes Enforcement Network). Additionally, it encompasses any other foreign terrorist organization or other sanctioned, restricted, or debarred party list published by the FIA (Financial Intelligence Agency), or under Economic Sanctions, AML (Anti-Money Laundering), or CTF (Counter-Terrorist Financing) laws of or by Governments of the United States, the Seychelles, Hong Kong SAR, the United Nations, or any other applicable jurisdiction or Government, as amended, supplemented, or substituted from time to time.
                                            </p>
                                            <p>
                                                1.1.34 Definition of "Sanctioned Person":
                                                The term "Sanctioned Person" pertains to any individual or Digital Token Address meeting the following criteria: (i) explicitly mentioned in any Sanctions List; or (ii) having 50 percent or more ownership directly or indirectly by any individual or group of individuals, along with a Digital Tokens Wallet associated with such individuals, as mentioned in any Sanctions List, or associated with a Government or Government Official from any Prohibited Jurisdiction; and (iii) not being subject to any Government Approval or any form of sanction, restriction, or penalty under applicable laws.
                                            </p>
                                            <p>1.1.35 Meaning of "Terms of Service":
                                                "Terms of Service" refers to the terms and conditions governing the provision of services, subject to changes, amendments, or updates periodically, and encompassing the following Site policies and pages: the Risk Disclosure Statement; the Privacy Policy; the Anti-Spam Policy; the Law Enforcement Requests Policy; and the Fees Schedule.

                                            </p>
                                            <p>1.1.36 Definition of "Underlying Asset":
                                                The term "Underlying Asset" signifies the asset that supports a specific type of CoinFLEX Token, for instance, flexUSD, which may be backed by assets like USDC.
                                            </p>
                                            <p>.1.37 Definition of "U.S. Citizen or U.S. Resident":
                                                The term "U.S. Citizen or U.S. Resident" encompasses individuals falling under the following categories: (i) U.S. citizens; (ii) U.S. lawful permanent residents; (iii) individuals meeting the "substantial presence" test as described in section 7701(b)(3) of the U.S. Internal Revenue Code of 1986 (as amended); (iv) protected individuals under section 1324b(a)(3) of the U.S. Immigration and Nationality Act; or (v) individuals holding a passport issued by the United States Government.
                                            </p>
                                            <p>1.1.38 Meaning of "USDC":
                                                "USDC" denotes the stablecoin issued by Coinbase, backed in a 1:1 ratio to US dollars. Further information can be found at https://www.coinbase.com/usdc.

                                            </p>
                                            <p>1.1.39 Definition of "U.S. Person":
                                                The term "U.S. Person" refers to the following:
                                            </p>
                                            <p>1.1.39.1 U.S. Citizen or U.S. Resident;
                                            </p>
                                            <p>1.1.39.2 A corporation, partnership, or any other entity established or organized under the laws of the United States;
                                            </p>
                                            <p>1.1.39.3 Any estate of a decedent who was a citizen or a resident of the United States; or
                                            </p>
                                            <p>1.1.39.4 Any trust that meets both conditions: (i) A court within the United States has the ability to exercise primary supervision over the administration of the trust, and (ii) one or more United States Persons have the authority to control all significant decisions of the trust.

                                            </p>
                                            <p>1.1.39.5 Definition of "you" or "your":
                                                The terms "you" or "your" refer to the user.
                                            </p>
                                            <p>1.2 Headings:
                                                The headings and subheadings used in these Terms of Service are provided solely for the purpose of convenient reference and do not affect the interpretation or construction of any provisions to which they pertain.
                                            </p>
                                            <p>1.3 Extended Meanings:
                                                Unless explicitly stated otherwise in these Terms of Service, words in the singular form shall include the plural form, and words pertaining to a specific gender shall encompass all genders.
                                            </p>
                                            <p>1.4 Governing Law:
                                                These Terms of Service shall be governed by, construed, and enforced in accordance with the laws of the Cook Islands. They shall be considered a contract under the laws of the Cook Islands in all respects. Any dispute, controversy, claim, or action arising from or related to your use of the Site or these Terms of Service shall also be subject to the laws of the Cook Islands, without consideration of choice-of-law principles.
                                            </p>
                                            <h3>2.License to Use the Site:
                                            </h3>
                                            <p>{title} grants you a limited right to utilize the Site and its Services, provided that you agree to comply with the terms outlined in these Terms of Service. This right to use the Site and Services is personal, restricted, non-exclusive, non-transferable, non-sublicensable, revocable, and subject to the limitations and obligations stated within these Terms of Service. It is important to note that these Terms of Service do not confer upon you any license, right, title, or ownership of, in, or to the Site, its Services, copyrights, or trademarks (Marks). {title} reserves the right, at its sole discretion and in accordance with applicable laws, to suspend or terminate the Services to you, your Digital Token Wallet, or any of your Digital Token Addresses if {title} determines that you have violated, breached, or acted inconsistently with any provisions of these Terms of Service.</p>
                                            <h3>3. About {title} Tokens; General Restrictions:
                                            </h3>
                                            <p>{title} issues and redeems {title} Tokens, which can be utilized as a form of payment wherever parties are willing to accept them.
                                            </p>
                                            <p>The primary purpose of {title} Tokens is to function as stablecoins that offer interest payments. These tokens are pegged to the value of an Underlying Asset, which is loaned out at interest through Perpetual Futures Contracts on the CoinFLEX Platform.
                                            </p>
                                            <p>It's important to note that {title} Tokens carry certain risks that other non-interest bearing stablecoins do not possess. While {title} has taken measures to minimize the risk of loss, it's impossible to eliminate all risks entirely. Users must grasp the structure of {title} Tokens to comprehend the associated risks fully. For more details, please refer to our Risk Disclosure Statement.
                                            </p>
                                            <h3>What backs the value of {title} Tokens?
                                            </h3>
                                            <p>Each {title} Token is designed to mirror the value of another asset, such as USDC or BTC (the "Underlying Asset"). The value peg to the Underlying Asset is achieved by enabling {title} Token holders to redeem their tokens for the Underlying Asset. {title} Tokens are supported by the {title} Reserves, consisting of a combination of the Underlying Asset and margin-collateral that {title} uses for the redemption of {title} Tokens.

                                            </p>
                                            <p>It's essential to recognize that {title} Tokens differ from the Underlying Asset that imparts value to them. Although holders of {title} Tokens have the right to redeem their tokens for the Underlying Asset, subject to these Terms of Service, this right of redemption doesn't signify custodial ownership. Consequently, {title} Tokens may be traded at a discount compared to the value of the Underlying Asset that backs them.
                                            </p>
                                            <h3>Do {title} Tokens pay interest, and how is that interest calculated and paid?
                                            </h3>
                                            <p>{title} Tokens are designed to pay interest based on the backing of Underlying Assets. Understanding the interest generation process is crucial for assessing the risk of holding these tokens. For example, flexUSD, backed by USDC, generates interest by lending out its USDC reserves in Perpetual Futures Contracts on the {title} Platform.
                                            </p>
                                            <p>Different {title} Tokens backed by various Underlying Assets may yield varying interest rates and trade volumes, resulting in different interest amounts for holders. The interest is distributed pro rata among holders of a specific {title} Token and is paid in {title} Tokens via smart contracts to their Digital Wallet Addresses.
                                            </p>
                                            <p>{title} charges a ten percent fee on the total interest generated by {shortTitle} repo lenders before distributing it to {title} Token holders. Distributions occur at specific times aligned with certain Perpetual Futures Contract funding windows.

                                            </p>
                                            <p>The content does not explicitly state whether {title} maintains custody and control over the Underlying Assets when loaned for trading on the {title} Platform's Perpetual Futures Market.
                                            </p>
                                            <h3>Might the volatility within the {title} Platform's Perpetual Futures Contract market lead to a potential loss of the Underlying Assets?
                                            </h3>
                                            <p>The {title} Platform faces additional risks due to volatility in the Perpetual Futures Contract market. Although efforts have been made to mitigate these risks, extreme volatility remains a concern. To safeguard lenders in Perpetual Futures Contracts, the platform utilizes two risk-reduction methods: posting margin-collateral and implementing forced liquidations. Margin-collateral is required for loans, and in the event of borrower losses, it can be liquidated to cover loan obligations. Forced liquidations are enforced when the value of margin-collateral falls, ensuring it is sufficient to repay the borrower's loans. The {title} Platform employs algorithms to trigger forced liquidations before lenders suffer losses. The terms governing forced liquidations are determined solely by the {title} Platform.

                                            </p>
                                            <h3>What is the reason for the risk of potential loss to Underlying Assets loaned in a Perpetual Futures Contract?

                                            </h3>
                                            <p>Understanding the {title} Platform's Perpetual Futures Market is crucial to grasp the risk of potential loss. Familiarize yourself with the platform's terms and the following explanation to comprehend the risks fully. There is always a risk of loss in Perpetual Futures Contracts because if the borrower lacks sufficient Underlying Asset (e.g., USDC for flexUSD) to repay, the margin-collateral (BTC, ETH, etc.) needs to be sold. Extreme market volatility can hinder quick force-liquidation of margin-collateral, leading to a shortfall in the outstanding Perpetual Futures Contract obligation. Four examples illustrate different scenarios and potential outcomes. While {title} Token holders experience no loss in some cases, rapid price declines with insufficient buyers may result in a loss for Token holders in others.
                                            </p>
                                            <h3>In the event of an insolvency event on the {title} Platform, what will be the impact on the {title} Reserves?

                                            </h3>
                                            <p>{title} and the {title} Platform are separate entities operated by the same people. When {title} Reserves are loaned in Perpetual Futures Contracts on the Platform, {title} becomes a creditor to the Platform, which is a debtor. In case of the Platform's insolvency, and would be a general unsecured creditor, receiving a pro rata share of remaining assets. However, these payments may not be enough to cover {title}'s obligations to Token holders, potentially leading to a loss.
                                            </p>
                                            <h3>4: Risks and Limitation of Liability - Important: This section supplements the Risk Disclosure Statement.
                                            </h3>
                                            <p>The section highlights the risks and responsibilities associated with trading in Digital Tokens on the {title} Platform. Digital Token markets are volatile, and extreme volatility can lead to losses for {title} Token holders and the {title} Reserves.

                                                Users are solely responsible and liable for their trading and non-trading activities on the Site, as well as for safeguarding their Digital Tokens Wallet and sensitive information. {title} is not responsible for any losses arising from third-party transactions, and users must address any issues with third-party sellers directly.
                                            </p>
                                            <p>
                                                The content also warns about the cybersecurity risk, including the potential loss of Digital Tokens if private keys are compromised. Digital Tokens may be compatible with third-party software, but {title} does not guarantee the security or functionality of such software and is not liable for Digital Token losses caused by third-party software failures.
                                            </p>
                                            <p>Overall, users must exercise caution, understanding that {title} does not control third-party transactions or guarantee Digital Token security outside its platform.
                                            </p>
                                            <h3>5. Withdrawals and Deposits:
                                            </h3>
                                            <p>The content emphasizes several points regarding user information sharing and Digital Token transactions on the {title} Platform. {title} may share user information with third parties during withdrawals or deposits as required by contracts, applicable laws, or lawful governmental requests, and users release {title} from any liability in this regard.
                                            </p>
                                            <p>Users must accept the irreversible nature of Digital Token transactions and the risk of losing access to sent tokens indefinitely or permanently. {title} reserves the right to delay or suspend redemption under certain circumstances, including Prohibited Use, government directions, pending litigations, or suspected unauthorized access.

                                            </p>
                                            <p>Additionally, Digital Tokens are not legal tender and lack backing or protections from any government entities like the FDIC or Securities Investor Protection Corporation.
                                            </p>
                                            <h3>6. Resolution of Disputes:
                                            </h3>
                                            <p>Any disputes, claims, controversies, or actions related to the Terms of Service, your account(s), the Site's operations and Services, or your access to and use of the Services are subject to the exclusive jurisdiction of the courts of the Cook Islands. This provision applies to any claim against {title}, regardless of whether it is in tort, contract, or otherwise. By using the Services, you agree to the jurisdiction and venue of the courts in the Cook Islands, waiving any objections, including forum non conveniens. Claims may only be brought on an individual basis, and no consolidation or joining of claims is permitted. The relief awarded to one user does not affect other {title} users.
                                            </p>
                                            <h3>7: Jury Trial Waiver:</h3>
                                            <p>The parties involved hereby completely and unconditionally waive any right to trial by jury in any legal action or proceeding, to the fullest extent allowed by applicable law. This waiver applies to all matters related to these Terms of Service, any breach thereof, your use or attempted use of the Site or the Service, and any other issues involving the parties.
                                            </p>
                                            <h3>8. Prohibited Uses: You may not:
                                            </h3>
                                            <p>Here are the prohibited actions in points:
                                            </p>
                                            <p>8.1 Using the Site or Services to disguise the origin or nature of illicit proceeds or contraband Digital Tokens.
                                            </p>
                                            <p>8.2 Violating any applicable laws, including AML, CTF, Anti-Corruption, and Economic Sanctions laws, while using the Site or Services.
                                            </p>
                                            <p>8.3 Facilitating, evading, or violating applicable laws using the Site or any U.S. Financial Institution's services.
                                            </p>
                                            <p>8.4 Using the Site or Services to evade taxes in any jurisdiction applicable to you or the Site.</p>
                                            <p>8.5 Using the Site with illegally obtained Fiat, funds, property, or Digital Tokens.
                                            </p>
                                            <p>8.6 Interfering with CoinFLEX's or other users' rights or obligations or causing legal liability for CoinFLEX or other users.
                                            </p>
                                            <p>8.7 Exploiting technical glitches or security breaches on the Site.
                                            </p>
                                            <p>8.8 Engaging in conduct detrimental to CoinFLEX or other users.
                                            </p>
                                            <p>8.9 Engaging in wash trading, price manipulation, or disrupting the orderly conduct of trading.
                                            </p>
                                            <p>8.10 Falsifying information provided to CoinFLEX or misrepresenting affiliation with others.

                                            </p>
                                            <h3>9. General Due Diligence, Anti-Money Laundering, and Counter-Terrorist Financing.
                                            </h3>
                                            <p>{title} is committed to maintaining a safe and compliant platform, adhering to Anti-Money Laundering (AML), Counter-Terrorist Financing (CTF), Anti-Corruption, and Economic Sanctions laws. Users must undergo a comprehensive due diligence process and provide necessary information to meet regulatory requirements. {title} monitors transactions for suspicious activities and reports to regulators when necessary, even after the user's relationship with {title} ends.
                                            </p>
                                            <p>Registration may be refused or services suspended or terminated for any user violating the terms, including conducting business with sanctioned individuals or high-risk jurisdictions. Enhanced due diligence may be performed in certain cases. Failure to provide requested due diligence information may result in immediate suspension or termination of services.
                                            </p>
                                            <h3>10. Intellectual Property:
                                            </h3>
                                            <p>In the provided content, {title} emphasizes the protection of its intellectual property rights. The Marks, including trademarks, service marks, and trade names, belong to {title} or third parties, and users are strictly prohibited from using, copying, or displaying them without obtaining express written permission from the rightful owners. Furthermore, all materials on the {title} website are copyrighted and exclusively owned by {title} or its parent company, granting users no right to appropriate, copy, or use this content without prior written consent from {title}.</p>
                                            <p>Additionally, the {title} platform and its services are protected by copyright, trademark, and other intellectual property laws in various jurisdictions. As such, users are strictly prohibited from engaging in unauthorized commercial exploitation, modification, redistribution, or derivative works based on the site or services without express authorization from {title} or its licensors. The content discourages any attempts to frame, mirror, or reverse engineer the site or its services for competitive purposes or to copy its ideas, features, functions, or graphics.
                                            </p>
                                            <h3>11. No Representations & Warranties by CoinFLEX:
                                            </h3>
                                            <p>{title} provides no assurances, guarantees, or warranties of any nature to users. The Site and Services are offered strictly on an "as-is" and "where-is" basis, without any representation as to their merchantability or fitness for specific purposes. Additionally, {title} may grant access to features or services labeled as "beta" or pre-release. It is essential to note that such services are still under development, and they may contain bugs, errors, be incomplete in terms of features, undergo significant changes before a full commercial launch, or potentially not be released commercially at all. Thus, users should be aware of these factors and acknowledge the inherent uncertainties associated with beta services provided by {title}.
                                            </p>
                                            <h3>12. Absence of Guidance: CoinFLEX does not offer any investment advice or guidance regarding trading techniques, models, algorithms, or any other strategies.

                                            </h3>
                                            <h3>13. Limitation of Liability & Release:
                                            </h3>
                                            <p>{title} holds no liability for any Losses arising from various factors, including these Terms of Service, your use of the Site or Services, inaccurate statements regarding your Digital Tokens Wallet, Site operation or Service provision, security breaches, or actions by third parties. This includes delays, value losses, or theft of assets backing {title} Tokens. You agree to release the Associates from liability and indemnify them against any Losses, regardless of the basis for such claims. These limitations of liability apply even if the Associates were aware of the potential for such damages, and regardless of the success of other remedies.
                                            </p>
                                            <h3>14. No Waiver:
                                            </h3>
                                            <p>{title}'s failure to exercise or delay in exercising any of its rights, powers, or remedies under these Terms of Service does not imply a waiver of those rights, powers, or remedies. Similarly, the partial exercise of any right, power, or remedy by {title} does not restrict its ability to exercise any other rights, powers, or remedies available to it.
                                            </p>
                                            <h3>15. Assignment:
                                            </h3>
                                            <p>You cannot assign these Terms of Service, including any rights, duties, or obligations, without obtaining prior written consent from {title}. On the other hand, {title} has the right to freely assign these Terms of Service, along with any rights, duties, or obligations, without providing notice or seeking your consent. Any attempt by you to assign these Terms of Service without obtaining written consent from {title} will be considered void.

                                            </p>
                                            <h3>16. Severability:
                                            </h3>
                                            <p>If any provision or part of these Terms of Service, as amended from time to time, is found to be invalid, void, or unenforceable by a court of competent jurisdiction, such invalidity, voidness, or unenforceability shall only affect that specific provision to the extent of its illegality or unenforceability, while all other parts of these Terms of Service remain in full force and effect.

                                            </p>



                                        </div>

                                    </Col>


                                </Row>
                            </div>
                        </TabPanel>


                        <TabPanel>
                            <div className="min-tab-sec">

                                <Row className="m-0 p-0">
                                    <Col className="left col-12">
                                        <div className='policy_sec_body'>
                                            <h2>{title} operates as the trading name of Liquidity Technologies Limited, a registered company in Seychelles with business registration number 208853.

                                            </h2>
                                            <p>This document, referred to as the "Affiliate Program Services Agreement" ("Services Agreement"), outlines the terms and conditions governing your participation in the Affiliate Program ("Affiliate Program") offered by {title}.</p>
                                            <p>By choosing to take part in the Affiliate Program, you acknowledge and accept the terms and conditions presented in the Affiliate Program Services Agreement, as well as those stated in the Terms Of Service, and any other disclaimers and policies published on our website. These policies may include, but are not limited to, the Privacy Policy, Cookies Policy, and Risk Policy. Please be aware that {title} reserves the right to modify, amend, or terminate the Services Agreement and the Affiliate Program at any time and at its sole discretion. If you do not agree with the terms and conditions of the Services Agreement, the Affiliate Program, or any subsequent changes, we kindly request that you refrain from participating in the Affiliate Program. By participating or continuing to participate in the Affiliate Program, you indicate your acceptance of all the aforementioned terms and conditions, as well as any amendments or modifications that may be made.
                                            </p>
                                            <p>Citizens, residents, or individuals located in the United States of America or any other restricted, sanctioned, or prohibited jurisdiction are strictly prohibited from engaging in the following activities:</p>
                                            <ul>
                                                <li>Becoming an Affiliate of {title}.</li>
                                                <li>Referring or recommending {title} to others as an Affiliate.</li>
                                                <li>Opening an account as a Follower or using any of {title}'s products or services.</li>
                                                <li>Receiving any Commissions due to an Affiliate or any Rebates offered to a Follower.</li>
                                            </ul>
                                            <h3>1. How does the Affiliate Program function?</h3>
                                            <p>{title} offers eligible Affiliates the opportunity to refer and recommend the {title} trading platform to their Followers. In return, Affiliates can earn Commissions, receiving a percentage of the fees paid by those Followers to {title}.</p>
                                            <p>Moreover, Followers will be entitled to a Rebate on completed trade fees during the initial period from the moment of registration.
                                            </p>

                                            <h3>2. Your Responsibilities as an Affiliate</h3>
                                            <p>You understand and agree that your website or communication methods must not:
                                            </p>
                                            <p>Contain false, inaccurate, or misleading information regarding {title}'s products and services.
                                                Copy or resemble the look and feel of our website content in any way, nor create the impression that your website or communication location is affiliated with {title}.</p>
                                            <p>Engage in sending unsolicited commercial emails or indiscriminate advertising.</p>
                                            <p>Violate any applicable laws, such as data transfer, data privacy, data security, or securities laws, by offering or promoting {title}'s products and services in any jurisdiction where such actions would be illegal, regulated, restricted, or require licensing.</p>
                                            <p>Publish or facilitate the publication of material that could be interpreted as an offer, solicitation, or recommendation to buy or sell investment products, thereby breaching relevant laws, rules, or regulations.</p>
                                            <p>Make statements or promises on behalf of {title}.</p>
                                            <p>Engage in activities that {title} deems, at its sole discretion, to be detrimental to its customers, employees, operations, or reputation.</p>
                                            <p>Make statements, promises, or implications regarding returns, risks, or success through the use of {title}'s products or services.</p>
                                            <p>State or imply that any information, content, or data on your website or communication location represents or reflects the views, advice, or opinions of {title}.</p>
                                            <p>Use <Link to="#">https://{title}.io</Link> as a display URL.</p>
                                            <p>Please ensure compliance with these guidelines as an Affiliate.</p>
                                            <p>You acknowledge and agree that {title} retains the right to terminate the Services Agreement if you, your website, or your publishing location violate any of the aforementioned restrictions or any additional restrictions imposed by {title} at its sole discretion, whether presently or in the future. </p>
                                            <p>Moreover, {title} reserves the right to pursue the recovery of any or all Commissions paid or payable to you, and you hereby accept liability for such repayment of Commission in the event of any violation, as determined by {title} in its sole discretion. Please be aware that these restrictions may be modified by {title} at any time and without prior notice.</p>

                                            <h3>3. Your Responsibilities as a Follower, you acknowledge and agree that:</h3>
                                            <p>The "Affiliate link" cannot be utilized with {title} accounts that are already registered.
                                                You will not create duplicate accounts with the purpose of using the "Affiliate link."</p>
                                            <p>Your email address and registration time will be shared with your Affiliate.</p>
                                            <h3>4. Our Responsibilities</h3>
                                            <p>To Affiliates:</p>
                                            <p>We will track, verify, report, and pay you the Commissions that are due to you. Commissions will be credited in the asset class that your Followers used to pay exchange fees. For instance, if they paid fees in both Tether and USD Coin for different products they traded on {title}, you will receive Commissions in both of these cryptocurrencies.
                                            </p>
                                            <p>Regarding our spot order books, the Commissions earned from your Followers' trades will be available in your balance after 12 pm UTC on the day following the date when the Commissions were earned. As for our futures products, which are physically delivered, the Commissions earned from your Followers' trades in the futures products will become available in your balance after 12 pm UTC on the day of the expiry of the relevant futures contracts.</p>
                                            <p>To Followers:
                                            </p>
                                            <p>We will track, verify, and apply the Rebate that is due to you.</p>
                                            <p>General:</p>
                                            <p>You acknowledge that {title} will not be held responsible for any missed opportunities to earn Commissions or receive Rebates due to circumstances beyond our control, such as technical difficulties or system overload, which may prevent us from registering accounts, accepting deposits, executing trades, closing positions, or providing any other product or service to the Followers.</p>
                                            <h3>5. Cancellations of the Program</h3>
                                            <p>{title} reserves the right to terminate or modify the terms of the Affiliate Program, including Commission percentages, Rebate percentages, and the program duration, due to changing market conditions, fraud risk, or any other relevant reasons, at any time. However, any Commissions earned up to the end of the Affiliate Program will always be available in your balance from that point onwards.</p>



                                        </div>

                                    </Col>


                                </Row>
                            </div>
                        </TabPanel>


                        <TabPanel>
                            <div className="min-tab-sec">

                                <Row className="m-0 p-0">
                                    <Col className="left col-12">
                                        <div className='policy_sec_body'>
                                            <h2>The {title} website uses cookies</h2>
                                            <h3>What is a Cookie?</h3>
                                            <p>A cookie is a small file containing letters and numbers that gets downloaded onto a device when a user accesses certain websites. It enables a website to recognize a user's device and includes information about the domain from which the cookie originated and its lifespan (usually expiring after a specific period).</p>

                                            <h2>Four types of cookies used on this website and the information collected:</h2>
                                            <h3>Performance and Analytics Cookies:</h3>
                                            <p>These cookies are employed to enhance the user experience on our website, evaluate content performance, and resolve technical issues. We use analytics tools, including from third parties like Google Analytics, to collect aggregate data. These cookies cannot identify individual users.</p>
                                            <h3>Strictly Necessary Cookies:</h3>
                                            <p>These cookies allow users to browse our website and utilize its features. The data collected by these cookies is related to the website's operation, such as secure authentication and session stability until the user signs out.
                                            </p>
                                            <h3>Marketing Cookies:</h3>
                                            <p>As part of our marketing initiatives, we use these cookies to gather information about users' browsing habits and inferred interests, enabling us to deliver more relevant advertising. They also limit the frequency of ad displays and measure advertising campaign effectiveness. Some of these cookies come from third-party advertising networks, which share information about other websites you visit with third-party organizations, like advertisers.</p>
                                            <h3>Functionality Cookies:</h3>
                                            <p>We utilize functionality cookies on our website to remember your choices and personal preferences, such as login details, to enhance your user experience. By storing this information, we aim to avoid the need for you to re-enter these details during each visit, making your interactions with our website smoother and more efficient.
                                            </p>
                                            <h3>Use of Web Beacons:</h3>
                                            <p>Certain web pages on our site may contain web beacons that enable us to count the number of users who have visited those pages. These web beacons collect limited data, including a cookie number, the time and date of a page view, and a description of the page containing the web beacon. However, they do not collect any personally identifiable information. The purpose of web beacons is to track the effectiveness of specific marketing campaigns.</p>
                                            <h3>Managing Cookies:</h3>
                                            <p>While accepting cookies is not mandatory, disabling or deleting cookies generated by our website might impact its performance and functionality. This could result in limited options and reduced website performance during your visit. Most modern browsers offer settings to manage cookie preferences, usually found in the 'options' or 'preferences' menu.</p>
                                            <h3>Useful Links:</h3>
                                            <p>To learn more about cookies' usage on the internet and privacy issues related to internet advertising, you can visit the following links:</p>
                                            <p><Link to="#">https://www.allaboutcookies.org</Link></p>
                                            <p><Link to="#">https://www.youronlinechoices.eu</Link></p>
                                            <p>By using our website, you agree to the placement of the aforementioned types of cookies on your device.</p>
                                        </div>

                                    </Col>


                                </Row>
                            </div>
                        </TabPanel>


                        <TabPanel>
                            <div className="min-tab-sec">

                                <Row className="m-0 p-0">
                                    <Col className="left col-12">
                                        <div className='policy_sec_body'>
                                            <h2>Introduction Risk Policy

                                            </h2>
                                            <p>{title} offers the option to trade Digital Assets and their derivatives, but it's crucial to be aware of the high risks involved. This warning aims to highlight key factors and risks associated with trading on our platform. However, it doesn't cover all risks or their application to your unique situation. It's essential to comprehend these risks thoroughly before engaging in trading with us. If you have any uncertainties, please reach out to us for clarification. When opening an account, you must always keep these risk factors in mind and ensure you have sufficient financial resources to handle such risks during your trades. Monitoring your positions carefully is strongly advised.
                                            </p>
                                            <h3>Our Products</h3>

                                            <p>Our products offer you the opportunity to gain exposure to cryptocurrencies, either directly or indirectly. If you choose to trade PD (Product A), you will own the underlying asset. However, if you decide to close or roll the position near expiry, you will not own the underlying asset, and the settlement will be in cash. Nonetheless, you will still experience gains or losses based on the price movements of the underlying asset to which you have indirect exposure.
                                            </p>
                                            <p>It's important to be aware that trading our products carries a high level of risk and may not be suitable for everyone.
                                            </p>
                                            <p>Understanding Digital Assets requires advanced technical knowledge. The language used to describe Digital Assets is often highly technical and demands a comprehensive understanding of applied cryptography and computer code to fully appreciate the inherent risks involved.
                                            </p>
                                            <p>The fact that a Digital Asset is listed on {title} does not imply {title}'s approval or disapproval of the underlying technology of that Digital Asset. It should not be used as a substitute for your own understanding of the specific risks associated with each Digital Asset.
                                            </p>
                                            <p>Please note that some of our products are listed on a "permissionless" basis. We do not guarantee the appropriateness, quality, or viability of any digital assets or derivatives listed on {title}. There is no warranty provided as to the suitability of the Digital Assets traded on {title}, and we do not assume any fiduciary duty to you in connection with your use of our platform.
                                            </p>
                                            <p>Trading in complex financial products, which includes, among others, perpetual futures contracts, futures contracts, permissionless perpetual futures contracts, repo, and spreads (collectively referred to as "Complex Products"), may not be suitable for all users. These Complex Products are specifically designed for sophisticated users, such as active traders employing dynamic strategies.
                                            </p>
                                            <p>If you choose to trade Complex Products, it is crucial to exercise extreme caution and only do so if you fully understand how they operate. This understanding should encompass the associated risks of margin trading, the use of leverage, the potential risk of shorting, and the impact of compounding and market volatility on leveraged products.
                                            </p>
                                            <p>It's important to acknowledge that trading Complex Products involves significant risk, and you may experience immediate losses. Participating in Complex Product trading requires you to provide an initial collateral amount to meet the initial margin requirements. If market movements for a Complex Product or its underlying Digital Asset lead to a decrease in the value of your position, you may be obligated to provide additional collateral to meet the maintenance margin requirements. Failure to maintain the minimum margin requirements in your account could result in the liquidation of your position at a loss, potentially leading to a complete loss of assets in your account.
                                            </p>
                                            <h3>Your losses are limited to your deposits
                                            </h3>
                                            <p>Every financial investment carries a certain level of risk. However, when using this platform, your potential losses are limited to the amount you have deposited into your account, and you will not be required to owe us any additional money.
                                            </p>

                                            <h3>Our products are typically traded with the use of leverage.
                                            </h3>
                                            <p>Leveraged trading allows you to trade without having to pay or deposit the full value of your position upfront. Instead, we require a form of security or deposit, known as Margin, to cover any potential losses that may arise during leveraged trading. It's important to understand that trading with leverage can magnify both your gains and losses. This means that even small price fluctuations in the underlying asset can lead to significant gains or losses in your positions.
                                            </p>
                                            <p>It is crucial to be aware that your losses have the potential to exceed the amount of your initial deposits, as leveraged trading carries an elevated level of risk.
                                            </p>

                                            <h3>The effects of trading on margin
                                            </h3>
                                            <p>For each transaction, we require you to deposit and maintain a specific amount of money in your account, known as "Margin." This Margin serves as a deposit, covering a portion of the full value of the transaction and enabling leverage, as mentioned earlier. For instance, we may ask you to deposit 10% of the full trade value you wish to enter into. Insufficient Margin could result in you owing us money if any of your open positions are closed. Margin acts as a protective measure for us, ensuring that we are covered in case you are unable to fulfill any financial obligations when transactions are closed.
                                            </p>
                                            <p>The required Margin amount is determined by us and can be adjusted for any reason. To keep a position open, your account must maintain a minimum amount of money, known as the "Maintenance Margin." It is essential to maintain sufficient Margin in your Account at all times to meet this Maintenance Margin threshold. In the event that the market moves against you, you might be required to promptly provide additional funds to meet Margin requirements and maintain your position.
                                            </p>
                                            <p>Failure to maintain the Maintenance Margin level may trigger our auto-liquidation algorithm, which can reduce your open position to comply with Margin requirements and/or close your open positions without prior notification. Consequently, you will be held responsible for any losses incurred as a result of these actions.

                                            </p>
                                            <p>Every futures trade you initiate through our services establishes a contractual agreement between you and a counterparty. Please note that {title} does not function as the seller when you buy, nor as the buyer when you sell. Despite this arrangement, it is not permissible to transfer your contracts to other providers or exchanges. All contracts must be closed exclusively through our platform.
                                            </p>
                                            <p>We want to emphasize that we do not offer advisory services. Our services are provided on an execution-only basis, which means we do not provide investment or financial advice for any product. While we may offer factual information, market commentary, transaction procedure guidance, or risk management methods, you should not interpret these as advice. Ultimately, you bear sole responsibility for deciding whether to engage in transactions with us. Additionally, it is your responsibility to manage your tax, regulatory, and legal matters, including any required filings, payments, or returns.
                                            </p>
                                            <p>Please be aware that we do not offer any tax, legal, or regulatory advice. If you need assistance with tax, legal, or regulatory issues related to our products, we strongly advise seeking independent professional advice.
                                            </p>
                                            <p>By engaging in trading Digital Assets on {title}, you acknowledge and accept the associated risks. When you participate in any transaction on our platform, you assert that you have, currently do, and will continue to take full responsibility for conducting your own independent assessment and research of the risks involved in such transactions and the underlying Digital Asset. Moreover, you affirm that you possess adequate knowledge, market expertise, professional advice, and experience to form your own judgment regarding the merits and risks of any transaction executed on {title} or related to any underlying Digital Asset.

                                            </p>
                                            <p>Please be aware that {title} is not acting as your broker, intermediary, agent, or advisor, and we do not hold any fiduciary relationship or obligation to you concerning any trades, decisions, or activities carried out by you using our platform's services. The responsibility for your actions and decisions lies solely with you.
                                            </p>
                                            <h3>Our products may not be suitable for everyone. </h3>
                                            <p>Prior to opening an account, we are obligated to evaluate whether our products align with your specific needs and circumstances. In some cases, we may decline to open an account for you if we deem our services to be inappropriate for your situation. Generally, our services may not be well-suited for individuals who:
                                            </p>
                                            <ul>

                                                <li>Lack comprehension of the impact and risks associated with trading on margin and in derivative products.</li>
                                                <li>Have a low tolerance for high-risk ventures.
                                                </li>
                                                <li>Cannot afford to bear potential losses from their trading activities.
                                                </li>
                                                <li>Do not possess a diversified investment portfolio or savings.
                                                </li>
                                            </ul>
                                            <p>If we find that our services may not be appropriate for you, we will provide a warning to that effect. In such instances, you may still be allowed to open an account, but it would be done at your own risk, just like all our other customers.

                                            </p>
                                            <h3>Pricing:
                                            </h3>
                                            <p>Our product pricing is based on a straightforward policy, calculated in basis points of the currency value traded. You can find detailed information about these charges on our website. Please note that these charges may occasionally be updated, so we recommend checking our website regularly for any changes.
                                            </p>
                                            <h3>Past Performance Disclaimer:
                                            </h3>
                                            <p>It's important to understand that past performance is not indicative of future performance. The value of investments can fluctuate, and they may both decrease and increase over time.</p>
                                            <p>Markets are subject to volatility, and prices can experience rapid fluctuations. In the financial world, including these specific products, volatility is a common occurrence, especially in response to news events. These price movements directly influence your potential profits and losses. Understanding the volatility of the underlying market can be instrumental in determining where to place stop-loss orders. However, it's essential to recognize that volatility can be challenging to predict and may arise unexpectedly.
                                            </p>
                                            <h3>Market Liquidity:
                                            </h3>
                                            <p>The value of Digital Assets relies on the willingness of market participants to trade these assets for fiat currency or other Digital Assets. If the market for a specific Digital Asset were to vanish, it could lead to a complete and irreversible loss of value for that particular asset.

                                            </p>
                                            <p>Market conditions are subject to rapid and significant changes. In certain trading scenarios, it might become challenging or even impossible to exit a position swiftly. This could occur during times of abrupt price movements, such as a sharp rise or fall within a single trading session, or when trading is halted or restricted.
                                            </p>
                                            <p>It's important to note that abnormal conditions in our futures market, such as a wide disparity between bids and offers, can result in more frequent liquidation events or larger losses upon liquidation. Liquidation is an automatic process, determined by the depth of the order book. In some instances, unexpected widening of bids and offers or a loss of liquidity on the order book could trigger auto-liquidations, even if the underlying market has not experienced significant price fluctuations.
                                            </p>
                                            <p>We have implemented a sanity check system that references our own spot exchange and external sources to prevent excessively unreasonable liquidations and erroneous trades. However, it's essential to understand that liquidations can still occur within a certain price band. Currently set at 10% above or below the referenced spot prices, this price band may vary considerably depending on market conditions, especially during periods of excessive volatility.
                                            </p>
                                            <h3>Monitoring Open Positions:
                                            </h3>
                                            <p>Due to the rapid movements in markets, it is essential to closely monitor your open positions. You bear the responsibility of consistently overseeing all your open positions. It is advisable to maintain quick and easy access to your accounts while any position remains open.
                                            </p>
                                            <h3>Tax Obligations:
                                            </h3>
                                            <p>You are accountable for all taxes, fees, and assessments related to any transaction. Please note that we do not provide tax advice. If you have any uncertainties about your tax matters, it is advisable to seek independent professional advice.
                                            </p>
                                            <h3>Blacklisted Addresses and Forfeited Assets:
                                            </h3>
                                            <p>{title} reserves the right to "blacklist" specific addresses and freeze associated assets (either temporarily or permanently) that we, at our sole discretion, determine to be linked to illegal activities or actions that violate the Terms and Conditions ("Blacklisted Addresses"). In the event that you send assets to or receive assets from a Blacklisted Address, {title} may freeze those Assets and take necessary measures, including the possibility of terminating your account.
                                            </p>
                                            <p>Under certain circumstances, {title} may find it necessary to report suspected illegal activities to relevant law enforcement agencies and regulatory authorities. If such suspicion arises, you may lose any rights associated with your assets, including the ability to redeem or exchange your Digital Assets for other Digital Assets or fiat currency. Additionally, assets held in your account may be frozen if we receive a corresponding order or request from a legal or regulatory authority.
                                            </p>

                                        </div>

                                    </Col>


                                </Row>
                            </div>
                        </TabPanel>


                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default Policies;
