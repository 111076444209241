import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import btc from "../../Assets/Image/New_Home/btc.png";
import bnb from "../../Assets/Image/New_Home/bnb-bnb-logo.png";
import ada from "../../Assets/Image/market_tableimg.png";
import { title, logo, shortTitle } from "../../config/config";
import { Link } from "react-router-dom";
import short__trade from "../../Assets/Img__v2/slider_logo.png"

const Communityicons = () => {

  const auth = localStorage.getItem("auth");

  const SetButtonHome2 = () => {
    if (auth !== "true") {
      return (
        <>
          <Link to="/sign-up" className="dark_btn">Sign Up</Link>
        </>
      )
    }
    else {
      return (
        <>
          <Link to="/dashboard" className="dark_btn">Dashboard</Link>
        </>
      )
    }
  }

  return (
    <div className="all_part">
      <div className="coustom_container main_iconcard">

        <div className="row card_box pt-2">
          <div className="col-md-5">
            <div className="heading-main">
              <h1>What are the reasons for investing in cryptocurrency assets?</h1>
              <p>Investing in cryptocurrency assets offers an alternative avenue compared to conventional investment choices. As the crypto market continues to gain traction and expand, opting for crypto investments could prove to be a strategic decision for individuals seeking to broaden their investment portfolio.</p>
            </div>
          </div>

          <div className="col-md-7">
            <div className="class__1 d-flex align-items-center justify-content-end">
              <div className="w-100">
                <Card className="card_item">
                  <span className="left__top__border"></span>
                  <span className="right__top__border"></span>
                  <div className="pro_sec">
                    <div className="d-flex align-items-center car__card__inner">
                      <img src={btc} className="card_img me-2" />
                      <div className="info">
                        <h4>Bitcoin (BTC)</h4>
                        <p>BTC/USD</p>

                      </div>
                    </div>
                    <img className="bar" src="" alt="" />

                  </div>

                </Card>
                <Card className="card_item another_card">
                  <span className="left__top__border"></span>
                  <span className="right__top__border"></span>
                  <div className="pro_sec">
                    <div className="d-flex align-items-center car__card__inner">
                      <img src={ada} className="card_img me-2" />
                      <div className="info">
                        <h4>Tron (TRX)</h4>
                        <p>TRX/USD</p>

                      </div>
                    </div>

                  </div>

                </Card>

                <Card className="card_item mt-5">
                  <span className="left__top__border"></span>
                  <span className="right__top__border"></span>
                  <div className="pro_sec">
                    <div className="d-flex align-items-center car__card__inner">
                      <img src={bnb} className="card_img me-2" />
                      <div className="info">
                        <h4>Binance Coin (BNB)</h4>
                        <p>BNB/USD</p>

                      </div>
                    </div>


                  </div>

                </Card>

              </div>

              <Card className="card_item another_card1">
                <span className="left__top__border"></span>
                <span className="right__top__border"></span>
                <div className="pro_sec">
                  <div className="d-flex align-items-center car__card__inner">
                    <img src={short__trade} className="card_img me-2" />
                    <div className="info">
                      <h4>{title} ({shortTitle})</h4>
                      <p>{shortTitle}/USD</p>

                    </div>
                  </div>

                </div>

              </Card>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
};

export default Communityicons;
