import React, { useEffect, useState } from "react";
import { ShortName, shortTitle, title } from "../../config/config";
import Launchpad__img from "../../Assets/Img__v2/slider_logo.png";
import { Col, Row } from "react-bootstrap";
import launchpadpng from "../../Assets/Img__v2/launchpadpng.png";

const Launchpad = () => {

  const text = "Secure Prime Investments.";

  const [content, setContent] = useState('');
  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentLength < text.length) {
        const newText = text.slice(0, currentLength + 1);
        setContent(newText);
        setCurrentLength(currentLength + 1);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval time for the typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentLength]);



  return (
    <>
      <div className="Launchpad__sec only-margin">
        <div className="coustom_container for__bg">
          <Row className="img_banner_launch">
            <Col>
              <div className="top-sec">
                <h4>{title} LAUNCHPAD</h4>
                <h1 className="sentence">{content}</h1>
                <p>At the {title} Launchpad, we carefully select the most promising projects for you. Acquire tokens during their initial stages using {shortTitle}. Experience innovation through {shortTitle} Launchpad at {title}. Explore handpicked projects, invest early using {shortTitle} tokens, and be part of groundbreaking advancements. Don't miss your chance to shape the future. Join us now!
                </p>
              </div>
            </Col>
            <Col>
            <div className="banner_img">
              <img src={launchpadpng} alt=""/>
            </div>
            </Col>
          </Row>

        </div>

        <div className="more__option_lan">
          <div className="Launchpad__contain coustom_container">
            <div className="Launchpad__option_header">
              <h1> Past Projects {shortTitle}</h1>
            </div>

            <div className="option___data">

              <div className="row for_border">
                <span className='left__top__border'></span>
                <span className='right__top__border'></span>
                <span className='left__bottom__border'></span>
                <span className='right__bottom__border'></span>


                <div className="col-md-3 p-0">
                  <div className="New___img__div d-flex justify-content-center align-items-center">
                    <img src={Launchpad__img} alt="img/icon" />
                  </div>
                </div>

                <div className="col-md-8 p-0">
                  <div className="launchpad_text_sec">
                    <h3> {ShortName} </h3>
                    <p className="pro__diss"> Feel The Power Of {ShortName} Token </p>

                    <div className="pro___data">
                      <div className="data-section">
                        <div className="data_item d-flex justify-content-center align-items-center">
                          <h5>{shortTitle} TOKEN</h5>
                          {/* <h4>150,000 REVO</h4> */}
                        </div>

                        <div className="data_item">
                          <h5>TOTAL SUPPLY</h5>
                          <h4>210,000,000 <b>{ShortName} </b> </h4>
                        </div>
                      </div>
                      <div className="data-section">
                        <div className="data_item">
                          <h5>HOLDER</h5>
                          <h4>169,559 addresses</h4>
                        </div>
                        <div className="data_item">
                          <h5> LIVE PRICE SALE</h5>
                          <h4>$0.48  = 1 {shortTitle}</h4>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Launchpad;
