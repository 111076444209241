import React from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardArrowRight } from "@mui/icons-material";


const GettingApiKey = () => {



  const toggleMenuBar = () => {
    const ABC: HTMLElement | any = document.body;
    ABC.classList.toggle("Api__add__class");
  };

  return (
    <>
      <div className="main-sec-getting-api">
        <div className="inner-sec">
          {/* <div className="header__new">
            <div className="top h-100">
              <div className="d-flex justify-content-between align-items-center h-100 ps-2 pe-2">
              <p className="m-0 ms-3">API</p>

                <MenuIcon
                  onClick={() => {
                    toggleMenuBar();
                  }}
                />
              </div>
            </div>
          </div> */}

          <Tabs>
            <TabList>
              <Tab>Market Data</Tab>
              <Tab>Account</Tab>
              <div className="button">

                <button type="button" className="open-close_button" onClick={() => {  toggleMenuBar();  }}>
                  <KeyboardArrowRight/>
                </button>
              </div>
            </TabList>

            <TabPanel>
              <div className="min-tab-sec">

                <Row className="m-0 p-0 new_bg">
                  <Col md={6} className="left">


                    <div className="inner">
                      <h1>Account management</h1>
                      <div className="info">
                        <h3>Authentication</h3>
                        <p>Public market data are available without authentication. Authentication is required for other requests.</p>
                      </div>
                      <div className="info">
                        <h3>Parameters</h3>
                        <p>Follow the sample coding on the right. Before sending API requests, you must create a token code with the secretkey provided to you.The created token will be valid for 15 seconds.</p>
                      </div>
                      <div className="next_sec">
                        <div className="table__responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Field</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Api key</td>
                                <td>You can create it from the account page.</td>
                              </tr>
                              <tr>
                                <td>Secret key</td>
                                <td>You can create it from the account page.</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>


                    <div className="inner">
                      <h1>Account management</h1>
                      <div className="info">
                        <h3>Account balance</h3>
                        <p>Returns the user's account balance. Requires the "Payment information" API key Access Right.</p>
                      </div>
                      <div className="info">
                        <h3>Parameters</h3>
                        <p>The parameters should be passed in the query component of the URL of GET request.</p>
                      </div>
                      <div className="next_sec">
                        <div className="table__responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Field</th>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Api key</td>
                                <td>string</td>
                                <td>You can create it from the account page.</td>
                              </tr>
                              <tr>
                                <td>token</td>
                                <td>string</td>
                                <td>	Token created with Secret Key.</td>
                              </tr>
                              <tr>
                                <td>symbol</td>
                                <td>string</td>
                                <td>Unique identifier of the currency, e.g. "BTC"</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </Col>
                  <Col md={6} className="right">
                    <div className="inner">
                      <div className="codding">
                        <code>
                          <p className="pb-5">GET <span className="green">/api/account/...</span><br />
                            Check out the example below to create API tokens.<br />
                            <span className="yellow">$skey =</span><span className="green"> "Api Secret Key"</span><span className="yellow">;</span><br />
                            <span className="yellow">$encMethod =</span><span className="green"> "AES-256-CFB"</span><span className="yellow">;</span><br />
                            <span className="yellow">$tkey =</span><span className="red"> time().$skey</span><span className="yellow">;</span><br />
                            <span className="yellow">$token=</span><span className="red"> openssl_encrypt($skey, $encMethod, $tkey)</span><span className="yellow">;</span><br />
                          </p>
                        </code>


                        <code>
                          <p className="pb-5"> GET  <span className="green"> /api/account/userBalance?apiKey=$apiKey&token=$token&symbol=BTC</span><br />
                            The above request returns JSON structured like this:<br />

                            {"{"}<span className="green">"0.00000000"</span>{"}"}
                          </p>
                        </code>

                        <code>
                          <p className="pb-5"> GET <span className="green"> /api/account/userOrder?apiKey=$apiKey&token=$token</span><br />
                            The above request returns JSON structured like this:<br />
                            {`[`}<br />
                            {`{`}<br />
                            <span className="yellow">"orderID":</span><span className="green"> "order ID"</span><span className="yellow">,</span><br />
                            <span className="yellow">"price":</span><span className="red"> 0.0000005</span><span className="yellow">,</span><br />
                            <span className="yellow">"amount":</span><span className="red"> 8.6957</span><span className="yellow">,</span><br />
                            <span className="yellow">"type":</span><span className="red"> sell</span><span className="yellow">,</span><br />
                            <span className="yellow">"symbol":</span><span className="red"> ETH_BTC</span><span className="yellow">,</span><br />
                            <span className="yellow">"time":</span><span className="red"> 162587412565</span><span className="yellow">,</span><br />
                            {`},`}<br />
                            ...<br />
                            {`]`}
                          </p>
                          <p> GET <span className="green"> /api/account/userOrder?apiKey=$apiKey&token=$token&symbol=LTC_BTC</span><br />
                            The above request returns JSON structured like this:<br />
                            {`[`}<br />
                            {`{`}<br />
                            <span className="yellow">"orderID":</span><span className="green"> "order ID"</span><span className="yellow">,</span><br />
                            <span className="yellow">"price":</span><span className="red"> 0.0000005</span><span className="yellow">,</span><br />
                            <span className="yellow">"amount":</span><span className="red"> 8.6957</span><span className="yellow">,</span><br />
                            <span className="yellow">"type":</span><span className="red"> sell</span><span className="yellow">,</span><br />
                            <span className="yellow">"symbol":</span><span className="red"> LTC_BTC</span><span className="yellow">,</span><br />
                            <span className="yellow">"time":</span><span className="red"> 162587412565</span><span className="yellow">,</span><br />
                            {`},`}<br />
                            ...<br />
                            {`]`}
                          </p>
                        </code>


                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="min-tab-sec">

                <Row className="m-0 p-0 new_bg">
                  <Col md={6} className="left">


                    <div className="inner">
                      <h1>Account management</h1>
                      <div className="info">
                        <h3>Authentication</h3>
                        <p>Public market data are available without authentication. Authentication is required for other requests.</p>
                      </div>
                      <div className="info">
                        <h3>Parameters</h3>
                        <p>Follow the sample coding on the right. Before sending API requests, you must create a token code with the secretkey provided to you.The created token will be valid for 15 seconds.</p>
                      </div>
                      <div className="next_sec">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Field</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Api key</td>
                              <td>You can create it from the account page.</td>
                            </tr>
                            <tr>
                              <td>Secret key</td>
                              <td>You can create it from the account page.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div className="inner">
                      <h1>Account management</h1>
                      <div className="info">
                        <h3>Account balance</h3>
                        <p>Returns the user's account balance. Requires the "Payment information" API key Access Right.</p>
                      </div>
                      <div className="info">
                        <h3>Parameters</h3>
                        <p>The parameters should be passed in the query component of the URL of GET request.</p>
                      </div>
                      <div className="next_sec">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Field</th>
                              <th scope="col">Type</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Api key</td>
                              <td>string</td>
                              <td>You can create it from the account page.</td>
                            </tr>
                            <tr>
                              <td>token</td>
                              <td>string</td>
                              <td>	Token created with Secret Key.</td>
                            </tr>
                            <tr>
                              <td>symbol</td>
                              <td>string</td>
                              <td>Unique identifier of the currency, e.g. "BTC"</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </Col>
                  <Col md={6} className="right">
                    <div className="inner">
                      <div className="codding">
                        <code>
                          <p className="pb-5">GET <span className="green">/api/account/...</span><br />
                            Check out the example below to create API tokens.<br />
                            <span className="yellow">$skey =</span><span className="green"> "Api Secret Key"</span><span className="yellow">;</span><br />
                            <span className="yellow">$encMethod =</span><span className="green"> "AES-256-CFB"</span><span className="yellow">;</span><br />
                            <span className="yellow">$tkey =</span><span className="red"> time().$skey</span><span className="yellow">;</span><br />
                            <span className="yellow">$token=</span><span className="red"> openssl_encrypt($skey, $encMethod, $tkey)</span><span className="yellow">;</span><br />
                          </p>
                        </code>


                        <code>
                          <p className="pb-5"> GET  <span className="green"> /api/account/userBalance?apiKey=$apiKey&token=$token&symbol=BTC</span><br />
                            The above request returns JSON structured like this:<br />

                            {"{"}<span className="green">"0.00000000"</span>{"}"}
                          </p>
                        </code>

                        <code>
                          <p className="pb-5"> GET <span className="green"> /api/account/userOrder?apiKey=$apiKey&token=$token</span><br />
                            The above request returns JSON structured like this:<br />
                            {`[`}<br />
                            {`{`}<br />
                            <span className="yellow">"orderID":</span><span className="green"> "order ID"</span><span className="yellow">,</span><br />
                            <span className="yellow">"price":</span><span className="red"> 0.0000005</span><span className="yellow">,</span><br />
                            <span className="yellow">"amount":</span><span className="red"> 8.6957</span><span className="yellow">,</span><br />
                            <span className="yellow">"type":</span><span className="red"> sell</span><span className="yellow">,</span><br />
                            <span className="yellow">"symbol":</span><span className="red"> ETH_BTC</span><span className="yellow">,</span><br />
                            <span className="yellow">"time":</span><span className="red"> 162587412565</span><span className="yellow">,</span><br />
                            {`},`}<br />
                            ...<br />
                            {`]`}
                          </p>
                          <p> GET <span className="green"> /api/account/userOrder?apiKey=$apiKey&token=$token&symbol=LTC_BTC</span><br />
                            The above request returns JSON structured like this:<br />
                            {`[`}<br />
                            {`{`}<br />
                            <span className="yellow">"orderID":</span><span className="green"> "order ID"</span><span className="yellow">,</span><br />
                            <span className="yellow">"price":</span><span className="red"> 0.0000005</span><span className="yellow">,</span><br />
                            <span className="yellow">"amount":</span><span className="red"> 8.6957</span><span className="yellow">,</span><br />
                            <span className="yellow">"type":</span><span className="red"> sell</span><span className="yellow">,</span><br />
                            <span className="yellow">"symbol":</span><span className="red"> LTC_BTC</span><span className="yellow">,</span><br />
                            <span className="yellow">"time":</span><span className="red"> 162587412565</span><span className="yellow">,</span><br />
                            {`},`}<br />
                            ...<br />
                            {`]`}
                          </p>
                        </code>


                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
            </TabPanel>

          </Tabs>
        </div>
      </div>
    </>
  );
};

export default GettingApiKey;
