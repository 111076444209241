import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import off from "../../Assets/Img__v2/off.png";
import { WorkspacePremium } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { title } from "../../config/config";
import { Col, Row } from "react-bootstrap";
import bonus from "../../Assets/Img__v2/bonus_banner.png";

const Bonus = () => {

    const text = "Welcome to Reward Center";

    const [content, setContent] = useState('');
    const [currentLength, setCurrentLength] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLength < text.length) {
                const newText = text.slice(0, currentLength + 1);
                setContent(newText);
                setCurrentLength(currentLength + 1);
            } else {
                clearInterval(interval);
            }
        }, 50); // Adjust the interval time for the typing speed

        return () => {
            clearInterval(interval);
        };
    }, [currentLength]);

    return (
        <>
            <div className="main-bonus">
                <div className="coustom_container for__bg">
                    <Row className="bonus_banner">
                        <Col sm={12} md={6}>
                            <div className="top-sec">
                                <h4>BONUS</h4>
                                <h1 className="sentence">{content}</h1>
                                <p>Discover the {title} bonus experience. Elevate your trading adventure from the start with our enticing bonus program. Trade confidently, leveraging extra funds. Unleash your potential with our platform's benefits. Embrace the future of trading with {title}.io. Join us now!
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="bonus_ban_img">
                                <img src={bonus} alt="bonus" />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="sign-up-sec">
                    <div className="coustom_container">
                        <div className="inner-sec">
                            <span className='left__top__border'></span>
                            <span className='right__top__border'></span>
                            <span className='left__bottom__border'></span>
                            <span className='right__bottom__border'></span>
                            <div className="sl">
                                <img src={off} alt="disc" />
                            </div>
                            <div className="sr">
                                <h3>Sign up to get a Welcome Bonus up to $100</h3>
                                <p>Create an account here to enjoy a 10% rebate on your trading fee and massive rewards.</p>
                                <Link to="/sign-up"><div className="btn2">
                                    <span className='left__top__border'></span>
                                    <span className='right__top__border'></span>
                                    <span className='left__bottom__border'></span>
                                    <span className='right__bottom__border'></span>
                                    Sign Up</div></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tab-sec">
                    <div className="coustom_container">
                        <div className="tab_bonus">
                            <Tabs>
                                <TabList>
                                    <Tab>Beginner's Tasks</Tab>
                                    <Tab>Advanced Tasks</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="top-sec">
                                        <div className="icn">
                                            <WorkspacePremium />
                                        </div>
                                        <div className="head">
                                            <h3>Trade</h3>
                                        </div>
                                    </div>
                                    <div className="btm-sec">

                                        <div className="sec-l">
                                            <span className='left__top__border'></span>
                                            <span className='right__top__border'></span>
                                            <span className='left__bottom__border'></span>
                                            <span className='right__bottom__border'></span>
                                            <span className="top-txt">100 seats offered</span>
                                            <div className="head-sec2">
                                                <div className="head2">
                                                    <div className="inner1">
                                                        <div className="inner2">
                                                            <span className="txt-data">
                                                                <small>$</small>
                                                                <strong>2</strong>
                                                            </span>
                                                        </div>
                                                        <span className="small-txt"> Futures Trial Fund </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="body-txt">
                                                <h3> Futures account first time trading...</h3>
                                                <p>- It has to be your first trade of futures contracts.</p>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-clr"></div>
                                                <div className="txt">0 / 100</div>
                                            </div>
                                            <div className="exp-sec">
                                                <div className="timer">
                                                    <div className="exp-txt"><p>Expiration</p></div>
                                                    <ul>
                                                        <li className="day">0</li>
                                                        <span className="colon">-</span>
                                                        <li className="hour">15</li>
                                                        <span className="colon">:</span>
                                                        <li className="minute">08</li>
                                                        <span className="colon">:</span>
                                                        <li className="second">24</li>

                                                    </ul>
                                                </div>

                                                <Link to="">
                                                    Trade Now
                                                </Link>

                                            </div>
                                        </div>
                                        <div className="sec-l">
                                            <span className='left__top__border'></span>
                                            <span className='right__top__border'></span>
                                            <span className='left__bottom__border'></span>
                                            <span className='right__bottom__border'></span>
                                            <span className="top-txt">100 seats offered</span>
                                            <div className="head-sec2">
                                                <div className="head2">
                                                    <div className="inner1">
                                                        <div className="inner2">
                                                            <span className="txt-data">
                                                                <small>$</small>
                                                                <strong>2</strong>
                                                            </span>
                                                        </div>
                                                        <span className="small-txt"> Futures Trial Fund </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="body-txt">
                                                <h3> Futures account first time trading...</h3>
                                                <p>- It has to be your first trade of futures contracts.</p>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-clr"></div>
                                                <div className="txt">0 / 100</div>
                                            </div>
                                            <div className="exp-sec">
                                                <div className="timer">
                                                    <div className="exp-txt"><p>Expiration</p></div>
                                                    <ul>
                                                        <li className="day">0</li>
                                                        <span className="colon">-</span>
                                                        <li className="hour">15</li>
                                                        <span className="colon">:</span>
                                                        <li className="minute">08</li>
                                                        <span className="colon">:</span>
                                                        <li className="second">24</li>

                                                    </ul>
                                                </div>

                                                <Link to="">
                                                    Trade Now
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="top-sec">
                                        <div className="icn">
                                            <WorkspacePremium />
                                        </div>
                                        <div className="head">
                                            <h3>Trade</h3>
                                        </div>
                                    </div>
                                    <div className="btm-sec">
                                        <div className="sec-l">
                                            <span className='left__top__border'></span>
                                            <span className='right__top__border'></span>
                                            <span className='left__bottom__border'></span>
                                            <span className='right__bottom__border'></span>
                                            <span className="top-txt">100 seats offered</span>
                                            <div className="head-sec2">
                                                <div className="head2">
                                                    <div className="inner1">
                                                        <div className="inner2">
                                                            <span className="txt-data">
                                                                <small>$</small>
                                                                <strong>5</strong>
                                                            </span>
                                                        </div>
                                                        <span className="small-txt"> Futures Trial Fund </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="body-txt">
                                                <h3> Futures account first time trading...</h3>
                                                <p>- It has to be your first trade of futures contracts.</p>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-clr"></div>
                                                <div className="txt">0 / 100</div>
                                            </div>
                                            <div className="exp-sec">
                                                <div className="timer">
                                                    <div className="exp-txt"><p>Expiration</p></div>
                                                    <ul>
                                                        <li className="day">0</li>
                                                        <span className="colon">-</span>
                                                        <li className="hour">15</li>
                                                        <span className="colon">:</span>
                                                        <li className="minute">08</li>
                                                        <span className="colon">:</span>
                                                        <li className="second">24</li>

                                                    </ul>
                                                </div>

                                                <Link to="">
                                                    Trade Now
                                                </Link>

                                            </div>
                                        </div>
                                        <div className="sec-l">
                                            <span className='left__top__border'></span>
                                            <span className='right__top__border'></span>
                                            <span className='left__bottom__border'></span>
                                            <span className='right__bottom__border'></span>
                                            <span className="top-txt">1000 seats offered</span>
                                            <div className="head-sec2">
                                                <div className="head2">
                                                    <div className="inner1">
                                                        <div className="inner2">
                                                            <span className="txt-data">
                                                                <small>$</small>
                                                                <strong>5</strong>
                                                            </span>
                                                        </div>
                                                        <span className="small-txt"> Futures Trial Fund </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="body-txt">
                                                <h3> Futures account first time trading...</h3>
                                                <p>- It has to be your first trade of futures contracts.</p>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-clr"></div>
                                                <div className="txt">0 / 1000</div>
                                            </div>
                                            <div className="exp-sec">
                                                <div className="timer">
                                                    <div className="exp-txt"><p>Expiration</p></div>
                                                    <ul>
                                                        <li className="day">0</li>
                                                        <span className="colon">-</span>
                                                        <li className="hour">15</li>
                                                        <span className="colon">:</span>
                                                        <li className="minute">08</li>
                                                        <span className="colon">:</span>
                                                        <li className="second">24</li>

                                                    </ul>
                                                </div>

                                                <Link to="">
                                                    Trade Now
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>

                    </div>
                </div>

                <div className="qa-sec">

                    <div className="coustom_container">

                        <div className="head-sec-qa">
                            <h2>Q&A</h2>
                            <p>Frequently asked questions about the {title} Reward Center are as follows:</p>
                        </div>

                        <div className="body-sec">
                            <div className="ques">
                                <div className="q1">
                                    <span className="number">1.</span>
                                    <span>How can I see the prizes I've earned?</span>
                                </div>
                                <span>A: You can check your task rewards at the [Reward Center - Coupon Center]</span>
                            </div>

                            <div className="ques">
                                <span className="q1"><span className="number">2.</span> How should I redeem my Spot Coupons?</span>
                                <span>A: You can utilize the spot coupons to cover the costs associated with trading tokens and margin transactions. The coupon will be refunded in the order of "first deducted, then refunded" after the transaction charge has been subtracted.</span>
                            </div>

                            <div className="ques">
                                <span className="q1"><span className="number">3.</span> How should I use the margin coupon?</span>
                                <span>A: If "Auto-Borrow and Repay" is enabled for margin trading, you can use margin coupons to defray the interest on borrowed cryptos, with the interest being reimbursed right to your account.</span>
                            </div>

                            <div className="ques">
                                <span className="q1"><span className="number">4.</span> How do the Futures Trial Fund's operations work?</span>
                                <span>A: The Futures Trial Fund Voucher's goal is to give novice users a risk-free, real-world trading experience with {title} futures products. The trial fund will be sent to your futures account and available for futures trading after the voucher has been successfully redeemed and activated.</span>
                            </div>

                            <div className="ques">
                                <span className="q1"><span className="number">5.</span> How can I utilize the Futures Coupons?</span>
                                <span>A: The futures coupon offsets trading futures contract costs. It's refunded as a "first deducted, then refunded" fee when the transaction fee is taken. You can see your credit on the "Wallet-Futures" or futures trading page, and your futures account will show the "actual amount available".</span>
                            </div>

                            <div className="ques">
                                <span className="q1"><span className="number">6.</span> How to use Polo Earn Coupons?</span>
                                <span>A: Polo Earn coupons offer added benefits to users when subscribing to particular Polo Earn products. For conditions and details, please check the coupon description.</span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="rules-sec">

                    <div className="coustom_container">

                        <div className="head-sec-qa">
                            <h2>Rules</h2>
                            <p>The rules of {title} are as follows:</p>
                        </div>

                        <div className="body-sec">
                            <div className="ques d-flex">
                                <span className="number">1.</span>
                                <span>10% Handling Fee Rebate: Users who register with the invitation code receive a 10% rebate on Spot, Margin, and Futures transactions. This offer remains valid for 60 days from {title} account registration and will be credited to the spot account. Rebate records can be viewed in [Activity-Wallet-Other], calculated as transaction rebate reward = net handling fee * rebate ratio.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">2.</span>
                                <span> Deposit Challenge: Fulfill the task requirements by making the specified amount of deposits (limited to on-chain deposits) to earn deposit rewards. View your rewards in the [Reward Center - Coupon Center].</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">3.</span>
                                <span>  Spot trading task: To qualify for spot trading rewards, complete the assigned spot trading tasks, excluding spot coupon transactions, Maker trades, and trading pairs with zero transaction fees.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">4.</span>
                                <span>Futures Challenge: Register and open a futures account to receive the trial fund or coupon reward. Note that the transaction amount offset by using the futures trial fund will not be included in the futures trading task count.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">5.</span>
                                <span> The opening time and validity period of each task may vary, and rewards for trial fund and coupon are limited on a first-come-first-served basis.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">6.</span>
                                <span> Remember to collect each task in the reward center before completion to ensure effective counting by the system. For tasks like "cumulative futures account transfer" or "cumulative spot/margin/futures trading volume," there might be a slight delay in displaying the progress of task completion. The task of "cumulative active futures trading" may experience a longer display delay.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">7.</span>
                                <span> {title} retains the authority to withdraw trial funds or coupons claimed by users engaged in cheating, suspected cheating, or violating any Poloniex rules and regulations. Fraudulent attempts to obtain trial funds or coupons may lead to account suspension.</span>
                            </div>

                            <div className="ques d-flex">
                                <span className="number">8.</span>
                                <span>{title} reserves the right to make final decisions and interpretations regarding event rules, and may modify event rules or rewards without prior notice.</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bonus;
