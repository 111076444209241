import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link, useParams } from 'react-router-dom';
import { api_url } from '../../config/config';
import _fetch from '../../config/api';
import toasted from '../../config/toast';

const BuySell = (props:any) => {


    const { id } = useParams()
    const [buyStopPriceAt, setbuyStopPriceAt] = useState(0);
    const [coinCurrentPrice, setcoinCurrentPrice] = useState(0);
    const [amount, setamount] = useState(0);
    const [usdAmount, setusdAmount] = useState(0);
    const [currentId, setCurrentId] = useState('')
    const [btnDisable, setbtnDisable] = useState(false);
    const [SellbtnDisable, setSellbtnDisable] = useState(false);

    async function BuyCoin(e: any) {

        setbtnDisable(true)
        e.preventDefault();
        const limit = {
            buy_coin_id: currentId,
            amount: amount,
            buyCurrency: id,
            limit_price: buyStopPriceAt,
            trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
            // chain: 'bep20'
            symbol: id?.toLowerCase()

        }

        let d = await _fetch(`${api_url}trade/buy-trade`, 'POST', limit, {});
        if (d?.status === 'success') {
            toasted.success(d?.message);
            // CoinInfo();
            props.setLoadApi(props.LoadApi === 0 ? 1 :0)
            setbtnDisable(false);
        } else {
            toasted.error(d?.message);
            setbtnDisable(false);
        }
    }
    // ======= SELL Functions ======== //

    async function SellCoin(e: any) {
        setSellbtnDisable(true);

        e.preventDefault();
        const limit = {
            amount: amount,
            sellCurrency: id?.toLowerCase(),
            sell_coin_id: currentId,
            limit_price: buyStopPriceAt,
            trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
            symbol: id?.toLowerCase()
            // chain: 'bep20'
        }

        let sell = await _fetch(`${api_url}trade/sell-trade`, 'POST', limit, {});
        if (sell?.status === 'success') {
            toasted.success(sell?.message);
            props.setLoadApi(props.LoadApi === 0 ? 1 :0)
            // CoinInfo()
            // SaleCoinInfo()
            // onCloseSellModal()
            // onCloseSellStopModal();
            setSellbtnDisable(false);

        } else {
            toasted.error(sell?.message);
            setSellbtnDisable(false);

        }
    }

    // const [oderCurrentPrice, setOrderCurrentPrice] = useState()
    // const [currentId, setCurrentId] = useState('')
    const [USDTBallanceShow, setUSDTBallanceShow] = useState(0)
    const [coinBallanceShow, setCoinBallanceShow] = useState(0)

    async function CoinInfo() {
        var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=trade_account&symbol=${id?.toLowerCase()}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setcoinCurrentPrice(d?.market[0].current_price);
            // props.settradeCoinPrice(d?.market[0].current_price)
            // setCoinBallanceShow(d?.market[0].usdt_income);
            let f = Number(d?.market[0].total_coin).toFixed(3)
            setCoinBallanceShow(parseFloat(f));

            setCurrentId(d?.market[0].coin_id);
            // setOrderCurrentPrice(d?.market[0].current_price)
        }
    }
    const onCurrentPrice = (e: any) => {
        const float = Number(parseFloat(e.target.value).toFixed(3));
        setcoinCurrentPrice(float);
        if (amount > 0) {
            const main2 = Number(amount * float).toFixed(3);
            setusdAmount(parseFloat(main2));
        }
    }



    const buyPercentage = (buyPercentage: any) => {
        const float = Number(parseFloat(buyPercentage).toFixed(3));
        const totalUSDPercentage = (Number(coinBallanceShow) * float / 100).toFixed(3);
        const main2 = Number(parseFloat(totalUSDPercentage) / coinCurrentPrice).toFixed(3);

        setamount(parseFloat(totalUSDPercentage));
        setusdAmount(parseFloat(totalUSDPercentage));
    }

    const sellPercentage = (buyPercentage: any) => {
        const float = Number(parseFloat(buyPercentage).toFixed(3));
        const totalUSDPercentage = ((Number(USDTBallanceShow) / 100) * float).toFixed(3);
        const main2 = Number(parseFloat(totalUSDPercentage) * coinCurrentPrice).toFixed(3);
        setamount(parseFloat(totalUSDPercentage));
        setusdAmount(parseFloat(main2));
    }

    const onBlur = (e: any) => {
        const float = Number(parseFloat(e.target.value).toFixed(3));
        setamount(float)
        const main2 = Number(float * coinCurrentPrice).toFixed(3);
        setusdAmount(parseFloat(main2));
    }
    const onBlur2 = (e: any) => {
        const float = Number(parseFloat(e.target.value).toFixed(3));
        const main2 = Number(e.target.value / coinCurrentPrice).toFixed(3);
        setamount(parseFloat(main2));
        setusdAmount(float);
    }
    const onBlur3 = (e: any) => {
        const float = Number(parseFloat(e.target.value).toFixed(3));
        setbuyStopPriceAt(float);
    }
    async function SaleCoinInfo() {
        var d = await _fetch(`${api_url}markets/getElpSingleMarketByType?type=trade_account&symbol=usdt`, 'GET', {}, {});
        if (d?.status === 'success') {
            // setSaleId(d?.market[0].coin_id);
            // setUSDTBallanceShow(d?.market[0].usdt_income);
            let f = Number(d?.market[0].usdt_income).toFixed(3)
            setUSDTBallanceShow(parseFloat(f));
            // setSaleSymbol(d?.market[0].symbol);
        }
    }




    useEffect(() => {
        CoinInfo()
        SaleCoinInfo()
    }, [])



    let [buymarketTab, setbuymarketTab] = useState('');
    let [buyStopLimitTab, setbuyStopLimitTab] = useState('');

    let [activeInnerTab, setactiveInnerTab] = useState('market');
    // let [activeTabLimit, setactiveTabLimit] = useState('stop-limit');


    let tradingTab = (tab: string) => {
        // tab.currentTarget.classList.add("active");
        if (window.screen.width! <= 1200) {
            // window.location.reload();
            setactiveInnerTab(tab)
            tab === 'market' ? setbuymarketTab('block') : setbuymarketTab('none');
            tab === 'stop-limit' ? setbuyStopLimitTab('block') : setbuyStopLimitTab('none');
        }
    };


    return (
        <>
            <div className='main-buysell'>
                <div className='inner'>
                    <div className='tabs-main'>
                        <Tabs>
                            <div className='header-but-sell'>
                                <TabList>
                                    <Tab>Market</Tab>
                                    <Tab className="sell react-tabs__tab" >Stop Limit</Tab>
                                </TabList>
                            </div>

                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>
                                        {/* <TabList>
                                            <Tab>Market</Tab>
                                            <Tab>Stop Limit</Tab>
                                        </TabList> */}

                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeInnerTab == 'market' ? "heading buy__btn" :"heading "}  onClick={() => tradingTab('market')} ><p>Buy Market</p></div>
                                            <div className={activeInnerTab == 'market' ? "heading" :"heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Market </p></div>
                                        </div>

                                        <TabPanel>
                                            <div className='main-market'>
                                                <div className="data_left" style={{ display: buymarketTab }}>
                                                    {/* <div className="heading only-for-lg"><p>Market</p></div> */}

                                                    <div className='' >
                                                        <div className='balance'>
                                                            <p>Avbl <span>{coinBallanceShow}</span>  USDT </p>
                                                        </div>

                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                                                            <span>USDT</span>
                                                        </div>

                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                                                            <input type='number' onChange={(e: any) => { onBlur(e) }} value={amount} />
                                                            <span>{id?.toLocaleUpperCase()} </span>
                                                        </div>

                                                        <div className='range'>
                                                            <div className='percentage'>
                                                                {/* <div className='items' onClick={() => buyPercentage(25)}>0%</div> */}
                                                                <div className='items' onClick={() => buyPercentage(25)}>25%</div>
                                                                <div className='items' onClick={() => buyPercentage(50)}>50%</div>
                                                                <div className='items' onClick={() => buyPercentage(75)}> 75%</div>
                                                                <div className='items' onClick={() => buyPercentage(100)}>100%</div>
                                                            </div>
                                                            <hr></hr>
                                                        </div>

                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                                                            <input type='number' value={usdAmount} onChange={onBlur2} />
                                                            <span>USDT</span>
                                                        </div>

                                                        <div className='buy-btn'>
                                                            <Link to="/login" className='btn btn-primary sign_up__button'> Login </Link>
                                                        </div>
                                                    </div>



                                                </div>


                                                {/* =======  */}

                                                <div className="data_left buy-hd" style={{ display: buyStopLimitTab }}>
                                                    {/* <div className="heading only-for-lg "><p className='loss__txt-color'>Market</p></div> */}
                                                    <div className='balance'>
                                                        <p className='loss__txt-color'>Avbl <span>{USDTBallanceShow}</span> {id?.toUpperCase()} </p>
                                                    </div>

                                                    <div className='input_price input-loss-red'>
                                                        <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                        <input type='number' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                                                        <span>USDT</span>
                                                    </div>

                                                    <div className='input_price input-loss-red'>
                                                        <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                                                        <input type='number' onChange={(e: any) => { onBlur(e) }} value={amount} />
                                                        <span>{id?.toUpperCase()}</span>
                                                    </div>

                                                    <div className='range'>
                                                        <div className='percentage'>
                                                            {/* <div className='items'>0%</div> */}
                                                            <div className='items loss_itms' onClick={() => { sellPercentage(25) }}>25%</div>
                                                            <div className='items loss_itms' onClick={() => { sellPercentage(50) }}>50%</div>
                                                            <div className='items loss_itms' onClick={() => { sellPercentage(75) }}>75%</div>
                                                            <div className='items loss_itms' onClick={() => { sellPercentage(100) }}>100%</div>

                                                        </div>
                                                        <hr></hr>
                                                    </div>

                                                    <div className='input_price input-loss-red'>
                                                        <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                                                        <input type='number' value={usdAmount} onChange={onBlur2} />
                                                        <span>USDT</span>
                                                    </div>

                                                    <div className='buy-btn'>
                                                        <Link to="/Sign-up" className='loss_button btn btn-primary btn'> Sign Up </Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </TabPanel>

                                    </Tabs>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>


                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeInnerTab == 'market' ? "heading buy__btn" :"heading "}  onClick={() => tradingTab('market')} ><p> Buy Stop Limit</p></div>
                                            <div className={activeInnerTab == 'market' ? "heading" :"heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Stop Limit</p></div>
                                        </div>

                                        <TabPanel>
                                            <div className='main-market2'>
                                                {/*  Buy Market */}

                                                <div className="data_right " style={{ display: buymarketTab }}>
                                                    {/* <div className="heading only-for-lg"><p>Stop Limit</p></div> */}

                                                    <div className='balance'>
                                                        <p className='profit__aaf'>Avbl <span>{coinBallanceShow}</span> USDT </p>
                                                        <div className='input_price input-green'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onBlur3(e) }} value={buyStopPriceAt} />
                                                            <span>STOP PRICE</span>
                                                            <span>USDT</span>
                                                        </div>
                                                        <div className='input_price input-green'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                                                            <span>USDT</span>
                                                        </div>

                                                        <div className='input_price input-green'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                                                            <input type='number' onChange={(e: any) => { onBlur(e) }} value={amount} />
                                                            <span>USDT</span>
                                                        </div>

                                                        <div className='range'>
                                                            <div className='percentage'>
                                                                {/* <div className='items'>0%</div> */}
                                                                <div className='items profit-green' onClick={() => buyPercentage(25)}>25%</div>
                                                                <div className='items profit-green' onClick={() => buyPercentage(50)}>50%</div>
                                                                <div className='items profit-green' onClick={() => buyPercentage(75)}>75%</div>
                                                                <div className='items profit-green' onClick={() => buyPercentage(100)}>100%</div>
                                                            </div>
                                                            <hr></hr>
                                                        </div>

                                                        <div className='input_price input-green'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                                                            <input type='number' value={usdAmount} onChange={onBlur2} />
                                                            <span>USDT</span>
                                                        </div>

                                                        {/* <div className='buy-btn'>
                                                            <Button variant='primary' className='profit-btn' onClick={BuyCoin} disabled={btnDisable}>
                                                            {btnDisable === true &&
                                                                <div className="color-ring">
                                                                    <ClipLoader color="#36d7b7" />

                                                                    Please Wait...
                                                                </div>
                                                            }
                                                            {btnDisable === false ? 'Buy Stop Limit' : ''}
                                                            </Button>
                                                        </div> */}

                                                        <div className='buy-btn'>
                                                            <Link to="/Sign-up" className='btn profit-btn btn btn-primary'> Sign Up </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*  Sell Market */}

                                                <div className="d-mobail-none-buy  data_right" style={{ display: buyStopLimitTab }}>
                                                    {/* <div className="heading only-for-lg"><p>Stop Limit</p></div> */}
                                                    <div className='balance'>
                                                        <p>Avbl <span>{USDTBallanceShow}</span> {id?.toUpperCase()} </p>
                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onBlur3(e) }} value={buyStopPriceAt} />
                                                            <span>STOP PRICE</span>
                                                            <span>USDT</span>
                                                        </div>
                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                                                            <span>USDT</span>
                                                        </div>

                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                                                            <input type='number' onChange={(e: any) => { onBlur(e) }} value={amount} />
                                                            <span>{id?.toUpperCase()}</span>
                                                        </div>

                                                        <div className='range'>
                                                            <div className='percentage'>
                                                                {/* <div className='items'>0%</div> */}
                                                                <div className='items' onClick={() => { sellPercentage(25) }}>25%</div>
                                                                <div className='items' onClick={() => { sellPercentage(50) }}>50%</div>
                                                                <div className='items' onClick={() => { sellPercentage(75) }}>75%</div>
                                                                <div className='items' onClick={() => { sellPercentage(100) }}>100%</div>
                                                            </div>
                                                            <hr></hr>
                                                        </div>

                                                        <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                                                            <input type='number' value={usdAmount} onChange={onBlur2} />
                                                            <span>USDT</span>
                                                        </div>

                                                        {/* <div className='buy-btn'>
                                                            <Button variant='primary' onClick={SellCoin}  disabled={SellbtnDisable}>
                                                            
                                                            {SellbtnDisable === true &&
                                                                <div className="color-ring">
                                                                    <ClipLoader color="#36d7b7" />

                                                                    Please Wait...
                                                                </div>
                                                            }
                                                            {SellbtnDisable === false ? 'Sell Stop Limit' : ''}
                                                            </Button>
                                                        </div> */}

                                                        <div className='buy-btn'>
                                                            <Link to="/login" className='btn btn-primary sign_up__button'> Login Up </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuySell