import React, { useEffect } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {Col, Row } from 'react-bootstrap';
import spot_img from "../../Assets/Img__v2/spot.png";
import buy_img from "../../Assets/Img__v2/buy.png";
import nft_img from "../../Assets/Img__v2/nft.png";
import weba_img  from "../../Assets/Img__v2/web_e.png";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import LooksIcon from '@mui/icons-material/Looks';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import about_banner from '../../Assets/Img__v2/circle-Blue.png';
import { logo, shortTitle, site_url, title } from '../../config/config';
import vission from '../../Assets/Img__v2/vission.png';
import mission from '../../Assets/Img__v2/mission.png';
import Value from '../../Assets/Img__v2/Value.png';

const About = () => {


    const checkAuth = localStorage.getItem('auth');
    const Authbutton = () => {
        if (checkAuth !== "true") {
            return (
                <>
                    <Link to={site_url + '/login'} className='main-button'>
                        <span className='left__top__border'></span>
                        <span className='right__top__border'></span>
                        <span className='left__bottom__border'></span>
                        <span className='right__bottom__border'></span>
                        Log In</Link>

                </>
            )
        }
        else {
            return (
                <>
                    <Link to={site_url + '/dashboard'} className='main-button'>
                    <span className='left__top__border'></span>
                        <span className='right__top__border'></span>
                        <span className='left__bottom__border'></span>
                        <span className='right__bottom__border'></span>
                         Dashboard</Link>

                </>
            )
        }
    }



    useEffect(() => {
        Authbutton()
    }, [Authbutton]);


    return (
        <>
            <div className='about-sec only-margin'>
                <div className="top-sec">
                    <div className='about__banenr'>
                        <div className="coustom_container about_container">
                            <div className='about__banenr__container'>
                                <Row className='about_row_top'>
                                    <Col md={5}>
                                        <div className="inner">
                                            <h4>About</h4>
                                            <h1 className='pragraph__about'>Next Level</h1>
                                            <h5 className='heading_about'>Improved reliability, expanded product range, and increased opportunities.</h5>
                                            <p>Discover {title}: Your gateway to seamless crypto trading. Access advanced tools, real-time data, and a vibrant community. Empower your trading journey with us.</p>
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className='card_cent'>
                                            <div className='card_wrok'>
                                                <div className='card_ani'>
                                                </div>
                                                <img src={about_banner} alt="work" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sec-sec mt-5">

                    <div className="coustom_container">

                        <div className='about___details mb-4 mt-4'>
                            <Row>
                                <Col md={6} lg={4}>
                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <LooksIcon />
                                                    </div>
                                                    <h5 className="card__description">Explore 270+ spot</h5>
                                                </div>
                                                <p>Assets on {title} – a diverse range for comprehensive trading experiences. Elevate your trading game now.</p>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>

                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <FindInPageIcon />
                                                    </div>
                                                    <h5 className="card__description">200 contracts perpectual</h5>
                                                    <p>
                                                        Unlock 200+ perpetual & quarterly futures contracts. Elevate trading strategies with diverse options. Your future in your hands.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={6} lg={4}>

                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <PersonAddIcon />
                                                    </div>
                                                    <h5>15 Millions Users Worldwide</h5>
                                                    <p>Serving a global community of 15 million users. Join us for seamless trading experiences and expansive opportunities.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <SmsFailedIcon />
                                                    </div>
                                                    <h5>24/7 Customer Support</h5>
                                                    <p>Reliable 24/7 customer support at your service. We're here to assist you every step of your trading journey.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={6} lg={4}>

                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <LanguageOutlinedIcon />
                                                    </div>
                                                    <h5>160 Countries</h5>
                                                    <p>Join our international trading platform for a diverse and inclusive trading experience. Your global journey begins.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={4}>

                                    <div className='about___details_a'>
                                        <div className='card'>
                                            <div className="card__content">
                                                <div className='card__title'>
                                                    <div className="inner-icons">
                                                        <PersonAddIcon />
                                                    </div>
                                                    <h5>15 Millions Users Worldwide</h5>
                                                    <p>Serving a global community of 15 million users. Join us for seamless trading experiences and expansive opportunities.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>





                            {/* <Row>
                                <Col md={4} sm={12}>
                                    <div className='about___details_a'>

                                        <div className="inner-icon">
                                            <PersonAddIcon />

                                        </div>
                                        <div className=''>
                                            <p>2.0 bn</p>
                                            <h5> Average daily volume</h5>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} sm={12}>
                                    <div className='about___details_a'>
                                        <div className="inner-icon">
                                            <PersonAddIcon />

                                        </div>
                                        <div className=''>
                                            <p> 10,000 bn</p>
                                            <h5> Transactions per second</h5>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} sm={12}>
                                    <div className='about___details_a'>
                                        <div className="inner-icon">
                                            <PersonAddIcon />

                                        </div>
                                        <div className=''>
                                            <p>24/7</p>
                                            <h5> Customer Support in 10+ languages</h5>

                                        </div>
                                    </div>
                                </Col>




                            </Row> */}
                        </div>

                    </div>
                </div>

                <div className="third-sec mt-5">
                    <div className="coustom_container">
                        <h1>Your Crypto Ark and <span>Gateway to Web3</span></h1>
                        <p>Step aboard our crypto ark and embark on a journey of growth. Our mission is to support you at every stage as you strive for financial freedom.</p>

                        <div className="inner-sec">
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <div className='gateway_img'>
                                        <img src={vission} alt='vission' />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="top-sec_about">
                                        <div className="top">
                                            <h1>VISION</h1>
                                        </div>
                                        <div className="info-sec">
                                            <p>Redefine finance with blockchain innovation. Foster accessible global transactions, empower growth, and elevate digital asset management for a more inclusive financial landscape. Discover limitless possibilities with {title}.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2 mission_row'>
                                <Col md={6}>
                                    <div className="top-sec_about">
                                        <div className="top">
                                            <h1>MISSION</h1>
                                        </div>
                                        <div className="info-sec">
                                            <p>Enabling crypto enthusiasts to unleash their Web3 potential through advanced tools, full support, and limitless growth.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='gateway_img'>
                                        <img src={mission} alt="mission" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <div className='gateway_img'>
                                        <img src={Value} alt="Value" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="top-sec_about">
                                        <div className="top">
                                            <h1>VALUES</h1>
                                        </div>
                                        <div className="info-sec">
                                            <p>We actively listen, genuinely care, and constantly improve to provide an exceptional trading experience.</p>
                                            <p>Our community drives us. Through our advanced, user-friendly platform and top-quality products, we reliably lead you towards financial freedom. With a strong infrastructure, we ensure smooth progress towards an empowered, decentralized future, promising a brighter tomorrow.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>




                        </div>
                    </div>
                </div>

                <div className="forth-sec">
                    <div className="coustom_container">

                        <h1>Our Suite Of Products</h1>

                        <div className="inner-sec">
                            <Row>
                                <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={spot_img} alt="spot_img" />
                                            <div className="inner-section">
                                                <h3>Spot</h3>
                                            </div>

                                        </div>
                                        <div className="right">
                                            <Link to="/" className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Try Now <KeyboardArrowRightIcon /></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={logo} alt="nft_img" />
                                            <div className="inner-section">
                                                <h3>{shortTitle} Token</h3>

                                            </div>

                                        </div>
                                        <div className="right">
                                            <button type='button' className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Coming Soon{/* <KeyboardArrowRightIcon /> */}</button>
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={spot_img} alt="" />
                                            <div className="inner-section">
                                                <h3>{shortTitle} Scan</h3>

                                            </div>

                                        </div>
                                        <div className="right">
                                            <a href='#' target='_blank' className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Try Now <KeyboardArrowRightIcon /></a>
                                        </div>
                                    </div>
                                </Col> */}
                                <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={buy_img } alt="buy_img " />
                                            <div className="inner-section">
                                                <h3>Buy Crypto</h3>

                                            </div>

                                        </div>
                                        <div className="right">
                                            <Link to="/buy-crypto" className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Try Now <KeyboardArrowRightIcon /></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={weba_img} alt="" />
                                            <div className="inner-section">
                                                <h3>Web 3</h3>

                                            </div>

                                        </div>
                                        <div className="right">
                                            <button type='button' className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Coming Soon{/* <KeyboardArrowRightIcon /> */}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="content">
                                        <span className='left__top__border'></span>
                                        <span className='right__top__border'></span>
                                        <span className='left__bottom__border'></span>
                                        <span className='right__bottom__border'></span>
                                        <div className="left">
                                            <img src={nft_img} alt="" />
                                            <div className="inner-section">
                                                <h3>NFT Market Place</h3>
                                            </div>

                                        </div>
                                        <div className="right">
                                            <button type='button' className='button'>
                                                <span className='left__top__border'></span>
                                                <span className='right__top__border'></span>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Coming Soon {/* <KeyboardArrowRightIcon /> */}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>











                        </div>
                    </div>
                </div>

                {/* <div className="last-sec">
                    <div className="coustom_container">

                        <div className="about_ecosystem">
                            <h1 className='text-center'>Our Partners</h1>
                            <div className="ecosystem_cards">
                                <div className="card_par">
                                    <a target='_blank' href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&chainId=56&inputCurrency=0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C">
                                        <div className="inner1 inner">
                                            <img className='img1' src={PancakeSwap} alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div className="card_par">
                                    <a target='_blank' href="https://www.coingecko.com/en/coins/sta">
                                        <div className="inner2 inner">
                                            
                                        </div>
                                    </a>
                                </div>
                                <div className="card_par">
                                    <a target='_blank' href="https://crypto.com/price/sta-token">
                                        <div className="inner3 inner">
                                           
                                        </div>
                                    </a>
                                </div>
                                <div className="card_par">

                                    <a target='_blank' href="https://www.livecoinwatch.com/price/STAToken-___STA">
                                        <div className="inner4 inner">
                                            
                                        </div>
                                    </a>
                                </div>
                                <div className="card_par">
                                    <a target='_blank' href="https://latoken.com/exchange/STA_USDT">
                                        <div className="inner5 inner">
                                            
                                        </div>
                                    </a>
                                </div>
                              
                            </div>

                        </div>
                    </div>
                </div> */}

                <div className="bottom-main-sec text-center">
                    <div className="info">
                        <h1>Join The<span> Crypto Movement</span></h1>
                        <p>Never miss a beat on our latest product updates and events.</p>
                        {Authbutton()}
                    </div>
                </div>


            </div>
        </>
    );
}

export default About;
