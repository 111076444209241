import React, { useEffect, useState } from "react";
import { title, titleDescription } from "../../config/config";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import privilegeImg from "../../Assets/Image/Footer_IMG/privilegeImg.png";



const VipIntroduce = () => {

  const text = `${title} VIP Services`;

  const [content, setContent] = useState('');
  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentLength < text.length) {
        const newText = text.slice(0, currentLength + 1);
        setContent(newText);
        setCurrentLength(currentLength + 1);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval time for the typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentLength]);

  return (
    <>
      <div className="b_container__1 only-margin">
        <div className="coustom_container for__bg">
          <div className="top-sec">
            <h4>VIP SERVICE</h4>
            <h1 className="sentence">{content}</h1>
            <p> {title} provides a full range of exclusive privileges to VIP
              users.
            </p>
          </div>
        </div>

        <div className="VipIntroduce">
          <div className="coustom_container">
            {/* <div className="sec-sec">
              <div className="slider">
                <div className="VIP____container">
                  <VipIntroduceSlider />
                </div>
              </div>
            </div> */}
            <div className="thired_sec">
              <h1>VIP Trading Privileges</h1>
              <div className="cards">
                <div className="row">
                  <div className="col-4">
                    <div className="main-card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>


                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive VIP Funding Rate</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="main-card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>


                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive Account Manager</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="main-card">
                      <span className='left__top__border'></span>
                      <span className='right__top__border'></span>
                      <span className='left__bottom__border'></span>
                      <span className='right__bottom__border'></span>


                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive Green Channel to Deposit and Withdraw</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-sec">
              <div className="heading">
                <h1>Lower Transaction Fees and Higher Withdrawal Limits</h1>
              </div>
              <div className="tabs">
                <Tabs>
                  <div className="tabbuttons">
                    <TabList>
                      <Tab>Spot Trading</Tab>
                      <Tab>Futures Trading</Tab>
                      <Tab>Leverage Trading</Tab>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">

                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 9,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,00,000</h3>
                          <h3>2,5,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">
                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 3,066,000</h3>
                          <h3>3,700,000USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,000,000</h3>
                          <h3>8,200,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">
                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 3,000,000</h3>
                          <h3>8,700,700USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,100,000</h3>
                          <h3>8,440,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                </Tabs>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bottom-sec">
        <div className="VIP____container">
          <div className="row">
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar1} alt="" />
                </div>
                <div className="info">
                  <h2>Christina</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar2} alt="" />
                </div>
                <div className="info">
                  <h2>Ludovic Migneault</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar3} alt="" />
                </div>
                <div className="info">
                  <h2>Gracelyn</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default VipIntroduce;
