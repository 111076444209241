import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const Freetrial = () => {
    return (
        <>
            <div className='coustom_container'>
                <div className='free_trial'>
                    <span className='left__top__border'></span>
                    <span className='right__top__border'></span>
                    <span className='left__bottom__border'></span>
                    <span className='right__bottom__border'></span>
                    <h1>Register for our Free and Referral then get Bonu</h1>
                    <div className='form_sec'>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Enter Your email address"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Text id="basic-addon2">Subscribe Now</InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Freetrial